export interface Ipdt_commande {
	id: Number;
	dateCommande: String;
	dateReglement: String;
	etat: String;
	numCommande: String;
	annulable: Number;
	idSaison: Number;
	idStructure: Number;
	idPersonne: Number;
	montantTotal: Number;
	nbProduits: Number;
	numFacture: String;
}

export class pdt_commande implements Ipdt_commande {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	dateCommande: String;
	dateReglement: String;
	etat: String;
	numCommande: String;
	annulable: Number;
	idSaison: Number;
	idStructure: Number;
	idPersonne: Number;
	montantTotal: Number;
	nbProduits: Number;
	numFacture: String;
	libelle: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.dateCommande = "";
		this.dateReglement = "";
		this.etat = "";
		this.numCommande = "";
		this.annulable = 0;
		this.idSaison = 0;
		this.idStructure = 0;
		this.idPersonne = 0;
		this.montantTotal = 0;
		this.nbProduits = 0;
		this.numFacture = "";
	}

	public fromObj(entry: Ipdt_commande) {
		this.id = entry.id;
		this.dateCommande = entry.dateCommande;
		this.dateReglement = entry.dateReglement;
		this.etat = entry.etat;
		this.numCommande = entry.numCommande;
		this.annulable = entry.annulable;
		this.idSaison = entry.idSaison;
		this.idStructure = entry.idStructure;
		this.idPersonne = entry.idPersonne;
		this.montantTotal = entry.montantTotal;
		this.nbProduits = entry.nbProduits;
		this.numFacture = entry.numFacture;
		return this;
	}

}
