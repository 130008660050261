import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AutoSlashDirective } from './auto-slash.directive';


@NgModule({
  declarations: [AutoSlashDirective],
  imports: [
    CommonModule,IonicModule,FormsModule
  ],
  exports:[AutoSlashDirective]
})
export class AutoSlashModule { }
