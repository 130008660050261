import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { PdtProduitcommandeService } from './pdt-produitcommande.service';
import { GlobalService } from './global.service';
import { combineLatest, Observable } from 'rxjs';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class AdherentsResolverService implements Resolve<any> {

	constructor(
		public global: GlobalService,
		public pdtProduitcommandeService: PdtProduitcommandeService,
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const id = route.paramMap.get('id');
		console.log("debug1 AdherentResolverService resolve", id)


		console.log("debug1 AdherentResolverService isready")

		return this.global.isReady.pipe(
			filter((ready) => ready === true),  // Wait until the boolean is `true`
			take(1),  // Only take the first `true` value and complete the observable
			switchMap(() => {

				console.log("debug1 AdherentResolverService globa isready ok")

				this.pdtProduitcommandeService.reload()

				return combineLatest([
					this.pdtProduitcommandeService.getLoadingComplete(),
					//delay
				]).pipe(
					// Filter only when all values are true
					filter(([
						pdtProduitcommandeServiceComplete,
						//	delay
					]) =>
						pdtProduitcommandeServiceComplete
						//	delay==0
					)
				)
			})
		)
	}
}