import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { InsFonctionAutoriseeService } from 'src/app/Services/ins-fonction-autorisee.service';
import { InsInstanceService } from 'src/app/Services/ins-instance.service';
import { InsTypeFonctionService } from 'src/app/Services/ins-type-fonction.service';
import { InsTypeInstanceService } from 'src/app/Services/ins-type-instance.service';
import { ServerDBApiService } from './server-dbapi.service';
import { take } from 'rxjs/operators';
import { ins_fonction } from '../types/type_ins_fonction';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class InsFonctionService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector,
		public insFonctionAutoriseeService: InsFonctionAutoriseeService,
		public insInstanceService: InsInstanceService,
		public insTypeFonctionService: InsTypeFonctionService,
		public insTypeInstanceService: InsTypeInstanceService,
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("ins_fonction", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new ins_fonction()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished ins_fonction", this.entries)
	}

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "codeEnvoiCourrierPersonne", nullable: false },
		{ field: "dateDebut", nullable: false },
		{ field: "dateFin", nullable: true },
		{ field: "libelle", nullable: true },
		{ field: "isSalarie", nullable: false },
		{ field: "idFonctionAutorisee", nullable: false },
		{ field: "idPersonne", nullable: false },
		{ field: "idStructure", nullable: false },
		{ field: "email", nullable: true },
		]
	}

	override getNewId(): number {
		return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
		/*let res = this.getEntries()
			.map((entry) => entry.id)
			.sort((entryId1, entryId2) => {
				return entryId1 > entryId2 ? -1 : 1;
			})[0] + 1
		return (
			isNaN(res) ? 0 : res
		);*/
	}



	//idPersonne, idStructure, dateDebut, dateFin, idTypeInstance, idTypeFonction
	//const { idPersonne, idStructure, dateDebut, dateFin, idInstance, idTypeFonction }
	insert(idPersonne, idStructure, SQLdateDebut, SQLdateFin, idInstance, idTypeFonction) {



		const data: any = {
			idPersonne: idPersonne,
			idStructure: idStructure,
			dateDebut: SQLdateDebut,
			dateFin: SQLdateFin,
			idInstance: idInstance,
			idTypeFonction: idTypeFonction
		};


		this.getDbFields().forEach(field => {
			if (field.nullable && (!data[field.field] || (data[field.field] && data[field.field] == ""))) {
				delete data[field.field]// = "NULL"
			}
		});

		/*data.dateDebut = "2024-06-21T20:07:00"
		data.dateFin = "2024-06-28T20:08:00"
		data.idInstance = "1"
		data.idStructure = "404"
		data.idTypeFonction = "1"*/

		console.log("✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ insertFunction", data)

		this.apiService.insertFonction(data).subscribe({
			next: (response) => {
				console.log('Record inserted successfully:', response);
				this.apiService.handleTablesToReset(response)
				this.insFonctionAutoriseeService.reload()
				this.dataEventLoadedSubscription = this.insFonctionAutoriseeService.dataEventLoaded.subscribe((table) => {
					console.log("✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ insFonctionAutoriseeService.dataEventLoaded.subscribe")
					if ( table == "ins_fonction_autorisee" ) {
						this.dataEventLoadedSubscription.unsubscribe()
						this.reload()
					}
				})
				
			},
			error: (error) => { console.error('Error inserting record:', error); }
		}
		);
	}

	async Add(
		codeEnvoiCourrierPersonne,
		dateDebut,
		dateFin,
		libelle,
		isSalarie,
		idFonctionAutorisee,
		idPersonne,
		idStructure,
		email
	) {

		const res = new ins_fonction()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.codeEnvoiCourrierPersonne = codeEnvoiCourrierPersonne
		res.dateDebut = dateDebut
		res.dateFin = dateFin
		res.libelle = libelle
		res.isSalarie = isSalarie
		res.idFonctionAutorisee = idFonctionAutorisee
		res.idPersonne = idPersonne
		res.idStructure = idStructure
		res.email = email

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}

	loadSubentries(idFonction) {
		const entry = this.entries.find(x => x.id == idFonction)
		//console.log("start loadSubentries",idFonction,entry)
		if (!entry.fonctionAutorisee)
			entry.fonctionAutorisee = this.insFonctionAutoriseeService.entries.find(x => x.id == entry.idFonctionAutorisee)

		//console.log("loadSubentries 1",idFonction,entry)
		if (!entry.fonctionAutorisee.instance) {
			entry.fonctionAutorisee.instance = this.insInstanceService.entries.find(x => x.id == entry.fonctionAutorisee.idInstance)
		}

		//console.log("loadSubentries 2",idFonction,entry)
		//console.log("xxx1",entry.fonctionAutorisee.instance)
		if (!entry.fonctionAutorisee.instance.typeInstance) {
			//console.log("xxx2",this.insTypeInstanceService.entries,entry.fonctionAutorisee.instance.idTypeInstance)
			entry.fonctionAutorisee.instance.typeInstance = this.insTypeInstanceService.entries.find(x => x.id == entry.fonctionAutorisee.instance.idTypeInstance)
		}


		if (!entry.fonctionAutorisee.typeFonction)
			entry.fonctionAutorisee.typeFonction = this.insTypeFonctionService.entries.find(x => x.id == entry.fonctionAutorisee.idTypeFonction)

		//console.log("end loadSubentries",idFonction,entry)
		return entry
	}

	findFonctionLibelle(idFonction) {
		//console.log("findFonctionLibelle",idFonction)
		return this.loadSubentries(idFonction).fonctionAutorisee.typeFonction.libelle
	}

	findInstanceLibelle(idFonction) {
		//console.log("findInstanceLibelle",idFonction)
		return this.loadSubentries(idFonction).fonctionAutorisee.instance.typeInstance.libelle
	}
}
