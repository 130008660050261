import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { trc_groupetrace } from '../types/type_trc_groupetrace';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TrcGroupeTraceService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("trc_groupetrace", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new trc_groupetrace()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished trc_groupetrace", this.entries)
	}


	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "libelle", nullable: false },
		]
	}

	async Add(
		libelle) {

		const res = new trc_groupetrace()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.libelle = libelle

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}
}