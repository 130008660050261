import { Injectable } from '@angular/core';
import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';

@Injectable({
	providedIn: 'root',
})
export class PdfTemplateService {
	constructor() { }

	async loadPdf(url: string): Promise<PDFDocument> {
		const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
		const pdfDoc = await PDFDocument.load(existingPdfBytes);
		return pdfDoc;
	}

	async modifyPdf(pdfDoc: PDFDocument, replacements: { [key: string]: string }): Promise<Uint8Array> {
		const pages = pdfDoc.getPages();
		const form = pdfDoc.getForm()

		for (const [key, value] of Object.entries(replacements)) {
			try {
				const field = form.getTextField(key)
				if (field) {
					field.setText(value)
				}
			} catch (error) {
				console.log(error)
			}
		}

		form.flatten();

		const pdfBytes = await pdfDoc.save();
		return pdfBytes;
	}
}
