export interface Ilic_categorieage {
	id: Number;
	ageMax: Number;
	ageMin: Number;
	code: String;
	libelle: String;
	idSaison: Number;
	ageMaxSurclassement: Number;
	ageMinSurclassement: Number;
	surclassementPossible: String;
}

export class lic_categorieage implements Ilic_categorieage {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	ageMax: Number;
	ageMin: Number;
	code: String;
	libelle: String;
	idSaison: Number;
	ageMaxSurclassement: Number;
	ageMinSurclassement: Number;
	surclassementPossible: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.ageMax = 0;
		this.ageMin = 0;
		this.code = "";
		this.libelle = "";
		this.idSaison = 0;
		this.ageMaxSurclassement = 0;
		this.ageMinSurclassement = 0;
		this.surclassementPossible = "";
	}

	public fromObj(entry: Ilic_categorieage) {
		this.id = entry.id;
		this.ageMax = entry.ageMax;
		this.ageMin = entry.ageMin;
		this.code = entry.code;
		this.libelle = entry.libelle;
		this.idSaison = entry.idSaison;
		this.ageMaxSurclassement = entry.ageMaxSurclassement;
		this.ageMinSurclassement = entry.ageMinSurclassement;
		this.surclassementPossible = entry.surclassementPossible;
		return this;
	}

}
