import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ins_instance } from '../types/type_ins_instance';
import { StrTypeStructureService } from './str-type-structure.service';
import { InsTypeInstanceService } from './ins-type-instance.service';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class InsInstanceService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public strTypeStructureService: StrTypeStructureService,
		public insTypeInstanceService: InsTypeInstanceService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("ins_instance", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new ins_instance()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		this.entries.forEach(instance=>{
			instance.typeStructure = this.strTypeStructureService.entries.find(x=>x.id==instance.idTypeStructure)?.libelle
			instance.typeInstance = this.insTypeInstanceService.entries.find(x=>x.id==instance.idTypeInstance)?.libellee
		})

		console.log("finished ins_instance", this.entries)
	}

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "idSpecialiteStructure", nullable: false },
		{ field: "idTypeInstance", nullable: false },
		{ field: "idTypeStructure", nullable: false }
		]
	}

	async Add(
		idSpecialiteStructure,
		idTypeInstance,
		idTypeStructure,
	) {

		const res = new ins_instance()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.idSpecialiteStructure = idSpecialiteStructure
		res.idTypeInstance = idTypeInstance
		res.idTypeStructure = idTypeStructure

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}
}