export interface Icfg_saison {
	id: Number;
	idSaison: Number;
	dateDebutPreSaison: String;
	dateDebutSaison: String;
	dateFinPreSaison: String;
	dateFinSaison: String;
	libelle: String;
	dateDebutTransfert: String;
	dateFinTransfert: String;
	dateDebutSaisonSportive: String;
	dateFinSaisonSportive: String;
	enMaintenance: String;
}

export class cfg_saison implements Icfg_saison {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	idSaison: Number;
	dateDebutPreSaison: String;
	dateDebutSaison: String;
	dateFinPreSaison: String;
	dateFinSaison: String;
	libelle: String;
	dateDebutTransfert: String;
	dateFinTransfert: String;
	dateDebutSaisonSportive: String;
	dateFinSaisonSportive: String;
	enMaintenance: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.idSaison = 0;
		this.dateDebutPreSaison = "";
		this.dateDebutSaison = "";
		this.dateFinPreSaison = "";
		this.dateFinSaison = "";
		this.libelle = "";
		this.dateDebutTransfert = "";
		this.dateFinTransfert = "";
		this.dateDebutSaisonSportive = "";
		this.dateFinSaisonSportive = "";
		this.enMaintenance = "";
	}

	public fromObj(entry: Icfg_saison) {
		this.id = entry.id;
		this.idSaison = entry.idSaison;
		this.dateDebutPreSaison = entry.dateDebutPreSaison;
		this.dateDebutSaison = entry.dateDebutSaison;
		this.dateFinPreSaison = entry.dateFinPreSaison;
		this.dateFinSaison = entry.dateFinSaison;
		this.libelle = entry.libelle;
		this.dateDebutTransfert = entry.dateDebutTransfert;
		this.dateFinTransfert = entry.dateFinTransfert;
		this.dateDebutSaisonSportive = entry.dateDebutSaisonSportive;
		this.dateFinSaisonSportive = entry.dateFinSaisonSportive;
		this.enMaintenance = entry.enMaintenance;
		return this;
	}

}
