import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { pdt_produitcommande } from '../types/type_pdt_produitcommande';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { PdtProduitService } from './pdt-produit.service';
import { PdtCommandeService } from './pdt-commande.service';
import { FinBanquesService } from './fin-banques.service';
import { Injector } from '@angular/core';
import { StrStructureService } from './str-structure.service';

@Injectable({
	providedIn: 'root'
})
export class PdtProduitcommandeService extends BaseService {

	override authneeded = false;

	public lastStructuresPerPersonne = new Map()

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public pdtProduitService: PdtProduitService,
		public pdtCommandeService: PdtCommandeService,
		public strStructureService: StrStructureService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		this.global.allMaps["pdt_produitcommande_personne"] = new Map();
		super.init("pdt_produitcommande", this.loadEntry.bind(this), [], this.getId.bind(this))
	}
	
	first = true
	getLastStructurePerPersonne(tmp,idPersonne) {
		const currentEntry = this.lastStructuresPerPersonne.get(idPersonne);
		if (currentEntry ) {
			if ( !currentEntry.structure ) {
				const str = this.strStructureService.map.get(currentEntry.idStructure)
				if ( str ) {
					currentEntry.structure = str.code+" "+str.nom
				}
			}
			return currentEntry.structure
		}		
		return ""		
	}

	findCommandeLibelle(idCommande) {
		if ( !idCommande )
			return null

		return this.pdtCommandeService.map.get(String(idCommande))?.numCommande
	}

	findProduitLibelle(idProduit) {
		if ( !idProduit )
			return null

		return this.pdtProduitService.map.get(String(idProduit))?.libelle		
	}

	findBanque(idBanque) {

	}

	override addEntry(): any {
		let entry = super.addEntry(new pdt_produitcommande())
		return entry
	}

	override getSingleUrl() {
		return '/client/' + this.currentEntry.id
	}

	override getListUrl() {
		return '/clients'
	}

	override getSingleIcon() {
		return 'person'
	}

	override getListIcon() {
		return 'people'
	}


	override onLoadingFinished() {
		console.log("finished pdtproduitcommande")
	}

	/*override getEntriesToDisplay(idcustomer=null,search=null,sortedField=null) {
		let tmp = super.getEntriesToDisplay(idcustomer,search,sortedField)
		tmp = tmp.filter(x=>!(x.company == "" && x.lastname=="" && x.firstname=="") )
		if ( sortedField ) {
			
			if ( sortedField.sort == 1 )
				return tmp.sort((a,b)=>a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a,b)=>b[sortedField.field].localeCompare(a[sortedField.field]))
		}
		return tmp
	}*/

	/*override getTitle(entry:any=null) {
		if (!entry)
			entry = this.currentEntry
		let tmp = entry.company.trim()
		if ( tmp == "" ) {
			tmp = entry.lastname + " " + entry.firstname
			tmp = tmp.trim()
			if (tmp == "")
				return "(Nouveau client)"
		}
		return tmp
	}*/

	getId(entry) {

		/*if (!this.global.allEntries[basetable])
		this.global.allEntries[basetable] = []*/
		let produitCommandPersonneArray = this.global.allMaps["pdt_produitcommande_personne"].get(entry.idPersonne)
		if (!produitCommandPersonneArray) {
			produitCommandPersonneArray = []
			this.global.allMaps["pdt_produitcommande_personne"].set(entry.idPersonne, produitCommandPersonneArray)
		}
		produitCommandPersonneArray.push(entry)
		return String(entry.id)
	}


	//funtion passed in constructor
	loadEntry(dbentry) {
		const res = (new pdt_produitcommande()).fromObj(dbentry)

		const idStructure = res.idStructure
		const idPersonne = Number( res.idPersonne ) 
		const date = moment(res.date); // Assuming entry.date is in a format parseable by Date

		if (!this.lastStructuresPerPersonne.has(idPersonne)) {
		  // If there's no entry for this idpersonne, add it
		  this.lastStructuresPerPersonne.set(idPersonne, { idStructure, date });
		} else {
		  const currentEntry = this.lastStructuresPerPersonne.get(idPersonne);
		  if (currentEntry && date < currentEntry.date) {
			// If the new date is earlier, update the entry
			this.lastStructuresPerPersonne.set(idPersonne, { idStructure, date });
		  }
		}

		//console.log("lastStructuresPerPersonne",this.lastStructuresPerPersonne)

		return res

	}

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "dateEnregistrement", nullable: false },
		{ field: "date", nullable: false },
		{ field: "etat", nullable: false },
		{ field: "quantite", nullable: true },
		{ field: "montantPaye", nullable: true },
		{ field: "montantPrisPrePaye", nullable: true },
		{ field: "idCommande", nullable: true },
		{ field: "idPersonne", nullable: true },
		{ field: "idProduit", nullable: false },
		{ field: "idSaison", nullable: false },
		{ field: "idStructure", nullable: true },
		{ field: "idTarif", nullable: true }]
	}

	async Add(idCommande, idPersonne, idProduit, idSaison, idStructure, idTarif, montantPaye) {
		const res = new pdt_produitcommande()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.dateEnregistrement = now
		res.date = now
		res.etat = "Actif"
		res.quantite = 1
		res.montantPaye = montantPaye
		res.montantPrisPrePaye = 0
		res.idCommande = idCommande
		res.idPersonne = idPersonne
		res.idProduit = idProduit
		res.idSaison = idSaison
		res.idStructure = idStructure
		res.idTarif = idTarif

		console.log("PdtProduitcommandeService beforesavea")
		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		console.log("PdtProduitcommandeService aftersaveb")
		return res;
	}

	/*findCustomer(id): Customer {
		this.currentCustomer = null;
		this.currentCustomer = this.customers.find(cust => cust.id == id)
		return this.currentCustomer
	}*/
}
