// src/app/services/data-loader.service.ts
import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, first } from 'rxjs';
import { GlobalService } from './global.service';
import { StrStructuresMeresService } from './str-structures-meres.service';
import { EtqEtiquettesService } from './etq-etiquettes.service';
import { TrcGroupeTraceService } from './trc-groupe-trace.service';
import { GedFichierService } from './ged-fichier.service';
import { DrtAccesService } from './drt-acces.service';
import { DrtAccesRoleService } from './drt-acces-role.service';
import { DrtRoleService } from './drt-role.service';
import { DrtUtilisateurService } from './drt-utilisateur.service';

@Injectable({
	providedIn: 'root'
})
export class DataLoader2Service implements OnInit {
	private nbloaded = 0;

	private done = false;
	public dataLoaded2: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	ngOnInit() {
		console.log("DataLoader2Service ngoninit")
	}

	getDataLoadedObservable() {
		return this.dataLoaded2.asObservable();
	}

	tables = [
		"str_structures_meres",
		"etq_etiquettes",
		"trc_groupetrace",
		"ged_fichier",
		"drt_utilisateur",
		"drt_acces",
		"drt_acces_role",
		"drt_role",
	]

	loadData2() {
		const sub1 = this.strStructuresMeresService.dataEventLoaded.subscribe((t) => { console.log("dataEventLoaded subscribe", t); this.nbloaded++; this.checkLoaded(); })
		const sub2 = this.trcGroupeTraceService.dataEventLoaded.subscribe((t) => { console.log("dataEventLoaded subscribe", t); this.nbloaded++; this.checkLoaded(); })
		const sub3 = this.etqEtiquettesService.dataEventLoaded.subscribe((t) => { console.log("dataEventLoaded subscribe", t); this.nbloaded++; this.checkLoaded(); })
		const sub4 = this.gedFichierService.dataEventLoaded.subscribe((t) => { console.log("dataEventLoaded subscribe ged_fichier", t); this.nbloaded++; this.checkLoaded(); })

		//on charge une simulation


		//
		/*this.sub2 = this.etqEtiquettesService.dataEventLoaded.subscribe((t)=>{console.log("2");this.nbloaded++;this.checkLoaded();this.sub2.unsubscribe()})
		this.sub3 = this.trcGroupeTraceService.dataEventLoaded.subscribe((t)=>{console.log("3");this.nbloaded++;this.checkLoaded();this.sub3.unsubscribe()})
*/

		/*setTimeout(() => {
			for (let i = 0; i < 10000; i++) {
				this.data.push({ id: i, name: `Item ${i}` });
			}
			// Notifier que les données sont chargées
			this.nbloaded++;
			this.checkLoaded();
			console.log("a nbloaded",this.nbloaded)
		}, 3000);*/ // Temps de chargement simulé de 3 secondes

		//on charge insfonction

		/*this.globalService.loaded.subscribe(t => {
			console.log("this.globalService.loaded.this.globalService.loaded.this.globalService.loaded.this.globalService.loaded.")
			if(  this.done )
				return;
			if (this.tables.includes(t)) {
				console.log("this.loadData2", t)				
				this.nbloaded++;
				this.checkLoaded();
				console.log("this.loadData2 b nbloaded", this.nbloaded, t)
			}
		})*/
	}

	constructor(private http: HttpClient,
		public globalService: GlobalService,
		public strStructuresMeresService: StrStructuresMeresService,
		public trcGroupeTraceService: TrcGroupeTraceService,
		public etqEtiquettesService: EtqEtiquettesService,
		public gedFichierService: GedFichierService,
		public drtAccesRoleService: DrtAccesRoleService,
		public drtAccesService: DrtAccesService,
		public drtRoleService: DrtRoleService,
		public drtUtilisateurService: DrtUtilisateurService
	) {
		this.strStructuresMeresService.callbackendloaded = this.endLoaded.bind(this, this.strStructuresMeresService)
		this.trcGroupeTraceService.callbackendloaded = this.endLoaded.bind(this, this.trcGroupeTraceService)
		this.etqEtiquettesService.callbackendloaded = this.endLoaded.bind(this, this.etqEtiquettesService)
		this.gedFichierService.callbackendloaded = this.endLoaded.bind(this, this.gedFichierService)
		this.drtAccesRoleService.callbackendloaded = this.endLoaded.bind(this, this.drtAccesRoleService)
		this.drtAccesService.callbackendloaded = this.endLoaded.bind(this, this.drtAccesService)
		this.drtRoleService.callbackendloaded = this.endLoaded.bind(this, this.drtRoleService)
		this.drtUtilisateurService.callbackendloaded = this.endLoaded.bind(this, this.drtUtilisateurService)

		this.strStructuresMeresService.reload()
		this.trcGroupeTraceService.reload()
		this.etqEtiquettesService.reload()
		this.gedFichierService.reload()
		this.drtAccesRoleService.reload()
		this.drtAccesService.reload()
		this.drtRoleService.reload()
		this.drtUtilisateurService.reload()
	}

	endLoaded(table, baseservice) {
		console.log("endLoaded", table)
		this.nbloaded++;
		this.checkLoaded();
	}

	wait() {
		return
		setTimeout(() => {
			this.nbloaded++; this.checkLoaded();
		}, 100)
	}

	async waitForTrue(): Promise<void> {
		// Create an observable from the BehaviorSubject
		const observable$ = this.dataLoaded2.asObservable();

		// Use the first operator to wait until the value is true
		const trueValue = await observable$.pipe(
			// Use the first operator to emit only when the value becomes true
			first(value => value === true)
		).toPromise();

		// At this point, trueValue is true
		console.log('The BehaviorSubject is now true');
	}

	checkLoaded(): void {
		console.log("checkLoaded", this.nbloaded)
		if (this.nbloaded == this.tables.length) {
			console.log("dataloaded2 next 1")
			this.dataLoaded2.next(true);
			console.log("dataloaded2 next 2")
			this.done = true;
		}
	}
}
