import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { trc_typetrace } from '../types/type_trc_typetrace';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TrcTypeTraceService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("trc_typetrace", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	findTypeTrace(id) {
		return this.entries.find(x=>x.id==id)?.libelle
	}

	getId(entry) {
		return entry.id
	}

	loadEntry(dbentry) {
		const res = (new trc_typetrace()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished trc_typetrace", this.entries)
	}


	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "code", nullable: true },
		{ field: "libelle", nullable: false },
		{ field: "idGroupeTrace", nullable: true },
		{ field: "withMajInfosAControler", nullable: true },
		]
	}

	async Add(
		code,
		libelle,
		idGroupeTrace,
		withMajInfosAControler,
	) {

		const res = new trc_typetrace()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.code = code
		res.libelle = libelle
		res.idGroupeTrace = idGroupeTrace
		res.withMajInfosAControler = withMajInfosAControler

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}
}