import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StrStructureService } from 'src/app/Services/str-structure.service';

@Component({
	selector: 'select-section-locale',
	templateUrl: './select-section-locale.component.html',
	styleUrls: ['./select-section-locale.component.scss'],
})
export class SelectSectionLocaleComponent implements OnInit {

	isModalOpen = false;

	public sectionlocale = ""
	public selectedItem: any;
	public selectedItemId = "";

	public entries = this.structureService.displayentries;
	public search = "";

	canDismiss = false;

	@Input() textalignright: boolean = false;
	@Input() noborder: boolean = false;
	@Input() label: string = "";
	@Input() myProp: string;
	@Output() myPropChange = new EventEmitter<string>();

	isOpenModalChangementStructureEvent(isOpen, selectedItemId, pdtCmd) {
		console.log("isOpenModalChangementStructureEvenet", isOpen)
		this.setOpen(isOpen)
		if (!isOpen) {
		}
			/*this.modalChangementStructure.dismiss()
			console.log("selectedItemId", selectedItemId)
			if (selectedItemId) {
				console.log("changement demandé", selectedItemId, pdtCmd)
				this.apiService.changeStructure(pdtCmd, selectedItemId).then(x => {
					pdtCmd.idStructure = selectedItemId
					pdtCmd.structure = this.strStructureService.fastFindEntry(selectedItemId)
				})
			}
		}*/
	}
	
	setOpen(isOpen: boolean) {
		if (!isOpen)
			this.canDismiss = true;
		if (isOpen)
			this.canDismiss = false;
		console.log("isOpen", isOpen)
		this.isModalOpen = isOpen;
	}

	isVisible(item) {
		if (item.children.length == 0) {
			if (this.search != "") {
				if (item.nom.toLowerCase().indexOf(this.search) > -1) {
					return true;
				}
				return false;
			}
			return true;
		}
		return true;
	}

	filter(entry) {
		if (entry.children.length == 0) {
			entry.visible = true;
			if (entry.nom.toLowerCase().indexOf(this.search) == -1)
				entry.visible = false;
		}
		entry.children.forEach(e => { this.filter(e) })
	}

	allChildrenVisible(entry) {
		if (entry.children.length == 0) {
			return entry.visible;
		}
		let nb = 0;
		entry.children.forEach(e => {
			if (this.allChildrenVisible(e))
				nb = nb + 1
		})
		console.log("allChildrenVisible",entry.nom,entry.children.length,nb)
		if (nb > 0) {
			entry.visible = true;
			return true;
		}
		entry.visible = false;
		return false;
	}

	handleSearch(event) {
		this.search = event.target.value.toLowerCase();
		console.log("this.structureService.entries", this.structureService.displayentries)
		this.filter(this.entries[0])
		console.log("this.structureService.entries", this.structureService.displayentries)
		this.allChildrenVisible(this.entries[0])
		console.log("this.structureService.entries", this.structureService.displayentries)
		//this.entries = this.structureService.entries.filter((n)=>n.nom.toLowerCase().indexOf(query)>-1);
		//console.log("handleSearch",query,this.entries)
		//this.results = this.data.filter((d) => d.toLowerCase().indexOf(query) > -1);
	}

	clear() {
		this.selectedItem = null;
		this.selectedItemId = "";
		this.sectionlocale = "";
	}

	constructor(public structureService: StrStructureService) {

	}

	onWillDismiss(event: Event) {
		console.log("onWillDismiss")
	}

	addSubentry(parent: Node) {
		/*const n = new Node()	
		n.libelle = this.nodeService.makeid(10)	
		n.parents.push(parent)
	
		parent.children.push(n)
		parent.expanded = true
	  	
		console.log("addsubentry",this.nodeService.entries)
	
		this.nodeService.saveEntriesDelay()*/
	}

	ngOnInit() {
		if ( !this.entries || this.entries.length == 0 )
			return;
		console.log("select-section-locale",this.entries)
		console.log("select-section-locale", this.entries[0].expanded,this.myProp,this.sectionlocale)
		this.entries[0].expanded = true

		if ( this.myProp != "" ) {
			//console.log("this.structureService.entries",this.structureService.flatentries)
			const structure = this.structureService.entries.find(str=>str.id==this.myProp)
			console.log("structure",structure)
			if ( structure ) {
				this.sectionlocale = structure.code + "-" + structure.nom
			}
		}

		/*this.search = "clermont"
		console.log("this.structureService.entries1", this.structureService.entries)
		this.filter(this.entries[0])
		console.log("this.structureService.entries2", this.structureService.entries)
		this.allChildrenVisible(this.entries[0])
		console.log("this.structureService.entries3", this.structureService.entries)*/
	}

	select(itemid: any) {
		console.log("debug4 select section locale", itemid)
		this.canDismiss = true;
		this.isModalOpen = false

		const item = this.structureService.fastFindEntry(itemid)

		this.selectedItem = item
		this.selectedItemId = item.id
		this.sectionlocale = item.code + "-" + item.nom

		this.myProp = item.id
		this.myPropChange.emit(this.myProp)
	}

	PopupSectionLocale() {

	}
}
