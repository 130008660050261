import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DatePickerModalComponent } from '../date-picker-modal/date-picker-modal.component';

@Component({
	selector: 'app-date-picker',
	templateUrl: './date-picker.component.html',
	styleUrls: ['./date-picker.component.scss'],
})
export class DatePickerComponent implements OnInit {
	showDatePicker = false;
	selectedDate: string;
	formattedDate: string;

	_dateSelected: string;
	@Input()
	get dateSelected(): string {
		return this._dateSelected;
	}

	ngOnInit(): void {
		this.selectedDate = this._dateSelected
	}

	first = true;
	set dateSelected(value: string) {
		console.log("set dateSelected",value)
		if ( value.length>10)
			value = value.substring(0,10)
		this._dateSelected = value;
		if (this.first) {			
			this.dateSelectedChange.emit(value);
			if (value) {
				const [year, month, day] = value.split('-');
				this.formattedDate = `${day}/${month}/${year}`;
				console.log("set dateSelected", value, this.formattedDate)
			}
			this.first = false;
		}
	}

	@Output() dateSelectedChange: EventEmitter<any> = new EventEmitter<any>();
	@Output() openDatePickerEvent: EventEmitter<any> = new EventEmitter<any>();

	constructor(private modalController: ModalController) { }

	private datePickerModal
	async openDatePicker() {
		console.log("openDatePicker")
		this.openDatePickerEvent.emit();

		this.datePickerModal = await this.modalController.create({
			component: DatePickerModalComponent,
			componentProps: {
				selectedDate: this.selectedDate,
			},
			animated: true,
			showBackdrop: true,
			cssClass: "modal-value",
		});

		this.datePickerModal.onDidDismiss().then((data) => {
			console.log("datePickerModal dismiss", data.data)
			this.selectedDate = data.data;
			console.log("this.selectedDate",this.selectedDate)
			this.formatDate();
			console.log("this.formattedDate", this.formattedDate)
			this.dateSelectedChange.emit(this.selectedDate);
		});

		return await this.datePickerModal.present();

	}



	onDateSelected(event: any) {
		this.selectedDate = event.detail.value;
		this.formatDate();
		this.dateSelectedChange.emit(this.selectedDate); // Émettre l'événement de date sélectionnée
	}

	onDateInput(event: any) {
		const keyPressed = event.data; // Récupère la touche entrée
		const key = event.inputType; // Récupère le type de l'événement
		let input = event.target.value; // La valeur actuelle de l'input
		const selectionStart = event.target.selectionStart; // La position actuelle du curseur
		console.log("input.charAt(selectionStart - 1)", selectionStart, input.charAt(selectionStart - 1))

		// Vérifie si la touche est "backspace" ou "delete"
		if (key === 'deleteContentBackward' || key === 'deleteContentForward') {


			// Si la touche Backspace est pressée et le curseur est juste avant le deuxième backslash
			if (key === 'deleteContentBackward' && input.charAt(selectionStart - 1) === '/') {
				event.target.value = input.slice(0, selectionStart - 1)// + input.slice(selectionStart); // Supprimer le deuxième backslash
				console.log("del event.target.value", event.target.value)
				this.formattedDate = event.target.value
				event.preventDefault(); // Empêcher la suppression normale effectuée par le navigateur
				return; // Sortir de la fonction car le traitement est terminé
			}

			if (key === 'deleteContentBackward' && input.length == 5) {
				event.target.value = input.slice(0, selectionStart - 1)// + input.slice(selectionStart); // Supprimer le deuxième backslash
				console.log("del event.target.value", event.target.value)
				this.formattedDate = event.target.value
				event.preventDefault(); // Empêcher la suppression normale effectuée par le navigateur
				return; // Sortir de la fonction car le traitement est terminé
			}
		}

		console.log("event.target.value", event)
		input = event.target.value.replace(/\D/g, ''); // Remove all non-numeric characters

		//input = input.replace(/\//g, '');

		if (input.length > 8) {
			input = input.substring(0, 8); // Limit input to 8 digits
		}

		if (input.length >= 2) {
			input = input.substring(0, 2) + '/' + input.substring(2);
		}
		if (input.length >= 5) {
			input = input.substring(0, 5) + '/' + input.substring(5);
		}

		this.formattedDate = input;
		this.selectedDate = this.convertirDateEnChaine(this.convertirChaineEnDate(this.formattedDate))
		this.dateSelectedChange.emit(this.selectedDate);
		console.log("a", this.selectedDate)
	}

	convertirDateEnChaine(date: Date): string {
		const year = date.getFullYear();
		const month = ('0' + (date.getMonth() + 1)).slice(-2); // Ajoute un zéro initial et récupère les deux derniers caractères
		const day = ('0' + date.getDate()).slice(-2); // Ajoute un zéro initial et récupère les deux derniers caractères

		return `${year}-${month}-${day}`;
	}

	convertirChaineEnDate(chaine: string): Date | null {
		const [jour, mois, annee] = chaine.split('/').map(Number);

		// Vérifie si les parties de la date sont valides
		if (isNaN(jour) || isNaN(mois) || isNaN(annee)) {
			return null;
		}

		// Crée un nouvel objet Date en utilisant les parties de la chaîne
		// (mois - 1 car les mois dans JavaScript sont indexés à partir de 0)
		const date = new Date(annee, mois - 1, jour);

		// Vérifie si la date créée est valide
		if (isNaN(date.getTime())) {
			return null;
		}

		return date;
	}

	formatDate() {
		if (this.selectedDate) {
			const date = new Date(this.selectedDate);
			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const year = date.getFullYear();
			this.formattedDate = `${day}/${month}/${year}`;
		}
	}
}
