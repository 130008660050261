import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanLoad, CanMatch, Router, RouterStateSnapshot, UrlTree, Route, UrlSegment, CanActivateFn } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';


export const AuthGuard: CanActivateFn = async (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> => {
	const authenticationService = inject(AuthenticationService);
	return await authenticationService.canActivate(next, state);
  };

/*export class AuthGuard implements CanActivate {
	constructor(private authService: AuthenticationService, private router: Router) { }

	async canMatch(
		route: Route,
		segments: UrlSegment[]
	  ): Promise<boolean> {
		const token = await this.authService.getToken();
		if (token && await this.authService.checkToken(token)) {
		  return true;
		} else {
		  this.router.navigate(['/login']);
		  return false;
		}
	  }

	async canActivate(): Promise<boolean> {
		const token = await this.authService.getToken();
		if (token && await this.authService.checkToken(token)) {
			return true;
		} else {
			this.router.navigate(['/login']);
			return false;
		}
	}
}*/