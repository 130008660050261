export interface Istr_structures_meres {
	idStructure: Number;
	idStructureMere: Number;
}

export class str_structures_meres implements Istr_structures_meres {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	idStructure: Number;
	idStructureMere: Number;

	constructor() {
		this.db = 0;
		this.idStructure = 0;
		this.idStructureMere = 0;
	}

	public fromObj(entry: Istr_structures_meres) {
		this.idStructure = entry.idStructure;
		this.idStructureMere = entry.idStructureMere;
		return this;
	}

}
