import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { fin_infosbancaires } from '../types/type_fin_infosbancaires';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FinInfosbancairesService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);		
		super.init("fin_infosbancaires", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	loadFromPersonne(idPersonne) {
		this.loadid = idPersonne
		super.init("fin_infosbancaires", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new fin_infosbancaires()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished fin_infosbancaires",this.entries)
	}


	override getDbFields() {
		return [{field:"id",nullable:false},
		{field:"etatBancaire",nullable:false},
		{field:"typeReglement",nullable:false},
		{field:"idCompteBancaire",nullable:true}]
	}

	async Add(
		etatBancaire,
		typeReglement,
		idCompteBancaire) {

		const res = new fin_infosbancaires()

		const now = moment().format("YYYY-MM-DD")
		
		res.db = 1
		res.etatBancaire = etatBancaire
		res.typeReglement = typeReglement
		res.idCompteBancaire = idCompteBancaire
		
		this.entries.push( res )
		const id = await this.save( res );
		res.id = Number(id);
		return res;
	}
}
