import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { TimeTaskLine, TimeTaskLineLog } from '../types/time-task-line';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TimeTaskLineService extends BaseService {

	loaded: string[] = []

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("timetaskline", this.loadTimeTaskLine.bind(this),
			[{ prop: "logs", table: "timetaskline_logs" }])
		
		/*	this.global.loaded.subscribe(value => {
			this.loaded.push(value)
			if (this.loaded.includes("timetasks") && this.loaded.includes("timetaskline")) {
				console.log("ok loaded", this.loaded)
				//this.getLastTimeTaskFromUser()
			}
		})*/
	}

	getIcon(entry, field) {
		if (this.isRunning(entry))
			return "pause"
		return "play"
	}

	isRunning(entry: TimeTaskLine) {
		if (!entry.logs)
			return false;
		const lastlog = entry.logs[entry.logs.length - 1]
		if (!lastlog)
			return false;
		if (!lastlog.datefin)
			return true;
		return false;
	}

	run(entry: any) {
		if (!entry.logs)
			entry.logs = []

		const lastlog = entry.logs[entry.logs.length - 1]
		if (lastlog && !lastlog.datefin) {
			lastlog.datefin = moment().format("YYYY-MM-DDTHH:mm:ss")
			const diff = moment(lastlog.datefin).diff(moment(lastlog.datedebut), 'seconds')
			console.log("lastlog", lastlog, diff)
			lastlog.totalsecond = diff
			lastlog.db = 1

			this.setEntryUpdate(entry);
			this.saveEntriesDelay();
			return
		}
		const log = new TimeTaskLineLog()
		log.id = this.getNewCustomId(entry.logs)
		log.idparent = entry.id
		entry.logs.push(log)
		console.log("run", log, entry)

		this.setEntryUpdate(entry);
		this.saveEntriesDelay();
	}

	override addEntry() {
		return super.addEntry(new TimeTaskLine())
	}

	getTotalSeconds(entry) {
		return entry.getTotalSeconds()
	}

	public formatHumandSeconds(entry) {
		const sec = entry.getTotalSeconds()
		//console.log("formatHumandSeconds",sec)
		const res = moment.utc(sec * 1000).format('HH:mm:ss');
		return res;
	}

	getMinutes(entry) {
		//console.log("getMinutes",entry)
		if (!this.isRunning(entry))
			return "<div style='display:flex;align-items:center;'>" + this.formatHumandSeconds(entry) + "</div>"
		else
			return "<div style='display:flex;align-items:center;'><ion-spinner style='font-size:10px;margin-right:10px;'></ion-spinner>" + this.formatHumandSeconds(entry) + "</div>"
	}

	getFirstDebut(entry) {
		if (!entry.logs || entry.logs.length == 0)
			return "";
		return moment(entry.logs[0].datedebut).format("DD/MM/YYYY HH:mm:ss");
	}

	override getEntriesToDisplay(idtimetask = null, search = null, sortedField = null) {
		//console.log("timetaskline getEntriesToDisplay",this.entries,idtimetask,search,sortedField)

		let res = null
		if (idtimetask != null) {
			res = this.entries.filter(x => x.db != 2 && x.idtimetask == idtimetask)
			//console.this.log("timetaskline getEntriesToDisplay res",res)
			return res;
		}
		return this.entries.filter(x => {
			let res = false
			res = (x.db != 2)
			if (!res)
				return false
			if (idtimetask)
				if (x.idtimetask != idtimetask)
					return false;
			if (search) {
				const xsearch = search.toLocaleLowerCase()
				let property: keyof typeof x;
				for (property in x) {
					if (typeof x[property] == "string" && x[property].toLocaleLowerCase().includes(xsearch))
						return true;
				}
				return false;
			}
			return true
		})
	}

	getLastTimeTaskLineFromUser(): any {
		const iduser = this.global.userid
		console.log("getLastTimeTaskFromUser", this.entries)
		const filteredTimeTaskLineUser = this.entries.filter(line => line.iduser == iduser)

		console.log("filteredTimeTaskLineUser filteredTimeTaskLineUser", filteredTimeTaskLineUser)
		const lastLogDate = filteredTimeTaskLineUser.map(line => line.getLastLogEntry() ).reduce((prev, current)=>{
            return (prev.datedebut > current.datedebut) ? prev : current
        })
		let res = null;
		if ( lastLogDate ) {
			res = this.entries.find(e=>e.id == lastLogDate.idparent)
		} 

		console.log("filteredTimeTaskLineUser lastLogDate", lastLogDate,res)

		return {lastTimeTaskLine:res, timeTask: this.global.allEntries["timetasks"].find(t=>t.id=res.idtimetask), lastLogDate:lastLogDate.datedebut}
	}

	override onLoadingFinished() {
		console.log("onLoadingFinished() start")
		const res = this.getLastTimeTaskLineFromUser()
		console.log("res",res)
		let title = "TimeLine"
		if ( res )
			title = res.timeTask.customer.company+" | " + res.lastTimeTaskLine.libelle+" "+moment(res.lastLogDate).format("DD/MM/YYYY HH:mm")
		this.global.mainPageTitle.next({ url: "/time-tasks", title: title })
		console.log("onLoadingFinished() end")
	}

	loadTimeTaskLine(dbentry, table) {
		if (table == "timetaskline_logs") {
			console.log("💾 timetaskline_logs", dbentry)
			const res = (new TimeTaskLineLog()).fromObj(dbentry)
			console.log("💾 timetaskline_logs res", res)
			return res
		}
		console.log("💾 loadTimeTaskLine", dbentry)
		const res = (new TimeTaskLine()).fromObj(dbentry)
		console.log("💾 loadTimeTaskLine res", res)
		return res
	}
}
