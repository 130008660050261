import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { fin_operation } from '../types/type_fin_operation';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { PdtProduitcommandeService } from './pdt-produitcommande.service';
import { FinBanquesService } from './fin-banques.service';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FinOperationService extends BaseService {

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector,
		public pdtProduitcommandeService: PdtProduitcommandeService,
		public finBanquesService: FinBanquesService,
	) {
		super(global, appPagesService, router, http, apiService, injector);
		console.log("constructor FinOperationService this.finBanquesService.map",this.finBanquesService.map,this.finBanquesService.entries);
		this.basetable = "fin_operation"
	}

	loadFromListe() {
		this.loadid = ""
		super.init("fin_operation", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
	}

	findBanque(idBanque) {
		if ( !idBanque )
			return null;
		console.log("this.finBanquesService.map",this.finBanquesService.map,this.finBanquesService.entries);
		return this.finBanquesService.map.get(String(idBanque))?.libelle
	}

	override getEntriesToDisplay(idvalue = null, search = null, sortedField = null) {
		
		//getEntriesToDisplay(idvalue = null, search = null, sortedField = null, idfieldname = "idcustomer")

		let tmp = super.getEntriesToDisplay(idvalue, search, sortedField)//, "idPersonne")
		
		/*tmp = tmp.filter(x=>!(x.company == "" && x.lastname=="" && x.firstname=="") )*/
		if (sortedField) {

			if (sortedField.sort == 1)
				return tmp.sort((a, b) => a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a, b) => b[sortedField.field].localeCompare(a[sortedField.field]))
		}
		
		return tmp
	}

	loadFromPersonne(idPersonne) {
		this.loadid = idPersonne
		super.init("fin_operation", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new fin_operation()).fromObj(dbentry)

		const produitCommande = this.pdtProduitcommandeService.map.get(String(res.idProduitCommande))

		if ( produitCommande ) {
			res.idCommande = produitCommande.idCommande
			res.idProduit = produitCommande.idProduit
		}

		//console.log("this.pdtProduitcommandeService.map.get",this.pdtProduitcommandeService.map,this.pdtProduitcommandeService.map.get("54534"))

		//console.log(res,res.idProduitCommande,produitCommande,this.pdtProduitcommandeService.map)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished finpoeration",this.entries)
	}


	override getDbFields() {
		return [{field:"id",nullable:false},
		{field:"credit",nullable:false},
		{field:"dateOperation",nullable:false},
		{field:"debit",nullable:false},
		{field:"libelle",nullable:true},
		{field:"numeroCheque",nullable:true},
		{field:"typeReglement",nullable:true},
		{field:"idBanque",nullable:true},
		{field:"idPersonne",nullable:true},
		{field:"idProduitCommande",nullable:true},
		{field:"idSaison",nullable:false},
		{field:"idStructure",nullable:true},
		{field:"idTypeOperation",nullable:false},
		{field:"idActeur",nullable:true},
		{field:"idCommandePrelevement",nullable:true},
		{field:"isCanceled",nullable:false},
		{field:"numeroAttFiscale",nullable:true},
		{field:"boutiquePaiement",nullable:true}]
	}

	getSolde() {
		const totalSum = this.entries.reduce((solde, entry) => {
			return solde + entry.debit + entry.credit;
		  }, 0);		  
	}

	async Add(
		credit,
		dateOperation,
		debit,
		libelle,
		numeroCheque,
		typeReglement,
		idBanque,
		idPersonne,
		idProduitCommande,
		idSaison,
		idStructure,
		idTypeOperation,
		idActeur,
		idCommandePrelevement,
		isCanceled,
		numeroAttFiscale,
		boutiquePaiement) {

		const res = new fin_operation()

		const now = moment().format("YYYY-MM-DD")
		
		res.db = 1
		res.credit = credit
		res.dateOperation = dateOperation
		res.debit = debit
		res.libelle = libelle
		res.numeroCheque = numeroCheque
		res.typeReglement = typeReglement
		res.idBanque = idBanque
		res.idPersonne = idPersonne
		res.idProduitCommande = idProduitCommande
		res.idSaison = idSaison
		res.idStructure = idStructure
		res.idTypeOperation = idTypeOperation
		res.idActeur = idActeur
		res.idCommandePrelevement = idCommandePrelevement
		res.isCanceled = isCanceled
		res.numeroAttFiscale = numeroAttFiscale
		res.boutiquePaiement = boutiquePaiement
		
		this.entries.push( res )
		const id = await this.save( res );
		res.id = Number(id);
		return res;
	}
}
