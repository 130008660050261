import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest, forkJoin } from 'rxjs';
import { GlobalService } from './global.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { FinPrelevementVirementService } from './fin-prelevement-virement.service';

@Injectable({
	providedIn: 'root'
})
export class FinPrelevementVirementResolver implements Resolve<boolean> {
	constructor(
		public global: GlobalService,
		public finPrelevementVirementService: FinPrelevementVirementService

	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		//const id = route.paramMap.get('id');
		//console.log("FinPrelevementVirementResolver resolve", id)

		console.log("debug5 FinPrelevementVirementResolver isready")

		return this.global.isReady.pipe(
			filter((ready) => ready === true),  // Wait until the boolean is `true`
			take(1),  // Only take the first `true` value and complete the observable
			switchMap(() => {

				this.finPrelevementVirementService.loadFromListe()

				return combineLatest([
					this.finPrelevementVirementService.getLoadingComplete(),
				]).pipe(
					// Filter only when all values are true
					filter(([
						finPrelevementVirementComplete,
					]) =>
						finPrelevementVirementComplete
					)
				)
			})
		)
	}
}
