export interface Ifin_infosbancaires {
	id: Number;
	etatBancaire: String;
	typeReglement: String;
	idCompteBancaire: Number;
}

export class fin_infosbancaires implements Ifin_infosbancaires {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	etatBancaire: String;
	typeReglement: String;
	idCompteBancaire: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.etatBancaire = "";
		this.typeReglement = "";
		this.idCompteBancaire = 0;
	}

	public fromObj(entry: Ifin_infosbancaires) {
		this.id = entry.id;
		this.etatBancaire = entry.etatBancaire;
		this.typeReglement = entry.typeReglement;
		this.idCompteBancaire = entry.idCompteBancaire;
		return this;
	}

}
