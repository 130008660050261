export interface Iins_fonction_autorisee {
	id: Number;
	idInstance: Number;
	idTypeFonction: Number;
	honorabiliteAllowed: String;
	genre: String;
}

export class ins_fonction_autorisee implements Iins_fonction_autorisee {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	idInstance: Number;
	idTypeFonction: Number;
	honorabiliteAllowed: String;
	genre: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.idInstance = 0;
		this.idTypeFonction = 0;
		this.honorabiliteAllowed = "";
		this.genre = "";
	}

	public fromObj(entry: Iins_fonction_autorisee) {
		this.id = entry.id;
		this.idInstance = entry.idInstance;
		this.idTypeFonction = entry.idTypeFonction;
		this.honorabiliteAllowed = entry.honorabiliteAllowed;
		this.genre = entry.genre;
		return this;
	}

}
