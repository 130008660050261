<ion-item class="main" [ngClass]="noborder ? 'no-border' : ''">
	<ion-label>{{label}}</ion-label>
	<ion-input (click)="setOpen(true)" fill="outline" [(ngModel)]="this.sectionlocale" type="text" autocorrect="off"
		autocapitalize="none" class="withborder label100px"
		[ngClass]="textalignright ? 'textalignright' : ''"></ion-input>

	<ion-icon (click)="setOpen(true)" name="search" color="primary" slot="end"></ion-icon>
	<ion-icon (click)="clear()" name="trash" color="primary" slot="end"></ion-icon>
</ion-item>

<ion-modal #modal [isOpen]="isModalOpen" [canDismiss]="canDismiss" (willDismiss)="onWillDismiss($event)"
	>
<!--[initialBreakpoint]="1" [breakpoints]="[0, 1]"-->

	<ng-template>
		<ion-header>
			<ion-toolbar>
				<ion-title>CHOIX DE LA SECTION LOCALE</ion-title>
				<ion-buttons slot="end">
					<ion-button (click)="setOpen(false)">
						<ion-icon name="close"></ion-icon>
					</ion-button>
				</ion-buttons>
			</ion-toolbar>
		</ion-header>

		<ion-content>
			<app-section-locale [hideToolbar]="true" (mySelectItem)="select($event)" [closeEnabled]="false"></app-section-locale>
		</ion-content>
	</ng-template>
</ion-modal>