export interface Idrt_acces_role {
	idAcces: Number;
	idRole: Number;
}

export class drt_acces_role implements Idrt_acces_role {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	idAcces: Number;
	idRole: Number;

	constructor() {
		this.db = 0;
		this.idAcces = 0;
		this.idRole = 0;
	}

	public fromObj(entry: Idrt_acces_role) {
		this.idAcces = entry.idAcces;
		this.idRole = entry.idRole;
		return this;
	}

}
