export interface Idrt_acces {
	id: Number;
	idStructure: Number;
	idUtilisateur: Number;
}

export class drt_acces implements Idrt_acces {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	idStructure: Number;
	idUtilisateur: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.idStructure = 0;
		this.idUtilisateur = 0;
	}

	public fromObj(entry: Idrt_acces) {
		this.id = entry.id;
		this.idStructure = entry.idStructure;
		this.idUtilisateur = entry.idUtilisateur;
		return this;
	}

}
