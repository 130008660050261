import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { StrStructureService } from 'src/app/Services/str-structure.service';

@Component({
	selector: 'app-section-locale',
	templateUrl: './section-locale.component.html',
	styleUrls: ['./section-locale.component.scss'],
})
export class SectionLocaleComponent implements OnInit {

	@Output() isModalOpen = false;

	public sectionlocale = ""
	public selectedItem: any;
	public selectedItemId = "";
	public structureActuelle = "";
	public entries = this.structureService.displayentries;
	public search = "";
	public structureSelected = ""

	canDismiss = false;

	@Input() mode = 1;
	@Input() hideToolbar = false;
	@Input() hideTitle = 0;
	@Input() closeEnabled: boolean = true;
	@Input() textalignright: boolean = false;
	@Input() noborder: boolean = false;
	@Input() label: string = "";
	@Input() myProp: string;
	@Input() produitCommande: any = null
	@Output() myPropChange = new EventEmitter<string>();
	@Output() mySelectItem = new EventEmitter<string>();
	@Output() isModalOpenEvent = null;//EventEmitter<boolean> = new EventEmitter<boolean>(); // Use EventEmitter


	setOpen(isOpen: boolean) {
		if (!isOpen)
			this.canDismiss = true;
		if (isOpen)
			this.canDismiss = false;
		console.log("isOpen", isOpen)
		this.isModalOpen = isOpen;
		if ( this.isModalOpenEvent ) {
			console.log("this.isModalOpenEvent",isOpen,this.selectedItemId,this.produitCommande)
			this.isModalOpenEvent(isOpen,this.selectedItemId,this.produitCommande)
		}
	}

	isVisible(item) {
		if (item.children.length == 0) {
			if (this.search != "") {
				if (item.nom.toLowerCase().indexOf(this.search) > -1) {
					return true;
				}
				return false;
			}
			return true;
		}
		return true;
	}

	filter(entry) {
		if (entry.children.length == 0) {
			entry.visible = true;
			if (entry.nom.toLowerCase().indexOf(this.search) == -1)
				entry.visible = false;
		}
		entry.children.forEach(e => { this.filter(e) })
	}

	allChildrenVisible(entry) {
		if (entry.children.length == 0) {
			return entry.visible;
		}
		let nb = 0;
		entry.children.forEach(e => {
			if (this.allChildrenVisible(e))
				nb = nb + 1
		})
		console.log("allChildrenVisible", entry.nom, entry.children.length, nb)
		if (nb > 0) {
			entry.visible = true;
			return true;
		}
		entry.visible = false;
		return false;
	}

	handleSearch(event) {
		this.search = event.target.value.toLowerCase();
		this.filter(this.entries[0])
		this.allChildrenVisible(this.entries[0])
	}

	clear() {
		this.selectedItem = null;
		this.selectedItemId = "";
		this.sectionlocale = "";
	}

	constructor(public structureService: StrStructureService) {

	}

	onWillDismiss(event: Event) {
		console.log("onWillDismiss")
	}

	addSubentry(parent: Node) {
		/*const n = new Node()	
		n.libelle = this.nodeService.makeid(10)	
		n.parents.push(parent)
	
		parent.children.push(n)
		parent.expanded = true
	  	
		console.log("addsubentry",this.nodeService.entries)
	
		this.nodeService.saveEntriesDelay()*/
	}

	ngOnInit() {
		this.entries = this.structureService.displayentries;
		console.log("debug6 section-locale", this.structureService.displayentries, this.entries[0].expanded, this.myProp, this.sectionlocale)
		console.log("debug6 section-locale", this.produitCommande )
		this.entries[0].expanded = true

		this.structureActuelle = ""
		if ( this.produitCommande ) {
			this.myProp = this.produitCommande.idStructure
			this.structureActuelle = this.produitCommande.structure.code + " " + this.produitCommande.structure.nom
		}

		if (this.myProp != "") {
			//console.log("this.structureService.entries",this.structureService.flatentries)
			const structure = this.structureService.entries.find(str => str.id == this.myProp)
			console.log("debug6 structure", structure)
			if (structure) {
				this.sectionlocale = structure.code + "-" + structure.nom
			}
		}

		/*this.search = "clermont"
		console.log("this.structureService.entries1", this.structureService.entries)
		this.filter(this.entries[0])
		console.log("this.structureService.entries2", this.structureService.entries)
		this.allChildrenVisible(this.entries[0])
		console.log("this.structureService.entries3", this.structureService.entries)*/
	}

	select(item: any) {
		console.log(item)
		this.canDismiss = true;
		this.isModalOpen = false

		this.selectedItem = item
		this.selectedItemId = item.id
		this.sectionlocale = item.code + "-" + item.nom

		this.myProp = item.id
		this.myPropChange.emit(this.myProp)
		this.mySelectItem.emit(this.myProp)

		console.log("select",this.selectedItemId)
		this.structureSelected = this.sectionlocale
	}

	changeStructureOk() {
		console.log("changestructureok",this.selectedItemId)
		this.setOpen(false)
	}

	PopupSectionLocale() {

	}

}
