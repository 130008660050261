import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { fin_banques } from '../types/type_fin_banques';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FinBanquesService extends BaseService {

  override authneeded: boolean  = false;

  constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("fin_banques", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new fin_banques()).fromObj(dbentry)
		return res

	}

	override onLoadingFinished() {
		console.log("finished fin_banques",this.entries)
	}


	override getDbFields() {
		return [{field:"id",nullable:false},
		{field:"libelle",nullable:false}]
	}

	async Add(
		libelle
  ) {

		const res = new fin_banques()

		const now = moment().format("YYYY-MM-DD")
		
		res.db = 1
		res.libelle = libelle

		this.entries.push( res )
		const id = await this.save( res );
    console.log("fin_comptebancaire save id",id)
		res.id = Number(id);
		return res;
	}
}
