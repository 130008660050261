export interface Ifin_typeoperation {
	id: Number;
	code: String;
	libelle: String;
	sens: String;
	saisieManuelleAutorisee: String;
	idGroupeOperation: Number;
	importFichierAutorise: String;
}

export class fin_typeoperation implements Ifin_typeoperation {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	libelle: String;
	sens: String;
	saisieManuelleAutorisee: String;
	idGroupeOperation: Number;
	importFichierAutorise: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.libelle = "";
		this.sens = "";
		this.saisieManuelleAutorisee = "";
		this.idGroupeOperation = 0;
		this.importFichierAutorise = "";
	}

	public fromObj(entry: Ifin_typeoperation) {
		this.id = entry.id;
		this.code = entry.code;
		this.libelle = entry.libelle;
		this.sens = entry.sens;
		this.saisieManuelleAutorisee = entry.saisieManuelleAutorisee;
		this.idGroupeOperation = entry.idGroupeOperation;
		this.importFichierAutorise = entry.importFichierAutorise;
		return this;
	}

}
