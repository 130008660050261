<ion-item class="main" [ngClass]="noborder ? 'no-border' : ''">
	<ion-label>{{label}}</ion-label>
<ion-input (click)="setOpen(true)" fill="outline" [(ngModel)]="this.sectionlocale" type="text"
	autocorrect="off" autocapitalize="none" class="withborder label100px" [ngClass]="textalignright ? 'textalignright' : ''"></ion-input>

	<ion-icon (click)="setOpen(true)" name="search" color="primary" slot="end"></ion-icon>
	<ion-icon (click)="clear()" name="trash" color="primary" slot="end"></ion-icon>
</ion-item>

<ion-modal #modal [isOpen]="isModalOpen" [canDismiss]="canDismiss" 
	(willDismiss)="onWillDismiss($event)" [initialBreakpoint]="1" [breakpoints]="[0, 1]">
	<ng-template>

		<app-section-locale (mySelectItem)="select($event)" 
		[closeEnabled]="true"></app-section-locale>

	</ng-template>
</ion-modal>