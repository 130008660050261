import { Injectable } from '@angular/core';
//import { table } from 'console';
import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface CacheDB extends DBSchema {
	cache: {
		key: string;
		value: any;
	};
}

@Injectable({
	providedIn: 'root'
})
export class CacheService {
	private dbPromise: Promise<IDBPDatabase<CacheDB>>;

	constructor() {
		this.dbPromise = openDB<CacheDB>('cache-db', 1, {
			upgrade(db) {
				db.createObjectStore('cache', { keyPath: 'key' });
			}
		});
	}

	async addRoute(route: string) {
		this.delete2("route")
		this.set("route",route)
		console.log("addRoute",route)
		/*const db = await this.dbPromise;
		await db.add('cache', { route, timestamp: new Date() });*/
	}

	async getRoutes() {
		const db = await this.dbPromise;
		return await db.getAll('cache');
	}

	async get(key: string): Promise<any> {
		try {
			const db = await this.dbPromise;
			//if (key == "https://restsnptes.edservices.fr/api/process.php?str_typestructure=")
			console.log("get", key)

			const res = await db.get('cache', key)
			if (key == "https://restsnptes.edservices.fr/api/process.php?str_typestructure=")
				console.log("get ok", key, res.value.length)
			return res.value
		} catch (error) {
			console.log("get error", key, error)
			console.error('An error occurred:', error);
		}
		return undefined
	}

	async delete(tablename: string) {
		console.log("cache service delete", tablename)
		const key = "https://restsnptes.edservices.fr/api/process.php?" + tablename + "="
		console.log("delete", key)
		const db = await this.dbPromise;
		await db.delete('cache', key);
	}

	async delete2(tablename: string, id?) {
		console.log("cache service delete2", tablename)
		const key = this.createCacheKey2(tablename, id)
		console.log("delete2", key)
		const db = await this.dbPromise;
		await db.delete('cache', key);
	}

	async set(key: string, value: any): Promise<void> {
		const db = await this.dbPromise;
		await db.put('cache', { key, value });
	}

	async has(key: string): Promise<boolean> {
		return false
		const db = await this.dbPromise;
		const result = await db.get('cache', key);
		return result !== undefined;
	}

	public createCacheKey(url: string, params?: any, id?: any): string {
		let key = url;
		if (params) {
			key += '?' + new URLSearchParams(params).toString();
		}
		return key;
	}

	public createCacheKey2(table: string, id?: any): string {
		let key = table;
		if (id) {
			key += "/" + id
		}
		return key;
	}
}
