export interface Ilic_licencecommandee {
	dateDebutValidite: String;
	dateFinValidite: String;
	dateSouscription: String;
	dateValidation: String;
	edite: String;
	modeReglement: String;
	id: Number;
	idCategorie: Number;
	dateEdition: String;
	retraite: Number;
	tempsPartiel: String;
	bap: String;
	idStatut: Number;
	idCorps: Number;
	idGrade: Number;
	idEmploi: Number;
	idDelegation: Number;
	idUnite: Number;
	idEtablissement: Number;
	echelon: String;
	typeContrat: String;
}

export class lic_licencecommandee implements Ilic_licencecommandee {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	dateDebutValidite: String;
	dateFinValidite: String;
	dateSouscription: String;
	dateValidation: String;
	edite: String;
	modeReglement: String;
	id: Number;
	idCategorie: Number;
	dateEdition: String;
	retraite: Number;
	tempsPartiel: String;
	bap: String;
	idStatut: Number;
	idCorps: Number;
	idGrade: Number;
	idEmploi: Number;
	idDelegation: Number;
	idUnite: Number;
	idEtablissement: Number;
	echelon: String;
	typeContrat: String;

	constructor() {
		this.db = 0;
		this.dateDebutValidite = "";
		this.dateFinValidite = "";
		this.dateSouscription = "";
		this.dateValidation = "";
		this.edite = "";
		this.modeReglement = "";
		this.id = 0;
		this.idCategorie = 0;
		this.dateEdition = "";
		this.retraite = 0;
		this.tempsPartiel = "";
		this.bap = "";
		this.idStatut = 0;
		this.idCorps = 0;
		this.idGrade = 0;
		this.idEmploi = 0;
		this.idDelegation = 0;
		this.idUnite = 0;
		this.idEtablissement = 0;
		this.echelon = "";
		this.typeContrat = "";
	}

	public fromObj(entry: Ilic_licencecommandee) {
		this.dateDebutValidite = entry.dateDebutValidite;
		this.dateFinValidite = entry.dateFinValidite;
		this.dateSouscription = entry.dateSouscription;
		this.dateValidation = entry.dateValidation;
		this.edite = entry.edite;
		this.modeReglement = entry.modeReglement;
		this.id = entry.id;
		this.idCategorie = entry.idCategorie;
		this.dateEdition = entry.dateEdition;
		this.retraite = entry.retraite;
		this.tempsPartiel = entry.tempsPartiel;
		this.bap = entry.bap;
		this.idStatut = entry.idStatut;
		this.idCorps = entry.idCorps;
		this.idGrade = entry.idGrade;
		this.idEmploi = entry.idEmploi;
		this.idDelegation = entry.idDelegation;
		this.idUnite = entry.idUnite;
		this.idEtablissement = entry.idEtablissement;
		this.echelon = entry.echelon;
		this.typeContrat = entry.typeContrat;
		return this;
	}

}
