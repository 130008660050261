import { Injectable, Injector } from '@angular/core';
import { Customer } from '../types/customer';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { per_personne } from '../types/type_per_personne';
import { pdt_commande } from '../types/type_pdt_commande';
import { pdt_produitcommande } from '../types/type_pdt_produitcommande';
import { LicLicencecommandeeService } from './lic-licencecommandee.service';
import { LicCategorieAgeService } from './lic-categorie-age.service';
import { InsTypeFonctionService } from './ins-type-fonction.service';
import { ServerDBApiService } from './server-dbapi.service';
import { DatePipe } from '@angular/common';
import { StrStructureService } from './str-structure.service';

@Injectable({
	providedIn: 'root'
})
export class PerPersonneService extends BaseService {

	override authneeded: boolean = false;

	globalEntries: any[];

	get formattedDateNaissance(): string {
		return this.datePipe.transform(this.currentEntry.dateNaissance, 'dd/MM/yyyy');
	}

	set formattedDateNaissance(value: string) {
		this.currentEntry.dateNaissance = value;
	}

	//Désactivé car je ne sias plius à quoi ça sert
	//>>> ça sert à charger la liste des peronnses
	//quand on sélectionne une structure
	public override getParams() {
		let array = {droits:this.global.droits,
			structure:this.strStructureService.getCurrentEntry()?.id}
		console.log("debug5 abc per-personne getParams",array)
		return JSON.stringify(array)
	}

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector,
		public licLicencecommandeeService: LicLicencecommandeeService,
		public licCategorieAgeService: LicCategorieAgeService,
		public insTypeFonctionService: InsTypeFonctionService,
		private datePipe: DatePipe,
		public strStructureService: StrStructureService

	) {
		super(global, appPagesService, router, http, apiService, injector);
		

		/*this.global.loaded.subscribe(table=>{
			if ( this.global.allMaps["pdt_produitcommande"] 
				&& this.global.allMaps["str_structure"]
				&& this.global.allMaps["cfg_saison"]
				&& this.global.allMaps["pdt_tarifproduit"]
				&& this.global.allMaps["pdt_produit"]
				&& this.global.allMaps["lic_categorieage"]
				&& this.global.allMaps["lic_licencecommandee"]
				&& this.global.allMaps["ins_typefonction"]
			) {
				console.log("global loaded ALL")
				this.assign_Last_pdt_produitcommande()
				this.assign_cfg_saison()
			}
		})*/
	}

	laodCure

	getId(entry) {
		return String(entry.id)
	}

	loadAll() {
		console.log("debug5 perPersonneService loadAll")
		this.loadid = null
		super.init("per_personne", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
	}

	loadFromId(idPersonne) {
		this.loadid = idPersonne
		super.init("per_personne", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
	}

	override addEntry(): any {
		return super.addEntry(new per_personne())
	}

	override getSingleUrl() {
		return '/adherent/' + this.currentEntry.id
	}

	override getListUrl() {
		return '/adherents'
	}

	override getSingleIcon() {
		return 'person'
	}

	override getListIcon() {
		return 'people'
	}

	override onLoadingFinished() {
		//this.entries = this.entries.filter(x=>x.id<100)
		console.log("finished perpersonne", this.entries)
	}

	assign_cfg_saison() {
		//const cfg_saison = this.global.allMaps["pdt_produitcommande"]
		const cfg_saisonMap = this.global.allMaps["cfg_saison"]
		console.log("start assign_cfg_saison", cfg_saisonMap)
		/*this.entries.forEach(entry=>{			
			entry.pdt_produitcommande = pdt_produitcommandeMap.get(entry.idDerniereLicence)
			entry.str_structure = undefined
			if ( entry.pdt_produitcommande )
				entry.str_structure = 	str_structureMap.get( entry.pdt_produitcommande.idStructure )				
		})*/
		console.log("end assign_cfg_saison", this.entries)
	}

	assign_Last_pdt_produitcommande() {

		const pdt_produitMap = this.global.allMaps["pdt_produit"]
		this.global.allEntries["pdt_tarifproduit"].forEach(tp => {
			if (tp.idProduit) {
				tp.produit = pdt_produitMap.get(tp.idProduit)
			}
		})

		this.global.allEntries["lic_categorieage"].forEach(ca => {
			if (ca.idProduit) {
				ca.produit = pdt_produitMap.get(ca.idProduit)
			}
		})

		const lic_categorieageMap = this.global.allMaps["lic_categorieage"]
		this.global.allEntries["lic_licencecommandee"].forEach(lc => {
			if (lc.idCategorie) {
				lc.categorieage = lic_categorieageMap.get(lc.idCategorie)
			}
		})

		const lic_licencecommandeeMap = this.global.allMaps["lic_licencecommandee"]
		this.global.allEntries["pdt_produitcommande"].forEach(pc => {
			if (pc.idProduit) {
				pc.licencecommandee = lic_licencecommandeeMap.get(pc.id)
			}
		})

		const pdt_produitcommandeMap = this.global.allMaps["pdt_produitcommande"]
		const pdt_produitcommande_personneMap = this.global.allMaps["pdt_produitcommande_personne"]
		const pdt_tarifproduitMap = this.global.allMaps["pdt_tarifproduit"]
		const cfg_saisonMap = this.global.allMaps["cfg_saison"]
		const str_structureMap = this.global.allMaps["str_structure"]
		const pdt_commande = this.global.allMaps["pdt_commande"]

		console.log("start assign_Last_pdt_produitcommande2", pdt_produitcommandeMap,
			str_structureMap, pdt_produitcommande_personneMap,
			this.global.allEntries["pdt_tarifproduit"],
			this.global.allEntries["lic_categorieage"]
		)

		this.entries.forEach(entry => {
			entry.pdt_produitcommande = pdt_produitcommandeMap.get(entry.idDerniereLicence)
			entry.pdt_produitcommandes = pdt_produitcommande_personneMap.get(entry.id)

			if (entry.pdt_produitcommandes) {
				entry.pdt_produitcommandes.forEach(p => {
					p.saison = cfg_saisonMap.get(p.idSaison)
					p.structure = str_structureMap.get(p.idStructure)
					p.tarifproduit = pdt_tarifproduitMap.get(p.idTarif)
					p.commande = pdt_commande.get(p.idCommande)
				})

			}

			//pdt-produitcommande_personne
			//entry.pdt_produitcommandes.sort((a, b) => a.saison.dateDebutSaison < b.saison.dateDebutSaison)

			entry.str_structure = undefined
			if (entry.pdt_produitcommande)
				entry.str_structure = str_structureMap.get(entry.pdt_produitcommande.idStructure)
		})
		console.log("end assign_Last_pdt_produitcommande", this.entries)
	}

	override getEntriesToDisplay(identry = null, search = null, sortedField = null) {
		let tmp = super.getEntriesToDisplay(identry, search, sortedField)
		/*tmp = tmp.filter(x=>!(x.company == "" && x.lastname=="" && x.firstname=="") )
		if ( sortedField ) {
			
			if ( sortedField.sort == 1 )
				return tmp.sort((a,b)=>a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a,b)=>b[sortedField.field].localeCompare(a[sortedField.field]))
		}*/
		return tmp
	}

	override getTitle(entry: any = null) {
		if (!entry)
			entry = this.currentEntry
		//console.log("per-personne service getTitle",entry)
		if ( !entry ) {
			return ""
		}
		let tmp = ""
		if ( entry.codeAdherent )
			tmp += entry.codeAdherent.trim() + " - "
		tmp += entry.nom.trim() + " " + entry.prenom.trim()
		if (tmp == "") {
			return "(Nouvel adhérent)"
		}
		return tmp
	}

	getFromId(id: String) {
		return this.fastFindEntry(id)
	}

	getPersonneLoggedIn() {
		if ( this.global.currentPersonne == null ) {
			const id = this.global.getIdPersonneLoggedIn()
			this.global.currentPersonne = this.entries.find( x=> x.id == id )		
		}
		return this.global.currentPersonne
	}

	//funtion passed in constructor
	loadEntry(dbentry) {
		if ( dbentry.codeAdherent && dbentry.codeAdherent.length<6)
			dbentry.codeAdherent = this.global.formatStringWithLeadingZeros(dbentry.codeAdherent,6)
		const res = (new per_personne()).fromObj(dbentry)
		return res

	}

	getFullAddress2(entry) {
		let res = entry.civilite.trim() + " " + entry.nom.trim() + " " + entry.prenom.trim() + "\n"		
		if ( entry.adresse.rue )
			res += entry.adresse.rue + "\n"
		if ( entry.adresse.immBatRes )
			res += entry.adresse.immBatRes + "\n"
		if ( entry.adresse.lieuDit )
			res += entry.adresse.lieuDit + "\n"
		res += entry.adresse.codePostal + " " + entry.adresse.ville
		return res.trim()
	}

	getFullAddress() {
		let res = this.currentEntry.adresse.rue + "\n"
		res += this.currentEntry.adresse.immBatRes + "\n"
		res += this.currentEntry.adresse.lieuDit
		return res.trim()
	}

	getBulletinInfoDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "journalSNPTESPapier", nullable: true },
		{ field: "journalUNSAPapier", nullable: true },

		]
	}

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "civilite", nullable: true },
		{ field: "codeAdherent", nullable: true },
		{ field: "dateNaissance", nullable: true },
		{ field: "etat", nullable: true },
		{ field: "lieuNaissance", nullable: true },
		{ field: "nationalite", nullable: true },
		{ field: "nom", nullable: true },
		{ field: "nomEpure", nullable: true },
		{ field: "nomJeuneFille", nullable: true },
		{ field: "nomJeuneFilleEpure", nullable: true },
		{ field: "prenom", nullable: true },
		{ field: "prenomEpure", nullable: true },
		{ field: "profession", nullable: true },
		{ field: "sexe", nullable: true },
		{ field: "situationFamille", nullable: true },
		{ field: "utilisationAdresse", nullable: true },
		{ field: "idAdresse", nullable: true },
		{ field: "idcategorieSocioPro", nullable: true },
		{ field: "idPhotoIdentite", nullable: true },
		{ field: "idDerniereLicence", nullable: true },
		{ field: "identifiantExterne", nullable: true },
		{ field: "idInfosBancaires", nullable: true },
		{ field: "paysNaissance", nullable: true },
		{ field: "departementNaissance", nullable: true },
		{ field: "prenom2", nullable: true },
		{ field: "prenom3", nullable: true },
		{ field: "nomPere", nullable: true },
		{ field: "prenomPere", nullable: true },
		{ field: "nomMere", nullable: true },
		{ field: "prenomMere", nullable: true },
		{ field: "infosAControler", nullable: true },
		{ field: "dateMajInfosAControler", nullable: true },
		{ field: "journalSNPTESPapier", nullable: true },
		{ field: "journalUNSAPapier", nullable: true },

		]
	}


	findCodeNomPrenom(id) {
		const entry = this.entries.find(x => x.id == id)
		if (entry) {
			let tmp = entry.nom.trim() + " " + entry.prenom.trim()
			if ( entry.codeAdherent )
				tmp = entry.codeAdherent.trim() + " - " + tmp
			return tmp
		}
		return "?"
	}

	/*findCustomer(id): Customer {
		this.currentCustomer = null;
		this.currentCustomer = this.customers.find(cust => cust.id == id)
		return this.currentCustomer
	}*/
}
