import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { cfg_saison } from '../types/type_cfg_saison';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CfgSaisonService extends BaseService {

	//currentEntry contient la saison courante
	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("cfg_saison", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new cfg_saison()).fromObj(dbentry)
		return res

	}

	getNextSaison() {
		return this.entries.find(x=>x.id == this.getNextSaisonId())
	}

	getNextSaisonId() {
		return "2025"
	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		//this.entries = this.entries.filter(x=>x.id<100)

		this.currentEntry = this.entries.find(x=>x.id=="2024")
		console.log("finished cfgsaison",this.entries,this.currentEntry)
	}
}
