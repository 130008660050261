import { Injectable } from '@angular/core';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PdtCommandeService } from './pdt-commande.service';
import { GlobalService } from './global.service';
import { Observable, combineLatest, forkJoin, timer } from 'rxjs';
import { CfgSaisonService } from './cfg-saison.service';
import { PdtCommandesStatAdhesionsService } from './pdt-commandes-stat-adhesions.service';

@Injectable({
	providedIn: 'root'
})
export class PdtCommandeResolverService implements Resolve<any> {

	constructor(public global: GlobalService,
		public pdtCommandeService: PdtCommandeService,
		public cfgSaisonService: CfgSaisonService,
		public pdtCommandesStatAdhesionsService: PdtCommandesStatAdhesionsService
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const id = route.paramMap.get('id');
		console.log("debug1 AdherentResolverService resolve", id)

		console.log("debug1 AdherentResolverService isready")

		return this.global.isReady.pipe(
			filter((ready) => ready === true),  // Wait until the boolean is `true`
			take(1),  // Only take the first `true` value and complete the observable
			switchMap(() => {

				console.log("debug1 AdherentResolverService globa isready ok")

				this.pdtCommandeService.loadAll()
				this.cfgSaisonService.reload()
				this.pdtCommandesStatAdhesionsService.reload()

				return combineLatest([
					this.pdtCommandeService.getLoadingComplete(),
					this.cfgSaisonService.getLoadingComplete(),
					this.pdtCommandesStatAdhesionsService.getLoadingComplete()
					//delay
				]).pipe(
					// Filter only when all values are true
					filter(([
						pdtCommandeServiceComplete,
						cfgSaisonServiceComplete,
						pdtCommandesStatAdhesionsServiceComplete,
						//	delay
					]) =>
						pdtCommandeServiceComplete &&
						cfgSaisonServiceComplete &&
						pdtCommandesStatAdhesionsServiceComplete
					//	delay==0
					)
				)
			})
		)

		//"fin_operation", on charge au coup par coup maintenant
		//"fin_prelevement_virement", on charge au coup par coup maintenant
		//"pdt_commande", on charge au coup par coup maintenant
		//"lic_licencecommandee", on charge au coup par coup maintenant
		//"trc_trace",	
		//cfgParametresLicenceCommandeeService

		/*return this.global.isReady.pipe(
			filter((ready) => ready === true),  // Wait until the boolean is `true`
			take(1),  // Only take the first `true` value and complete the observable
			switchMap(() => {

				console.log("debug1 AdherentResolverService isready")

				this.finOperationService.loadFromPersonne(id)
				this.finPrelevementVirementService.loadFromPersonne(id)
				this.trcTraceService.loadFromPersonne(id)
				this.pdtCommandeService.loadFromPersonne(id)
				this.pdtProduitcommandeService.loadFromPersonne(id)
				this.cfgParametresLicenceCommandeeService.loadFromPersonne(id)

				return forkJoin({
					finOperationComplete: this.finOperationService.getLoadingComplete().pipe(filter(val => val === true)),
					finPrelevementVirementComplete: this.finPrelevementVirementService.getLoadingComplete().pipe(filter(val => val === true)),
					trcTraceComplete: this.trcTraceService.getLoadingComplete().pipe(filter(val => val === true)),
					pdtCommandeComplete: this.pdtCommandeService.getLoadingComplete().pipe(filter(val => val === true)),
					pdtProduitcommandeComplete: this.pdtProduitcommandeService.getLoadingComplete().pipe(filter(val => val === true)),
					cfgParametresLicenceCommandeeComplete: this.cfgParametresLicenceCommandeeService.getLoadingComplete().pipe(filter(val => val === true)),
				})
			})
		)*/
	}
}
