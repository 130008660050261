export interface Istr_typestructure {
	id: Number;
	code: String;
	libelle: String;
	ordre: Number;
}

export class str_typestructure implements Istr_typestructure {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	libelle: String;
	ordre: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.libelle = "";
		this.ordre = 0;
	}

	public fromObj(entry: Istr_typestructure) {
		this.id = entry.id;
		this.code = entry.code;
		this.libelle = entry.libelle;
		this.ordre = entry.ordre;
		return this;
	}

}
