import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { adr_adressepostale } from '../types/type_adr_adressepostale';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class AdrAdressePostaleService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("adr_adressepostale", this.loadEntry.bind(this), [], this.getId.bind(this))
	}
	
	override addEntry(): any {
		return super.addEntry(new adr_adressepostale())
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new adr_adressepostale()).fromObj(dbentry)
		return res

	}

	override getDbFields() {
		console.log("aa getDbFields")
		return [{ field: "id", nullable: false },
		{ field: "apptEtageEsc", nullable: true },
		{ field: "codePostal", nullable: true },
		{ field: "codeInsee", nullable: true },
		{ field: "fax1", nullable: true },
		{ field: "immBatRes", nullable: true },
		{ field: "lieuDit", nullable: true },
		{ field: "mobile1", nullable: true },
		{ field: "mobile2", nullable: true },
		{ field: "nomVoie", nullable: true },
		{ field: "numero", nullable: true },
		{ field: "pays", nullable: true },
		{ field: "telephone1", nullable: true },
		{ field: "telephone2", nullable: true },
		{ field: "typeVoie", nullable: true },
		{ field: "ville", nullable: true },
		{ field: "email", nullable: true },
		{ field: "email2", nullable: true },
		{ field: "longitude", nullable: true },
		{ field: "latitude", nullable: true },
		{ field: "rue", nullable: true }
	]
	}

}
