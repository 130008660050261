import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest, forkJoin, timer } from 'rxjs';
import { GlobalService } from './global.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { GedFichierService } from './ged-fichier.service';
import { GedRepertoireService } from './ged-repertoire.service';

@Injectable({
	providedIn: 'root'
})
export class GedResolverResolver implements Resolve<boolean> {

	constructor(
		public global: GlobalService,
		public gedFichierService: GedFichierService,
		public gedRepertoireService: GedRepertoireService

	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const id = route.paramMap.get('id');
		console.log("GedResolverResolver resolve", id)

		console.log("debug1 GedResolverResolver isready")

		return this.global.isReady.pipe(
			filter((ready) => ready === true),  // Wait until the boolean is `true`
			take(1),  // Only take the first `true` value and complete the observable
			switchMap(() => {

				this.gedFichierService.reload()
				this.gedRepertoireService.reload()

				return combineLatest([
					this.gedFichierService.getLoadingComplete(),
					this.gedRepertoireService.getLoadingComplete(),
				]).pipe(
					// Filter only when all values are true
					filter(([
						gedFichierServiceComplete,
						gedRepertoireServiceComplete,
					]) =>
						gedFichierServiceComplete &&
						gedRepertoireServiceComplete 
					)
				)
			})
		)
	}
}
