import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { pdt_produit } from '../types/type_pdt_produit';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PdtProduitService extends BaseService {

	override authneeded = false;
	
	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("pdt_produit", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	findProduit(id) {
		return this.entries.find(entry=>entry.id==id)?.libelle
	}

	getFromFamille(idFamille) {
		return this.entries.filter(f=>f.idFamille == idFamille && f.etat=="Actif");
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new pdt_produit()).fromObj(dbentry)
		return res

	}
}
