export interface Ifin_operation {
	id: Number;
	credit: Number;
	dateOperation: String;
	debit: Number;
	libelle: String;
	numeroCheque: String;
	typeReglement: String;
	idBanque: Number;
	idPersonne: Number;
	idProduitCommande: Number;
	idSaison: Number;
	idStructure: Number;
	idTypeOperation: Number;
	idActeur: Number;
	idCommandePrelevement: Number;
	isCanceled: String;
	numeroAttFiscale: String;
	boutiquePaiement: String;
}

export class fin_operation implements Ifin_operation {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	credit: Number;
	dateOperation: String;
	debit: Number;
	libelle: String;
	numeroCheque: String;
	typeReglement: String;
	idBanque: Number;
	idPersonne: Number;
	idProduitCommande: Number;
	idSaison: Number;
	idStructure: Number;
	idTypeOperation: Number;
	idActeur: Number;
	idCommandePrelevement: Number;
	isCanceled: String;
	numeroAttFiscale: String;
	boutiquePaiement: String;
	idCommande?: Number;
	idProduit?: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.credit = 0;
		this.dateOperation = "";
		this.debit = 0;
		this.libelle = "";
		this.numeroCheque = "";
		this.typeReglement = "";
		this.idBanque = 0;
		this.idPersonne = 0;
		this.idProduitCommande = 0;
		this.idSaison = 0;
		this.idStructure = 0;
		this.idTypeOperation = 0;
		this.idActeur = 0;
		this.idCommandePrelevement = 0;
		this.isCanceled = "";
		this.numeroAttFiscale = "";
		this.boutiquePaiement = "";
	}

	public fromObj(entry: Ifin_operation) {
		this.id = entry.id;
		this.credit = entry.credit;
		this.dateOperation = entry.dateOperation;
		this.debit = entry.debit;
		this.libelle = entry.libelle;
		this.numeroCheque = entry.numeroCheque;
		this.typeReglement = entry.typeReglement;
		this.idBanque = entry.idBanque;
		this.idPersonne = entry.idPersonne;
		this.idProduitCommande = entry.idProduitCommande;
		this.idSaison = entry.idSaison;
		this.idStructure = entry.idStructure;
		this.idTypeOperation = entry.idTypeOperation;
		this.idActeur = entry.idActeur;
		this.idCommandePrelevement = entry.idCommandePrelevement;
		this.isCanceled = entry.isCanceled;
		this.numeroAttFiscale = entry.numeroAttFiscale;
		this.boutiquePaiement = entry.boutiquePaiement;
		return this;
	}

}
