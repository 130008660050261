import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddEntryDialogComponent } from './add-entry-dialog.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { DatePickerModule } from '../date-picker/date-picker.module';
import { SelectSectionLocaleModule } from '../select-section-locale/select-section-locale.module';

@NgModule({
  declarations: [AddEntryDialogComponent],
  imports: [
    CommonModule,IonicModule,FormsModule,
    DatePickerModule,SelectSectionLocaleModule
  ],
  exports:[AddEntryDialogComponent]
})
export class AddEntryDialogModule { }
