export interface Iged_fichier {
	id: Number;
	isVisibiliteFederation: Number;
	isVisibiliteLigue: Number;
	isVisibiliteZoneInterdept: Number;
	isVisibiliteDepartement: Number;
	isVisibiliteClub: Number;
	isVisibiliteEspaceLicencie: Number;
	isVisibiliteHn: Number;
	nom: String;
	nomFiler: String;
	taille: Number;
	dateModification: String;
	idStructureProprietaire: Number;
	idParent: Number;
	idNature: Number;
	etat: String;
}

export class ged_fichier implements Iged_fichier {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	isVisibiliteFederation: Number;
	isVisibiliteLigue: Number;
	isVisibiliteZoneInterdept: Number;
	isVisibiliteDepartement: Number;
	isVisibiliteClub: Number;
	isVisibiliteEspaceLicencie: Number;
	isVisibiliteHn: Number;
	nom: String;
	nomFiler: String;
	taille: Number;
	dateModification: String;
	idStructureProprietaire: Number;
	idParent: Number;
	idNature: Number;
	etat: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.isVisibiliteFederation = 0;
		this.isVisibiliteLigue = 0;
		this.isVisibiliteZoneInterdept = 0;
		this.isVisibiliteDepartement = 0;
		this.isVisibiliteClub = 0;
		this.isVisibiliteEspaceLicencie = 0;
		this.isVisibiliteHn = 0;
		this.nom = "";
		this.nomFiler = "";
		this.taille = 0;
		this.dateModification = "";
		this.idStructureProprietaire = 0;
		this.idParent = 0;
		this.idNature = 0;
		this.etat = "";
	}

	public fromObj(entry: Iged_fichier) {
		this.id = entry.id;
		this.isVisibiliteFederation = entry.isVisibiliteFederation;
		this.isVisibiliteLigue = entry.isVisibiliteLigue;
		this.isVisibiliteZoneInterdept = entry.isVisibiliteZoneInterdept;
		this.isVisibiliteDepartement = entry.isVisibiliteDepartement;
		this.isVisibiliteClub = entry.isVisibiliteClub;
		this.isVisibiliteEspaceLicencie = entry.isVisibiliteEspaceLicencie;
		this.isVisibiliteHn = entry.isVisibiliteHn;
		this.nom = entry.nom;
		this.nomFiler = entry.nomFiler;
		this.taille = entry.taille;
		this.dateModification = entry.dateModification;
		this.idStructureProprietaire = entry.idStructureProprietaire;
		this.idParent = entry.idParent;
		this.idNature = entry.idNature;
		this.etat = entry.etat;
		return this;
	}

}
