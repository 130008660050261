import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fullscreen-page',
  templateUrl: './fullscreen-page.component.html',
  styleUrls: ['./fullscreen-page.component.scss'],
})
export class FullscreenPageComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
