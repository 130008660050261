export interface Idrt_role {
	id: Number;
	code: String;
	etat: String;
	libelle: String;
	idsRolesOpposes: String;
}

export class drt_role implements Idrt_role {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	etat: String;
	libelle: String;
	idsRolesOpposes: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.etat = "";
		this.libelle = "";
		this.idsRolesOpposes = "";
	}

	public fromObj(entry: Idrt_role) {
		this.id = entry.id;
		this.code = entry.code;
		this.etat = entry.etat;
		this.libelle = entry.libelle;
		this.idsRolesOpposes = entry.idsRolesOpposes;
		return this;
	}

}
