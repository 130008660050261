import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { SelectSectionLocaleComponent } from './select-section-locale.component';
import { SectionLocaleModule } from '../section-locale/section-locale.module';

@NgModule({
  declarations: [SelectSectionLocaleComponent],
  imports: [
    CommonModule,IonicModule,FormsModule,SectionLocaleModule
  ],
  exports:[SelectSectionLocaleComponent]
})
export class SelectSectionLocaleModule { }
