import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { etq_etiquettes } from '../types/type_etq_etiquettes';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class EtqEtiquettesService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("etq_etiquettes", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new etq_etiquettes()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished etq_etiquettes", this.entries)
	}


	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "code", nullable: false },
		{ field: "libelle", nullable: true },
		{ field: "reference", nullable: false },
		]
	}
/*
	async Add(
		code,
		libelle,
		reference,
	) {

		const res = new etq_etiquettes()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.code = code
		res.libelle = libelle
		res.reference = reference

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}*/
}