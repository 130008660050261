export interface Ipdt_produit {
	id: Number;
	code: String;
	etat: String;
	libelle: String;
	idFamille: Number;
	ordre: Number;
	typeTransformation: String;
}

export class pdt_produit implements Ipdt_produit {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	etat: String;
	libelle: String;
	idFamille: Number;
	ordre: Number;
	typeTransformation: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.etat = "";
		this.libelle = "";
		this.idFamille = 0;
		this.ordre = 0;
		this.typeTransformation = "";
	}

	public fromObj(entry: Ipdt_produit) {
		this.id = entry.id;
		this.code = entry.code;
		this.etat = entry.etat;
		this.libelle = entry.libelle;
		this.idFamille = entry.idFamille;
		this.ordre = entry.ordre;
		this.typeTransformation = entry.typeTransformation;
		return this;
	}

}
