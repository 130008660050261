import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './Services/auth.guard';
import { CustomPreloadingStrategyService } from './Services/custom-preloading-strategy.service';
import { FullscreenPageComponent } from './fullscreen-page/fullscreen-page.component';
import { GlobalResolver } from './Services/global-resolver.service';
import { AdherentResolverService } from './Services/adherent-resolver.service';
import { GedResolverResolver } from './Services/ged-resolver.resolver';
import { FinPrelevementVirementResolver } from './Services/fin-prelevement-virement.resolver';
import { PdtCommandeResolverService } from './Services/pdt-commande-resolver.service';
import { AdherentsResolverService } from './Services/adherents-resolver.service';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' }, //pour dev
  //{ path: '', redirectTo: '/adherent/687', pathMatch: 'full' },
  {
    path: 'reset-password',
    loadChildren: () => import('./pages/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'reset-password-confirm',
    loadChildren: () => import('./pages/reset-password-confirm/reset-password-confirm.module').then( m => m.ResetPasswordConfirmPageModule)
  },
  { path: 'login', 
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule) 
  },

  /*{
    path: 'facture/:id', //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/facture/facture.module').then( m => m.FacturePageModule)
  },
  {
    path: 'factures', //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/factures/factures.module').then( m => m.FacturesPageModule)
  },
  {
    path: 'clients', //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/clients/clients.module').then( m => m.ClientsPageModule)
  },
  {
    path: 'client/:id', //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/client/client.module').then( m => m.ClientPageModule)
  },
  {
    path: 'produits', //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/produits/produits.module').then( m => m.ProduitsPageModule)
  },
  {
    path: 'produit/:id', //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/produit/produit.module').then( m => m.ProduitPageModule)
  },*/
  /*{
    path: 'time-tasks', data: {preload: true}, //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/time-tasks/time-tasks.module').then( m => m.TimeTasksPageModule)
  },
  {
    path: 'time-task/:id', //canMatch: [AuthGuard],
    loadChildren: () => import('./pages/time-task/time-task.module').then( m => m.TimeTaskPageModule)
  },*/
  {
    path: 'adherent/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/adherent/adherent.module').then( m => m.AdherentPageModule),
    resolve: { pageData: AdherentResolverService }
  },
  {
    path: 'parametres', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/parametres/parametres.module').then( m => m.ParametresPageModule)
  },
  {
    path: 'nodes', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/nodes/nodes.module').then( m => m.NodesPageModule)
  },
  {
    path: 'adherents', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/adherents/adherents.module').then( m => m.AdherentsPageModule),
    resolve: { pageData: AdherentsResolverService }
  },
  {
    path: 'nouveau-adherent', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/nouveau-adherent/nouveau-adherent.module').then( m => m.NouveauAdherentPageModule)
  },
  {
    path: 'nouvelle-adhesion/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/nouvelle-adhesion/nouvelle-adhesion.module').then( m => m.NouvelleAdhesionPageModule),
    resolve: { pageData: AdherentResolverService }
  },
  //{ path: 'fullscreen', component: FullscreenPageComponent },
  {
    path: 'questions', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/questions/questions.module').then( m => m.QuestionsPageModule)
  },
  {
    path: 'liste-paiements', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/liste-paiements/liste-paiements.module').then( m => m.ListePaiementsPageModule),
    resolve: { pageData: FinPrelevementVirementResolver }
  },
  {
    path: 'reglements', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/reglements/reglements.module').then( m => m.ReglementsPageModule)
  },
  {
    path: 'import-reglements', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/import-reglements/import-reglements.module').then( m => m.ImportReglementsPageModule)
  },
  {
    path: 'import-reglements', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/import-reglements/import-reglements.module').then( m => m.ImportReglementsPageModule)
  },
  {
    path: 'liste-documents', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/liste-documents/liste-documents.module').then( m => m.ListeDocumentsPageModule),    
    resolve: { pageData: GedResolverResolver }
  },
  {
    path: 'mes-coordonnees/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/mes-coordonnees/mes-coordonnees.module').then( m => m.MesCoordonneesPageModule),
    resolve: { pageData: AdherentResolverService }
  },
  {
    path: 'mes-documents/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/mes-documents/mes-documents.module').then( m => m.MesDocumentsPageModule),
    resolve: { pageData: AdherentResolverService }
  },
  {
    path: 'bulletin-info/:id', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/bulletin-info/bulletin-info.module').then( m => m.BulletinInfoPageModule),
    resolve: { pageData: AdherentResolverService }
  },
  {
    path: 'nouvelle-adhesion', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/nouvelle-adhesion/nouvelle-adhesion.module').then( m => m.NouvelleAdhesionPageModule)
  },
  {
    path: 'repartition-adhesions', canMatch: [AuthGuard],
    loadChildren: () => import('./reports/repartition-adhesions/repartition-adhesions.module').then( m => m.RepartitionAdhesionsPageModule)
  },
  {
    path: 'role-modal', canMatch: [AuthGuard],
    loadChildren: () => import('./pages/role-modal/role-modal.module').then( m => m.RoleModalPageModule)
  },
  {
    path: 'tableau-bord',
    loadChildren: () => import('./pages/tableau-bord/tableau-bord.module').then( m => m.TableauBordPageModule),
    resolve: { pageData: PdtCommandeResolverService }
  },

  //{ path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: CustomPreloadingStrategyService })
  ],  
  providers: [CustomPreloadingStrategyService],
  exports: [RouterModule]
})
export class AppRoutingModule {}
