import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { lic_licencecommandee } from '../types/type_lic_licencecommandee';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LicLicencecommandeeService extends BaseService {

	override authneeded = false;
	
	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService,injector);
		super.init("lic_licencecommandee", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	loadFromPersonne(idPersonne) {
		console.log("start loadFromPersonne lic_licencecommandee")
		this.loadid = idPersonne
		super.init("lic_licencecommandee", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
		console.log("end loadFromPersonne lic_licencecommandee")
	}

	getId(entry) {
		return String(entry.id)
	}

	override addEntry(): any {
		return super.addEntry(new lic_licencecommandee())
	}

	//funtion passed in constructor
	loadEntry(dbentry) {
		const res = (new lic_licencecommandee()).fromObj(dbentry)
		return res

	}

	override getDbFields() {
		return [
		{field:"id",nullable:false},	
		{field:"dateDebutValidite",nullable:false},
		{field:"dateFinValidite",nullable:false},
		{field:"dateSouscription",nullable:false},
		{field:"dateValidation",nullable:false},
		{field:"edite",nullable:true},
		{field:"modeReglement",nullable:true},
		{field:"idCategorie",nullable:false},
		{field:"dateEdition",nullable:true},
		{field:"retraite",nullable:false},
		{field:"tempsPartiel",nullable:true},
		{field:"bap",nullable:true},
		{field:"idStatut",nullable:true},
		{field:"idCorps",nullable:true},
		{field:"idGrade",nullable:true},
		{field:"idEmploi",nullable:true},
		{field:"idDelegation",nullable:true},
		{field:"idUnite",nullable:true},
		{field:"idEtablissement",nullable:true},
		{field:"echelon",nullable:true},
		{field:"typeContrat",nullable:true}
		]
	}

	async Add(
		id,
		dateDebutValidite,
		dateFinValidite,
		dateSouscription,
		dateValidation,
		edite,
		modeReglement,
		idCategorie,
		dateEdition,
		retraite,
		tempsPartiel)/*,
		bap,
		idStatut,
		idCorps,
		idGrade,
		idEmploi,
		idDelegation,
		idUnite,
		idEtablissement,
		echelon,
		typeContrat
	)*/ {
		const res = new lic_licencecommandee()

		const now = moment().format("YYYY-MM-DD")
		
		let xretraite = 0
		if ( retraite == "Oui" )
			xretraite = 1

		res.db = 1
		res.id = id
		res.dateDebutValidite = dateDebutValidite
		res.dateFinValidite = dateFinValidite
		res.dateSouscription = dateSouscription
		res.dateValidation = dateValidation
		res.edite = edite
		res.modeReglement = modeReglement
		res.idCategorie = idCategorie
		res.dateEdition = dateEdition
		res.retraite = xretraite
		res.tempsPartiel = tempsPartiel
		/*res.bap = bap
		res.idStatut = idStatut
		res.idCorps = idCorps
		res.idGrade = idGrade
		res.idEmploi = idEmploi
		res.idDelegation = idDelegation
		res.idUnite = idUnite
		res.idEtablissement = idEtablissement
		res.echelon = echelon
		res.typeContrat = typeContrat*/
		
		console.log("LicLicencecommandeeService beforesavea")
		this.entries.push(res)
		await this.save( res, true );
		//res.id = Number(id);
		console.log("LicLicencecommandeeService aftersaveb")
		return res;
	}

	/*findCustomer(id): Customer {
		this.currentCustomer = null;
		this.currentCustomer = this.customers.find(cust => cust.id == id)
		return this.currentCustomer
	}*/
}
