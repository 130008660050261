<div class="upload-dialog">
	<i style="float:right;font-size:x-large;" slot="end" (click)="close()" class="fa-regular fa-close"></i>
	<h2>Téléverser des fichiers</h2>
	<div class="drop-zone" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
		<p>Déposez votre fichier ici ou</p>
		<!--<input type="file" multiple (change)="onFileChange($event)" />-->

		<input type="file" multiple id="selectedFile" (change)="onFileChange($event)" style="display: none;" />
		<input type="button" value="Choisir les fichiers" onclick="document.getElementById('selectedFile').click();" />

		<div class="filegridwrapper">
			<div class="filegrid" *ngFor="let file of files; let i = index">
				<div class="icon"><i class="{{getFileIcon(file)}}" slot="start"></i></div>
				<div class="filename">{{ file.name }}</div>
				<div class="icon"><i (click)="removeFile(i)" class="fa-solid fa-close"></i></div>
			</div>
		</div>
	</div>

	<h3>Visiblité</h3>
	<div class="droitswrapper">
		<div *ngFor="let droit of droits">
		  <ion-label>{{ droit.key }}</ion-label>
		  <ion-checkbox [(ngModel)]="droit.checked" (ionChange)="handleCheckboxChange($event, droit)"></ion-checkbox>
		</div>
	</div>

	<div class="uploadfile">
		<ion-button (click)="uploadFiles()">Envoyer les fichiers</ion-button>
		<ion-spinner *ngIf="isLoading" name="dots" slot="end"></ion-spinner>
	</div>
	<div style="color:green" *ngIf="success!==''">
		{{success}}
	</div>
	<div style="color:red" *ngIf="error!==''">
		{{error}}
	</div>
</div>