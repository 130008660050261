<ion-header *ngIf="hideTitle == 0">
	<ion-toolbar *ngIf="!hideToolbar">
		<ion-title *ngIf="structureActuelle==''">CHOIX DE LA SECTION LOCALE</ion-title>
		<ion-title *ngIf="structureActuelle!=''">Changement de structure</ion-title>
		<ion-buttons slot="end">
			<ion-button *ngIf="closeEnabled" (click)="setOpen(false)">
				<ion-icon name="close"></ion-icon>
			</ion-button>			
		</ion-buttons>
	</ion-toolbar>

	<ion-grid *ngIf="structureActuelle!=''" style="margin-left:10px;">
		<ion-row>

			<ion-col size="8">

				<ion-grid>
					<ion-row>
						<ion-col>
							<div style="font-weight: bold;">Structure actuelle</div>
							<div>{{structureActuelle}}</div>
						</ion-col>
					</ion-row>
					<ion-row>
						<ion-col>
							<div style="text-align: center">
								<i class="fa-solid fa-arrows-up-down"></i>
							</div>
						</ion-col>
					</ion-row>

					<ion-row>
						<ion-col>
							<div style="font-weight: bold;">Structure sélectionnée</div>
							<div *ngIf="structureSelected!=''">{{structureSelected}}</div>
							<div style="color:#aaa" *ngIf="structureSelected==''">Veuillez sélectionner la nouvelle
								structure ci-dessous</div>
						</ion-col>
					</ion-row>
				</ion-grid>

			</ion-col>

			<ion-col>
				<div style="display:flex;justify-content: center; height:100%; align-items: center;">
					<ion-button color="success" (click)="changeStructureOk()">
						Changer
					</ion-button>
				</div>
			</ion-col>

		</ion-row>
	</ion-grid>

</ion-header>

<ion-content *ngIf="mode==1">
	<div>
		<ion-searchbar [debounce]="250" (ionInput)="handleSearch($event)" placeholder="Recherche"></ion-searchbar>

		<ion-list>
			<ng-template #recursiveList let-list>
				<div *ngFor="let item of list; let j = index">
					<ion-item *ngIf="item.visible" ng-init="depth = depth + 1">
						<ion-icon class="folder shadow" *ngIf="item.children.length>0" name="folder"
							slot="start"></ion-icon>
						<ion-icon *ngIf="item.children.length==0" name="document-text-outline" slot="start"
							(click)="select(item)"></ion-icon>
						<ion-label *ngIf="item.children.length>0" (click)="item.expanded=!item.expanded">{{item.code}} -
							{{item.nom}}</ion-label>
						<ion-label *ngIf="item.children.length==0" (click)="select(item)">{{item.code}} -
							{{item.nom}}</ion-label>
						<!--<ion-icon *ngIf="item.children.length>0" (click)="addSubentry(item)" name="add"
            slot="end"></ion-icon>-->
					</ion-item>
					<div class="subnode" *ngIf="item.children.length > 0 && item.expanded == true">
						<ng-container
							*ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
					</div>
				</div>
			</ng-template>
			<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: this.entries }"></ng-container>
		</ion-list>
	</div>
</ion-content>

<div *ngIf="mode==2">
	<ion-searchbar [debounce]="250" (ionInput)="handleSearch($event)" placeholder="Recherche"></ion-searchbar>

	<ion-list>
		<ng-template #recursiveList let-list>
			<div *ngFor="let item of list; let j = index">
				<ion-item *ngIf="item.visible" ng-init="depth = depth + 1">
					<ion-icon class="folder shadow" *ngIf="item.children.length>0" name="folder"
						slot="start"></ion-icon>
					<ion-icon *ngIf="item.children.length==0" name="document-text-outline" slot="start"
						(click)="select(item)"></ion-icon>
					<ion-label *ngIf="item.children.length>0" (click)="item.expanded=!item.expanded">{{item.code}} -
						{{item.nom}}</ion-label>
					<ion-label *ngIf="item.children.length==0" (click)="select(item)">{{item.code}} -
						{{item.nom}}</ion-label>
					<!--<ion-icon *ngIf="item.children.length>0" (click)="addSubentry(item)" name="add"
		slot="end"></ion-icon>-->
				</ion-item>
				<div class="subnode" *ngIf="item.children.length > 0 && item.expanded == true">
					<ng-container
						*ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
				</div>
			</div>
		</ng-template>
		<ng-container *ngTemplateOutlet="recursiveList; context:{ $implicit: this.entries }"></ng-container>
	</ion-list>
</div>