import { Injectable } from '@angular/core';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BaseService } from './base.service';
import { TimeTask } from '../types/time-task';
import { Customer } from '../types/customer';
import { TimeTaskLineService } from './time-task-line.service';
import * as moment from 'moment';
import { TimeTaskLine } from '../types/time-task-line';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TimeTaskService extends BaseService {

	loaded: string[]
	

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override injector: Injector,
		public timeTaskLine: TimeTaskLineService,
		public override apiService: ServerDBApiService,
	) {
		super(global, appPagesService, router, http, apiService,injector);
		super.init("timetasks", this.loadTimeTasks.bind(this))
		//this.global.mainPageTitle.next({url:"/time-tasks",title:"TimeLine"})
		this.global.iconClick.subscribe(page=>{
			this.iconClick(page)
		})
	}

	ngOnInit() {
		console.log("ngOnInit TimeTaskService")



		//this.getLastTimeTaskFromUser()
	//	this.appPagesService.appPages.find(p=>p.url=="/time-tasks").iconClick = this.iconClick.bind(this)
	}

	override addEntry(): any {
		return super.addEntry(new TimeTask())
	}

	override getSingleUrl() {
		return '/time-task/' + this.currentEntry.id
	}

	override getListUrl() {
		return '/time-tasks'
	}

	override getSingleIcon() {
		return 'timer'
	}

	icon: string = "timer"
	override getListIcon() {
		return this.icon
	}

	override iconClick(page) {
		if (page.url!="/time-tasks")
			return;
		console.log("TimeTaskService",page)
	}

	override getTitle(entry:any=null) {
		if (!entry)
			entry = this.currentEntry
		let tmp = entry.customer.company.trim()
		if ( tmp == "" ) {
			tmp = entry.customer.lastname + " " + entry.customer.firstname
			tmp = tmp.trim()
			if (tmp == "")
				return "(Nouveau TimeLine)"
		}
		return tmp
	}

	getLastLog(timeTask,iduser=null):TimeTaskLine {
		let res = this.timeTaskLine.entries.filter(x=>x.idtimetask==timeTask.id && ( !iduser ? true : x.iduser == iduser ) )
		if ( res.length == 0 )
			return undefined
		let b:TimeTaskLine = res.reduce((prev,current)=>prev.getLastLogDate()>current.getLastLogDate())
		return b
	}
	getLibelleLastLog(timeTask) {
		const res = this.getLastLog(timeTask)
		//console.log("getLibelleLastLog",res)
		if (!res)
			return ""
		return res.libelle;
	}
	getDateLastLog(timeTask) {
		const res = this.getLastLog(timeTask)
		if (!res)
			return ""
		let res2 = res.getFirstLogDate()
		if ( res2 == "" )
			return "";
		return moment(res2).format("DD/MM/YYYY HH:mm:ss")
	}
	getMinutesLastLog(timeTask) {
		const res = this.getLastLog(timeTask)
		if (!res)
			return ""
		return this.timeTaskLine.formatHumandSeconds( res )
	}

	autocompleteOnSelected(timetaskentry:TimeTask,customerentry:Customer) {
		console.log("autocompleteOnSelected",timetaskentry,customerentry)
		if ( !customerentry ) {
		console.log("autocompleteOnSelected",timetaskentry,customerentry)
			console.error("autocompleteOnSelected customerentry undefined !")
			return
		}
		timetaskentry.customer = customerentry
		this.setEntryUpdate(timetaskentry)
	}

	loadTimeTasks(dbentry) {
		console.log("💾 loadTimeTasks", dbentry)
		const res = (new TimeTask()).fromObj(dbentry)
		console.log("💾 loadTimeTasks", res)
		return res

	}
}