export interface Iadr_adressepostale {
	id: Number;
	apptEtageEsc: String;
	codePostal: String;
	codeInsee: String;
	fax1: String;
	immBatRes: String;
	lieuDit: String;
	mobile1: String;
	mobile2: String;
	nomVoie: String;
	numero: String;
	pays: String;
	telephone1: String;
	telephone2: String;
	typeVoie: String;
	ville: String;
	email: String;
	email2: String;
	longitude: String;
	latitude: String;

	rue: String; //new
}

export class adr_adressepostale implements Iadr_adressepostale {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	apptEtageEsc: String;
	codePostal: String;
	codeInsee: String;
	fax1: String;
	immBatRes: String;
	lieuDit: String;
	mobile1: String;
	mobile2: String;
	nomVoie: String;
	numero: String;
	pays: String;
	telephone1: String;
	telephone2: String;
	typeVoie: String;
	ville: String;
	email: String;
	email2: String;
	longitude: String;
	latitude: String;

	rue: String; //new

	constructor() {
		this.db = 0;
		this.id = 0;
		this.apptEtageEsc = "";
		this.codePostal = "";
		this.codeInsee = "";
		this.fax1 = "";
		this.immBatRes = "";
		this.lieuDit = "";
		this.mobile1 = "";
		this.mobile2 = "";
		this.nomVoie = "";
		this.numero = "";
		this.pays = "";
		this.telephone1 = "";
		this.telephone2 = "";
		this.typeVoie = "";
		this.ville = "";
		this.email = "";
		this.email2 = "";
		this.longitude = "";
		this.latitude = "";

		this.rue ="";
	}

	getRue() {
		if ( this.rue == "" || !this.rue ) {
			this.rue =	this.numero?.trim()+ " "
			this.rue +=	this.typeVoie?.trim()+ " "
			this.rue +=	this.nomVoie?.trim()+ " "
			this.rue = this.rue.trim()	
		}
	}

	public fromObj(entry: Iadr_adressepostale) {
		this.db = 0;
		this.id = entry.id;
		this.apptEtageEsc = entry.apptEtageEsc;
		this.codePostal = entry.codePostal;
		this.codeInsee = entry.codeInsee;
		this.fax1 = entry.fax1;
		this.immBatRes = entry.immBatRes;
		this.lieuDit = entry.lieuDit;
		this.mobile1 = entry.mobile1;
		this.mobile2 = entry.mobile2;
		this.nomVoie = entry.nomVoie;
		this.numero = entry.numero;
		this.pays = entry.pays;
		this.telephone1 = entry.telephone1;
		this.telephone2 = entry.telephone2;
		this.typeVoie = entry.typeVoie;
		this.ville = entry.ville;
		this.email = entry.email;
		this.email2 = entry.email2;
		this.longitude = entry.longitude;
		this.latitude = entry.latitude;
		this.rue = entry.rue;

		this.getRue()

		return this;
	}

}
