import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';
import { ged_repertoire } from '../types/type_ged_repertoire';
import { GedFichierService } from './ged-fichier.service';

@Injectable({
  providedIn: 'root'
})
export class GedRepertoireService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector,
		public gedFichierService: GedFichierService
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("ged_repertoire", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		//console.log('ged_repertoire loadEntry',dbentry)
		const res = (new ged_repertoire()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	/*override onLoadingFinished() {
		this.entries.forEach(instance=>{
		})

		console.log("finished ged_repertoire", this.entries)
	}*/

	refresh() {
		this.formatEntries(this.entries)
	}

	formatEntries(entries) {		
		entries.forEach(entry => {
			entry.children = this.oldEntries.filter( x => x.idParent == entry.id)
			entry.files = this.gedFichierService.entries.filter( x => x.idParent == entry.id)
			this.formatEntries(entry.children)
		})
		//console.log("gedrepertoire formatEntries",entries)
	}

	public flatentries: any[] = [];
	oldEntries: any[] = [];
	override onLoadingFinished() {
		console.log("finished ged_repertoire", this.entries, this.gedFichierService.entries)

		this.flatentries = [...this.entries]

		this.entries.forEach(structure => {
			//	console.log(structure.id)
			structure.childrenids = []
		})

		this.entries.forEach(structure => {
			//	console.log(structure.id)
			structure.parentsids = []
		})

		this.oldEntries = this.entries
		this.entries = []
		this.entries = this.oldEntries.filter(rep => !rep.idParent)
		this.formatEntries(this.entries)

		console.log("after children", this.entries)
	}

/*	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "idSpecialiteStructure", nullable: false },
		{ field: "idTypeInstance", nullable: false },
		{ field: "idTypeStructure", nullable: false }
		]
	}

	async Add(
		idSpecialiteStructure,
		idTypeInstance,
		idTypeStructure,
	) {

		const res = new ins_instance()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.idSpecialiteStructure = idSpecialiteStructure
		res.idTypeInstance = idTypeInstance
		res.idTypeStructure = idTypeStructure

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}*/
}
