export interface Itrc_typetrace {
	id: Number;
	code: String;
	libelle: String;
	idGroupeTrace: Number;
	withMajInfosAControler: String;
}

export class trc_typetrace implements Itrc_typetrace {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	libelle: String;
	idGroupeTrace: Number;
	withMajInfosAControler: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.libelle = "";
		this.idGroupeTrace = 0;
		this.withMajInfosAControler = "";
	}

	public fromObj(entry: Itrc_typetrace) {
		this.id = entry.id;
		this.code = entry.code;
		this.libelle = entry.libelle;
		this.idGroupeTrace = entry.idGroupeTrace;
		this.withMajInfosAControler = entry.withMajInfosAControler;
		return this;
	}

}
