import { Injectable } from '@angular/core';
import { ItemReorderEventDetail } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Observable, of } from 'rxjs';
import { InfoSociete } from '../types/info-societe';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import * as moment from 'moment';
import { CacheService } from './cache.service';
import { filter, first, take } from 'rxjs/operators';
import { DatePipe } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class GlobalService {

	public debug = false;
	public debug_grid = false;
	public appType="?"

	public infoSociete_raisonsociale: string = ""
	public infoSociete: InfoSociete;
	public loaded = new BehaviorSubject("");
	public lloaded = true;

	public authState = new BehaviorSubject(false);
	public iconClick = new BehaviorSubject(false);
	public mainPageTitle = new BehaviorSubject({ url: "", title: "" });
	public splitPane = new BehaviorSubject(false);
	public tableprefix: String;
	public userid: String;
	public user: any; //row from the database
	public users: any[]
	public droits: any[]
	public userskeyvalue: any[]
	public pagetitles: any[] = []
	public token = ""
	public tokenLoaded = new BehaviorSubject(false);
	public subtokenLoaded
	public allEntries: any[] = []
	public allMaps: any[] = []

	getSNPTESAdresse() {
		return "SNPTES\n18 rue Chevreul\n94600 CHOISY LE ROI"
	}

	public dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);	
	async waitDataLoadedForTrue() {
		await this.dataLoaded.asObservable()
			.pipe(
				filter(value => value === true),
				take(1)
			)
			.toPromise();
	}

	formatFloatWithComma(value: number): string {
		return new Intl.NumberFormat('fr-FR', {
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(value);
	}

	public currentUser = null;
	getIdPersonneLoggedIn() {
		return 1061;
		return 687;
	}

	isEspaceAdmin() {
		if ( this.appType == "Gestion" )
			return true;
		return false;
	}

	/*getMainTitle(url) {
		return this.pagetitles.find(p=>p.url==url)?.title
	}*/


	//arr all elements
	//target array to test
	areAllElementsInArray<T>(A: T[], B: T[]): boolean {
		// Convert array B to a Set for efficient lookup
		const setB = new Set(B);
	  
		// Check if every element in A is present in setB
		return A.every(element => setB.has(element));
	}

	  
	containsAllElements(arr: string[], target: string[]): boolean {
		return target.every(elem => arr.includes(elem));
	 }

	constructor(public http: HttpClient,private datePipe: DatePipe,
		public cacheService: CacheService,
	) {
		/*this.mainPageTitle.subscribe(pagetitle => {
			this.pagetitles.push(pagetitle)
			console.log("mainPageTitle",pagetitle,this.pagetitles)
		})*/
	}

	handleReorder(ev: CustomEvent<ItemReorderEventDetail>) {
		console.log('Dragged from index', ev.detail.from, 'to', ev.detail.to);
		ev.detail.complete();
	}

	sanitizeFileName(fileName: string): string {
		// Remplace les caractères non-alphanumériques par des underscores
		const sanitizedFileName = fileName.replace(/[^a-zA-Z0-9]/g, '_');

		// Convertit le nom de fichier en majuscule
		const upperCaseFileName = sanitizedFileName.toUpperCase();

		return upperCaseFileName;
	}

	delay(ms: number): Promise<void> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	convertWithLeadingZeros(num: number, totalLength: number): string {
		// Convert the number to a string
		let numStr = num.toString();

		// Calculate the number of leading zeros needed
		const leadingZeros = totalLength - numStr.length;

		// If leading zeros are needed, prepend them to the number string
		if (leadingZeros > 0) {
			numStr = '0'.repeat(leadingZeros) + numStr;
		}

		return numStr;
	}

	validateEmail(email: string): boolean {
		// Regular expression pattern for validating an email address
		const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

		// Test the input email against the regex pattern
		return emailPattern.test(email);
	}

	validatePhoneNumber(phoneNumber: string): boolean {
		// Regex pattern for international phone numbers (E.164 format)
		const internationalPhoneNumberPattern = /^\+?[1-9]\d{1,14}$/;

		// Regex pattern for French phone numbers (local and international formats)
		const frenchPhoneNumberPattern = /^(0[1-9](?:[ .-]?[0-9]{2}){4}|(\+33|0033)[1-9](?:[ .-]?[0-9]{2}){4})$/;

		// Check if the phone number matches either the international pattern or the French pattern
		return internationalPhoneNumberPattern.test(phoneNumber) || frenchPhoneNumberPattern.test(phoneNumber);
	}

	validateIBAN(iban: String): boolean {
		// Remove spaces and to upper case
		iban = iban.replace(/[^A-Z0-9]/gi, '').toUpperCase();

		// Check the basic format and length
		const regex = /^[A-Z0-9]+$/;
		if (!regex.test(iban.toString())) {
			return false;
		}

		const countryLengths: { [key: string]: number } = {
			'AL': 28, 'AD': 24, 'AT': 20, 'AZ': 28, 'BH': 22, 'BE': 16, 'BA': 20, 'BR': 29, 'BG': 22,
			'CR': 22, 'HR': 21, 'CY': 28, 'CZ': 24, 'DK': 18, 'DO': 28, 'EE': 20, 'FO': 18, 'FI': 18,
			'FR': 27, 'GE': 22, 'DE': 22, 'GI': 23, 'GR': 27, 'GL': 18, 'GT': 28, 'HU': 28, 'IS': 26,
			'IE': 22, 'IL': 23, 'IT': 27, 'JO': 30, 'KZ': 20, 'XK': 20, 'KW': 30, 'LV': 21, 'LB': 28,
			'LI': 21, 'LT': 20, 'LU': 20, 'MK': 19, 'MT': 31, 'MR': 27, 'MU': 30, 'MC': 27, 'MD': 24,
			'ME': 22, 'NL': 18, 'NO': 15, 'PK': 24, 'PS': 29, 'PL': 28, 'PT': 25, 'QA': 29, 'RO': 24,
			'SM': 27, 'SA': 24, 'RS': 22, 'SK': 24, 'SI': 19, 'ES': 24, 'SE': 24, 'CH': 21, 'TN': 24,
			'TR': 26, 'AE': 23, 'GB': 22, 'VG': 24
		};

		const countryCode = iban.slice(0, 2);
		const length = countryLengths[countryCode];

		if (!length || iban.length !== length) {
			return false;
		}

		// Rearrange and convert
		const rearranged = iban.slice(4) + iban.slice(0, 4);
		const converted = rearranged.split('').map(char => {
			const code = char.charCodeAt(0);
			if (code >= 65 && code <= 90) { // A-Z
				return (code - 55).toString(); // A=10, B=11, ..., Z=35
			} else {
				return char;
			}
		}).join('');

		// Perform checksum (mod 97)
		const checksum = converted.match(/.{1,7}/g)?.reduce((acc, part) => (Number(acc + part) % 97).toString(), '0');
		return checksum === '1';
	}

	validateBIC(bic: String): boolean {
		// Regular expression to match the BIC format
		const bicRegex = /^[A-Z]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;

		// Check if the BIC matches the regular expression
		if (!bicRegex.test(bic.toString())) {
			return false;
		}

		// If it matches, it's a valid BIC
		return true;
	}

	isLocal() {
		//return false;
		if (location.hostname === "localhost" || location.hostname === "127.0.0.1")
			return true;
		return false;
	}

	getAge(dateString: string) {
		const date = moment(dateString, 'YYYY-MM-DD')
		const years = moment().diff(date, 'years')
		const days = moment().diff(date.add(years, 'years'), 'days', false)
		return { years, days }
	}


	formatCurrency(number: number) {

		const res = String(number / 100) + "€"
		//console.log("formatCurrency",number,res)
		return res
	}

	formatStringWithLeadingZeros(input: string, desiredLength: number): string {
		// Ensure input is a string
		let str = input.toString();
	
		// Calculate how many zeros are needed
		let zerosToAdd = Math.max(0, desiredLength - str.length);
	
		// Pad the string with zeros
		for (let i = 0; i < zerosToAdd; i++) {
			str = '0' + str;
		}
	
		return str;
	}

	formatFileSize(bytes: number, decimalPoints: number = 2) {
		if (bytes === 0) {
			return '0 Bytes';
		  }
		  
		  const k = 1024;
		  const sizes = ['Octets', 'Ko', 'Mo', 'Go', 'To', 'Po', 'Eo', 'Zo', 'Yo'];
		  const i = Math.floor(Math.log(bytes) / Math.log(k));
		  
		  return parseFloat((bytes / Math.pow(k, i)).toFixed(decimalPoints)) + ' ' + sizes[i];
		
	}


	  

	formatHumanDate(dateString: string): string {
		const monthTranslationTable: {[key: string]: string} = {
			'January': 'Janvier',
			'February': 'Février',
			'March': 'Mars',
			'April': 'Avril',
			'May': 'Mai',
			'June': 'Juin',
			'July': 'Juillet',
			'August': 'Août',
			'September': 'Septembre',
			'October': 'Octobre',
			'November': 'Novembre',
			'December': 'Décembre'
		  };

		const date = new Date(dateString);
		// Format date part
		const day = this.datePipe.transform(date, 'd');
		const month = monthTranslationTable[ this.datePipe.transform(date, 'MMMM', 'fr-FR') ];
		const year = this.datePipe.transform(date, 'y');
		// Format time part
		const time = this.datePipe.transform(date, 'HH:mm:ss');
		return `${day} ${month} ${year} ${time}`;
	  }

}

