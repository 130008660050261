import { Injectable, Injector } from '@angular/core';
import { Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Storage } from '@ionic/storage';
import { ToastController, Platform, LoadingController } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { Observable, of } from 'rxjs';
import { HttpHeaders, HttpParams, HttpClient } from '@angular/common/http';
import { ServerDBApiService } from './server-dbapi.service';
import { GlobalService } from './global.service';
import { InfoSociete } from '../types/info-societe';
import { CfgSaisonService } from './cfg-saison.service';
import { PdtProduitService } from './pdt-produit.service';
import { PdtCommandeService } from './pdt-commande.service';
import { PdtTarifproduitService } from './pdt-tarifproduit.service';
import { AppPagesService } from './app-pages.service';
import { CacheService } from './cache.service';
import { DataLoaderService } from './data-loader.service';
import { DataLoader2Service } from './data-loader2.service';
import { DataLoader3Service } from './data-loader3.service';

@Injectable()
export class AuthenticationService {


	/*constructor(
		public global: GlobalService,
		private router: Router,
		private storage: Storage,
		private platform: Platform,
		public toastController: ToastController,
		public http: HttpClient,
		public cfgSaisonService: CfgSaisonService,
		public pdtProduitService: PdtProduitService,
		public pdtCommandeService: PdtCommandeService,
		public pdtTarifproduitService: PdtTarifproduitService
	) {

	}*/

	private tokenKey = 'auth-token';

	appType = "Adhérent"
	constructor(private http: HttpClient,
		public cacheService: CacheService,
		public loadingCtrl: LoadingController,
		public apiService: ServerDBApiService,
		public appPagesService: AppPagesService,
		private storage: Storage, private router: Router,
		public globalService: GlobalService,
		public injector: Injector) {
		this.globalService.tokenLoaded.next(false);
		this.storage.create().then(res => {
			this.storage.get("appType").then(x => {
				this.appType = x
				if ( x == "" || !x ) {
					this.appType = "Gestion"
				}
			})
		})
	}

	async login(username: string, password: string): Promise<void> {
		console.log(username, password)
		const response: any = await this.http.post(`${this.apiService.apiUrl}/login`, { username, password }).toPromise();
		console.log("debug6 login response", response)
		this.globalService.currentIdUtilisateur = response.login.id
		this.globalService.currentIdPersonne = response.login.idPersonne
		this.globalService.droits = response.droits
		this.globalService.rightids = response.rightids
		console.log("debug10 droits",this.globalService.droits,this.globalService.rightids)
		this.globalService.token = response.token
		await this.storage.set("appType", this.appType);
		this.globalService.appType = this.appType
		await this.storage.set(this.tokenKey, response.token);
		console.log("debug6 currentIdUtilisateur",this.globalService.currentIdUtilisateur,"currentIdPersonne",this.globalService.currentIdPersonne)
	}

	async getToken(): Promise<string | null> {
		const token = await this.storage.get(this.tokenKey);
		this.globalService.token = token
		this.globalService.tokenLoaded.next(true);
		return token
	}

	async autoLogin(): Promise<void> {
		const startTime = new Date().getTime();    
		let endTime
		let elapsedTime

		console.log("autoLogin", this.router.url)
		if (this.router.url.includes('reset-password') || this.router.url.includes('reset-password-confirm')) {
			console.log("ask reset");
			return;
		}
		this.globalService.appType = await this.storage.get("appType");
		const token = await this.getToken();
		console.log("💎💎💎💎💎💎💎💎💎💎💎💎💎💎 autoLogin", token)
		if (token || token === "") {
			const isValid = await this.apiService.checkToken(token);
			if (isValid) {
				this.globalService.currentIdUtilisateur = this.globalService.droits[0].idUtilisateur
				this.globalService.currentIdPersonne = this.globalService.droits[0].idPersonne;
				console.log("debug5 currentIdUtilisateur",this.globalService.currentIdUtilisateur,"currentIdPersonne",this.globalService.currentIdPersonne)
				//this.globalService.currentIdUtilisateur = this.globalService.getIdUtilisateurLoggedIn() //pour test
				await this.loadData();
				//this.router.navigate(['/adherents']);
				endTime = new Date().getTime();    // Get end time in milliseconds
				elapsedTime = endTime - startTime; // Calculate the time difference
				console.log(`autoLogin Elapsed time 1 : ${elapsedTime} ms`);

				console.log("💎💎💎💎💎💎💎💎💎💎💎💎💎💎 autoLogin loadData")
				if (!this.globalService.isEspaceAdmin()) {					
					console.log("debug5 islocal adherent",this.globalService.currentIdUtilisateur)
					//this.router.navigate(['/adherent', this.globalService.getIdPersonneLoggedIn()]);
					this.globalService.isReady.next(true);
					console.log(`debug6 this.globalService.isReady.next(true)`);
					return;
				}
				//this.router.navigate(['/adherents']);
				endTime = new Date().getTime();    // Get end time in milliseconds
				elapsedTime = endTime - startTime; // Calculate the time difference
				console.log(`autoLogin Elapsed time end: ${elapsedTime} ms`);

				console.log(`debug6 this.globalService.isReady.next(true)`);
				this.globalService.isReady.next(true);

				return;
			} else {
				this.router.navigate(['/login']);
			}
		} else {
			this.router.navigate(['/login']);
		}
	}



	delay(ms: number): Promise<void> {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	async loadData(): Promise<void> {

		const startTime = new Date().getTime(); 

		this.globalService.lloaded = false
		console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ authentication loadData")
		// Implement your data loading logic here



		//return () => new Promise<void>(async (resolve) => {
		const loading = await this.loadingCtrl.create({
			message: 'Chargement des données, veuillez patienter...',
			spinner: 'circles'
		});
		await loading.present();

		console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader2")
		const dataLoader2 = this.injector.get(DataLoader2Service);
		await dataLoader2.waitForTrue()
		console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader2 loaded")

		let endTime = new Date().getTime();
		let elapsedTime = endTime - startTime;
		console.log(`Elapsed time 1: ${elapsedTime} ms`);

		console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader3")
		const dataLoader3 = this.injector.get(DataLoader3Service);
		await dataLoader3.waitForTrue()
		let delayedReorgRights = dataLoader3.reorgRights()
		console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader3 loaded")

		endTime = new Date().getTime();
		elapsedTime = endTime - startTime;
		console.log(`Elapsed time 2: ${elapsedTime} ms`);

		console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader")
		const dataLoader = this.injector.get(DataLoaderService);
		endTime = new Date().getTime();
		elapsedTime = endTime - startTime;
		console.log(`Elapsed time 3.1: ${elapsedTime} ms`);
		dataLoader.dataLoaded.next(false);
		endTime = new Date().getTime();
		elapsedTime = endTime - startTime;
		console.log(`Elapsed time 3.2: ${elapsedTime} ms`);
		dataLoader.done = false;
		endTime = new Date().getTime();
		elapsedTime = endTime - startTime;
		console.log(`Elapsed time 3.3: ${elapsedTime} ms`);
		dataLoader.loadData()
		endTime = new Date().getTime();
		elapsedTime = endTime - startTime;
		console.log(`Elapsed time 3.4: ${elapsedTime} ms`);
		await dataLoader.waitForTrue()

		endTime = new Date().getTime();
		elapsedTime = endTime - startTime;
		console.log(`Elapsed time 3.5: ${elapsedTime} ms`);


		console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader loaded")
		await delayedReorgRights;
		console.log("debug5 await delayedReorgRights ok")
		this.appPagesService.loadPageStructure()
		loading.dismiss();

		this.globalService.everythingLoaded.next(true);

		this.globalService.lloaded = true

		endTime = new Date().getTime();
		elapsedTime = endTime - startTime;
		console.log(`Elapsed time 4: ${elapsedTime} ms`);

		/*this.dataLoader2.loadData2();
		console.log(" ☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️dataLoader2")
		this.dataLoader2.dataLoaded2.subscribe(loaded => {
			console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader2 xx", loaded)
			if (loaded) {
	
				const dataLoader = this.injector.get(DataLoaderService);
				dataLoader.loadData();
				console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ dataLoader")
				dataLoader.dataLoaded.subscribe(loaded => {
					if (loaded) {
						console.log("☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️☀️ authentication end loaded")

						await this.delay(10000);
						this.appPagesService.loadPageStructure()

						loading.dismiss();
					}
				});
	
			}
		});*/
		//});

		endTime = new Date().getTime();    // Get end time in milliseconds
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time total: ${elapsedTime} ms`);
	}

	async logout(): Promise<void> {		
		this.appPagesService.appPages = []
		await this.cacheService.clearCache();
		await this.storage.remove(this.tokenKey);
		this.globalService.currentUserLogin = "";
		this.globalService.currentPersonne  = null;
		this.globalService.currentIdPersonne = null;
		this.globalService.currentIdUtilisateur = null;
		this.router.navigate(['/login']);
	}

	async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		console.log("canActivate1", window.location, this.router.url, next)
		if (this.router.url.includes('reset-password') || this.router.url.includes('reset-password-confirm')) {
			console.group("ask reset")
			return true;
		}
		console.log("canActivate1", window.location, this.router.url, next)
		if (!this.globalService.lloaded) {
			console.log("canActivate3 false")
			this.router.navigate(['/login']);
			return false;
		}
		const token = await this.getToken();
		if (token && await this.apiService.checkToken(token)) {
			console.log("canActivate2 true", this.router.url, next)
			return true;
		} else {
			console.log("canActivate2 false", this.router.url, next)
			this.router.navigate(['/login']);
			return false;
		}
	}

	/////old below
	/*
		public isAuthenticated = false;
		public error = false;
	
		async getloginfromdb(key) {
			const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
			const params = new HttpParams()
				//.set('key', (JSON.stringify(key)))
				.set('table', ("users"))
	
			console.log("login load")
	
			const url = "https://restapi11.edservices.fr/api/process.php"
			const data = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise();
			console.log("getloginfromdb",data)
			const userfound = data.filter(d=>d.user == key.user && d.password == key.password)
			if ( userfound.length > 0) {
				console.log("userfound",userfound[0])
				this.global.users = data.filter(d=>d.tableprefix == userfound[0].tableprefix)
				this.global.userskeyvalue = this.global.users.map(x=>{return {key:x.userid,value:x.user}})
				this.global.userskeyvalue.unshift({key:"0",value:"-"})
				console.log("this.global.userskeyvalue ",this.global.userskeyvalue )
			} else {
				console.error("user not found")
			}
			return userfound
		}
	
		async loadinfo(data) {
			this.global.tableprefix = data[0]["tableprefix"] + "_"
			this.global.userid = data[0]["userid"]
			this.global.user = data[0]
	
			const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
			const params = new HttpParams()
				.set('table', (this.global.tableprefix + "settings"))
	
			console.log("💾 global settings load", params)
	
			const url = "https://restapi11.edservices.fr/api/process.php"
			const tmp = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise()
			console.log("tmp", JSON.parse(tmp[0].data))
			this.global.infoSociete = new InfoSociete().fromObj(JSON.parse(tmp[0].data))
			console.log("this.global.infoSociete",this.global.infoSociete)
			this.global.infoSociete_raisonsociale = this.global.infoSociete.raisonsocial;
		}
	
		async oldlogin(username, password): Promise<Observable<boolean>> {
			this.error = false;
			// Implement your authentication logic here
			// For demo purposes, we'll just accept any username/password
	
			const data = await this.getloginfromdb({ user: username, password: password });
	
			console.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx login load", data);
	
			if (data.length == 0) {
				this.error = true;
				this.isAuthenticated = false;
				return of(this.isAuthenticated);
			}
	
			await this.loadinfo(data)
	
			//await this.global.delay(5000);
	
			var response = {
				user_id: data[0]["userid"],
				user_name: data[0]["user"],
				token: data[0]["token"]
			};
			this.storage.set('USER_INFO', response).then((response) => {
				this.router.navigate(['nodes']);
				this.error = false;
				this.global.authState.next(true);
				console.log("login ok");
			});
	
			this.error = false;
			this.isAuthenticated = true;
			return of(this.isAuthenticated);
	
	
		}
	
		logout(): void {
			console.log("logout")
			this.storage.remove('USER_INFO').then(() => {
				this.router.navigate(['login']);
				this.global.authState.next(false);
			});
	
			this.isAuthenticated = false;
		}
	
		get isLoggedIn(): boolean {
			return this.isAuthenticated;
		}
	
		async ifLoggedIn() {
			console.log("ifLoggedIn")
			const response = await this.storage.get('USER_INFO')
			if (response) {
				console.log("response", response)
	
				const data = await this.getloginfromdb({ user: response["user_name"], token: response["token"] })
	
				console.log("ifLoggedIn getloginfromdb", data)
				if (data.length > 0) {
					await this.loadinfo(data)
	
					this.isAuthenticated = true;
					console.log("ifLoggedIn true")
					this.global.authState.next(true);
					this.router.navigate(['factures']);
				}
			}
		}
	
		async ifLoggedIn2(route: Route) {
	
			console.log("ifLoggedIn2", this.isAuthenticated)
			if (this.isAuthenticated) {
				console.log("already authenticated")
				return true;
			}
			const response = await this.storage.get('USER_INFO');
	
	
			console.log("response", response)
	
			if (response == null) {
				this.router.navigate(['login']);
				console.log("ifloggedin2 real res false");
				return false;
			}
	
			const data = await this.getloginfromdb({ user: response["user_name"], token: response["token"] });
	
			console.log("ifLoggedIn2 getloginfromdb", data)
			if (data.length > 0) {
	
				await this.loadinfo(data)
	
				this.isAuthenticated = true;
				this.global.authState.next(true)
				console.log("ifloggedin2 real res true", data[0]);
				return true;
	
			}
			this.router.navigate(['login']);
			console.log("ifloggedin2 real res false");
			return false;
	
		}
	
		/*login() {
			var response = {
				user_id: '007',
				user_name: 'test'
			};
			this.storage.set('USER_INFO', response).then((response) => {
				this.router.navigate(['factures']);
				this.authState.next(true);
				console.log("login ok");
			});
		}
		logout() {
			this.storage.remove('USER_INFO').then(() => {
				this.router.navigate(['login']);
				this.authState.next(false);
			});
		}
		isAuthenticated() {
			return this.authState.value;
		}*/
}