export interface Icfg_pays {
	id: Number;
	code: String;
	libelle: String;
	numeroISO: String;
}

export class cfg_pays implements Icfg_pays {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	libelle: String;
	numeroISO: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.libelle = "";
		this.numeroISO = "";
	}

	public fromObj(entry: Icfg_pays) {
		this.id = entry.id;
		this.code = entry.code;
		this.libelle = entry.libelle;
		this.numeroISO = entry.numeroISO;
		return this;
	}

}
