import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appAutoSlash]'
})
export class AutoSlashDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: any) {
    console.log("HostListener")
    let input = this.el.nativeElement.value.replace(/\D/g, ''); // Remove all non-numeric characters
    if (input.length >= 2) {
      input = input.substring(0, 2) + '/' + input.substring(2);
    }
    if (input.length >= 5) {
      input = input.substring(0, 5) + '/' + input.substring(5, 9);
    }
    this.el.nativeElement.value = input;
  }
}
