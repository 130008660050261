import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { etq_etiquettes } from '../types/type_etq_etiquettes';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';
import { drt_acces_role } from '../types/type_drt_acces_role';

@Injectable({
  providedIn: 'root'
})
export class DrtAccesRoleService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("drt_acces_role", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new drt_acces_role()).fromObj(dbentry)
		return res

	}


	override onLoadingFinished() {
		console.log("finished drt_acces_role", this.entries)
	}


	override getDbFields() {
		return [{ field: "idAcces", nullable: false },
		{ field: "idRole", nullable: false }
		]
	}
}
