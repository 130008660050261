export interface Iins_typeinstance {
	id: Number;
	code: String;
	libelle: String;
	ordre: Number;
}

export class ins_typeinstance implements Iins_typeinstance {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	libelle: String;
	ordre: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.libelle = "";
		this.ordre = 0;
	}

	public fromObj(entry: Iins_typeinstance) {
		this.id = entry.id;
		this.code = entry.code;
		this.libelle = entry.libelle;
		this.ordre = entry.ordre;
		return this;
	}

}
