export interface Ifin_prelevement_virement {
	id: Number;
	date: String;
	etat: String;
	montant: Number;
	type: String;
	idStructure: Number;
	idCommande: Number;
	idPersonne: Number;
	dateEcheance: String;
}

export class fin_prelevement_virement implements Ifin_prelevement_virement {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	date: String;
	etat: String;
	montant: Number;
	type: String;
	idStructure: Number;
	idCommande: Number;
	idPersonne: Number;
	dateEcheance: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.date = "";
		this.etat = "";
		this.montant = 0;
		this.type = "";
		this.idStructure = 0;
		this.idCommande = 0;
		this.idPersonne = 0;
		this.dateEcheance = "";
	}

	public fromObj(entry: Ifin_prelevement_virement) {
		this.id = entry.id;
		this.date = entry.date;
		this.etat = entry.etat;
		this.montant = entry.montant;
		this.type = entry.type;
		this.idStructure = entry.idStructure;
		this.idCommande = entry.idCommande;
		this.idPersonne = entry.idPersonne;
		this.dateEcheance = entry.dateEcheance;
		return this;
	}

}
