import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, combineLatest, forkJoin, timer } from 'rxjs';
import { GlobalService } from './global.service';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FinOperationService } from './fin-operation.service';
import { FinPrelevementVirementService } from './fin-prelevement-virement.service';
import { TrcTraceService } from './trc-trace.service';
import { PdtCommandeService } from './pdt-commande.service';
import { PdtProduitcommandeService } from './pdt-produitcommande.service';
import { CfgParametresLicenceCommandeeService } from './cfg-parametres-licence-commandee.service';
import { LicLicencecommandeeService } from './lic-licencecommandee.service';
import { FinInfosbancairesService } from './fin-infosbancaires.service';

@Injectable({
	providedIn: 'root'
})
export class AdherentResolverService implements Resolve<any> {

	constructor(
		public global: GlobalService,
		public finInfosbancairesService: FinInfosbancairesService,
		public finOperationService: FinOperationService,
		public finPrelevementVirementService: FinPrelevementVirementService,
		public trcTraceService: TrcTraceService,
		public pdtCommandeService: PdtCommandeService,
		public pdtProduitcommandeService: PdtProduitcommandeService,
		public cfgParametresLicenceCommandeeService: CfgParametresLicenceCommandeeService,
		public licLicencecommandeeService: LicLicencecommandeeService
	) { }

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
		const id = route.paramMap.get('id');
		console.log("debug1 AdherentResolverService resolve", id)


		console.log("debug1 AdherentResolverService isready")

		return this.global.isReady.pipe(
			filter((ready) => ready === true),  // Wait until the boolean is `true`
			take(1),  // Only take the first `true` value and complete the observable
			switchMap(() => {

				console.log("debug1 AdherentResolverService globa isready ok")

				this.finInfosbancairesService.loadFromPersonne(id)
				this.finOperationService.loadFromPersonne(id)
				this.finPrelevementVirementService.loadFromPersonne(id)
				this.trcTraceService.loadFromPersonne(id)
				this.pdtCommandeService.loadFromPersonne(id)
				this.pdtProduitcommandeService.loadFromPersonne(id)
				this.cfgParametresLicenceCommandeeService.loadFromPersonne(id)
				this.licLicencecommandeeService.loadFromPersonne(id)

				const delay = timer(5000); // Wait for 2 seconds

				/*this.pdtProduitcommandeService.getLoadingComplete().pipe(filter(val => val === true)).subscribe((data)=>
					console.log("debug1 debug1",data)
				)*/

				return combineLatest([
					this.finInfosbancairesService.getLoadingComplete(),
					this.finOperationService.getLoadingComplete(),
					this.finPrelevementVirementService.getLoadingComplete(),
					this.trcTraceService.getLoadingComplete(),
					this.pdtCommandeService.getLoadingComplete(),
					this.pdtProduitcommandeService.getLoadingComplete(),
					this.cfgParametresLicenceCommandeeService.getLoadingComplete(),
					this.licLicencecommandeeService.getLoadingComplete(),
					//delay
				]).pipe(
					// Filter only when all values are true
					filter(([
						finInfosbancairesServiceComplete,
						finOperationServiceComplete,
						finPrelevementVirementServiceComplete,
						trcTraceServiceComplete,
						pdtCommandeServiceComplete,
						pdtProduitcommandeServiceComplete,
						cfgParametresLicenceCommandeeServiceComplete,
						licLicencecommandeeServiceComplete,
					//	delay
					]) =>
						finInfosbancairesServiceComplete &&
						finOperationServiceComplete &&
						finPrelevementVirementServiceComplete &&
						trcTraceServiceComplete &&
						pdtCommandeServiceComplete &&
						pdtProduitcommandeServiceComplete &&
						cfgParametresLicenceCommandeeServiceComplete &&
						licLicencecommandeeServiceComplete 
					//	delay==0
					)
				)
			})
		)

		//"fin_operation", on charge au coup par coup maintenant
		//"fin_prelevement_virement", on charge au coup par coup maintenant
		//"pdt_commande", on charge au coup par coup maintenant
		//"lic_licencecommandee", on charge au coup par coup maintenant
		//"trc_trace",	
		//cfgParametresLicenceCommandeeService

		/*return this.global.isReady.pipe(
			filter((ready) => ready === true),  // Wait until the boolean is `true`
			take(1),  // Only take the first `true` value and complete the observable
			switchMap(() => {

				console.log("debug1 AdherentResolverService isready")

				this.finOperationService.loadFromPersonne(id)
				this.finPrelevementVirementService.loadFromPersonne(id)
				this.trcTraceService.loadFromPersonne(id)
				this.pdtCommandeService.loadFromPersonne(id)
				this.pdtProduitcommandeService.loadFromPersonne(id)
				this.cfgParametresLicenceCommandeeService.loadFromPersonne(id)

				return forkJoin({
					finOperationComplete: this.finOperationService.getLoadingComplete().pipe(filter(val => val === true)),
					finPrelevementVirementComplete: this.finPrelevementVirementService.getLoadingComplete().pipe(filter(val => val === true)),
					trcTraceComplete: this.trcTraceService.getLoadingComplete().pipe(filter(val => val === true)),
					pdtCommandeComplete: this.pdtCommandeService.getLoadingComplete().pipe(filter(val => val === true)),
					pdtProduitcommandeComplete: this.pdtProduitcommandeService.getLoadingComplete().pipe(filter(val => val === true)),
					cfgParametresLicenceCommandeeComplete: this.cfgParametresLicenceCommandeeService.getLoadingComplete().pipe(filter(val => val === true)),
				})
			})
		)*/
	}
}
