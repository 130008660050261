// src/app/services/data-loader.service.ts
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, first } from 'rxjs';
import { GlobalService } from './global.service';
import { InsFonctionService } from 'src/app/Services/ins-fonction.service';
import { InsFonctionAutoriseeService } from 'src/app/Services/ins-fonction-autorisee.service';
import { InsInstanceService } from 'src/app/Services/ins-instance.service';
import { InsTypeFonctionService } from 'src/app/Services/ins-type-fonction.service';
import { AdrCodePostalVilleService } from './adr-code-postal-ville.service';
import { AdrAdressePostaleService } from './adr-adresse-postale.service';
import { CfgSaisonService } from './cfg-saison.service';
import { PdtTarifproduitService } from './pdt-tarifproduit.service';
import { PdtProduitService } from './pdt-produit.service';
import { LicCategorieAgeService } from './lic-categorie-age.service';
import { InsTypeInstanceService } from './ins-type-instance.service';
import { PerPersonneService } from './per-personne.service';
import { StrTypeStructureService } from './str-type-structure.service';
import { TrcTypeTraceService } from './trc-type-trace.service';
import { FinTypeOperationService } from './fin-type-operation.service';
import { FinBanquesService } from './fin-banques.service';
import { InfosComplementairesService } from './infos-complementaires.service';
import { CfgPaysService } from './cfg-pays.service';
import { GedRepertoireService } from './ged-repertoire.service';
//import { FinInfosbancairesService } from './fin-infosbancaires.service';
//import { FinOperationService } from './fin-operation.service';
//import { PdtProduitcommandeService } from './pdt-produitcommande.service';
//import { LicLicencecommandeeService } from './lic-licencecommandee.service';
//import { PdtCommandeService } from './pdt-commande.service';
//import { TrcTraceService } from './trc-trace.service';
//import { FinPrelevementVirementService } from './fin-prelevement-virement.service';

@Injectable({
	providedIn: 'root'
})
export class DataLoaderService {

	public done = false;

	private nbloaded = 0;

	private loadedTables = [];
	private data: any[] = [];
	public dataLoaded: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	hash: string;

	constructor(private http: HttpClient,
		public gedRepertoireService: GedRepertoireService,
		//public finOperationService: FinOperationService,
		public cfgPaysService: CfgPaysService,
		public infosComplementairesService: InfosComplementairesService,
		public globalService: GlobalService,
		public trcTypeTraceService: TrcTypeTraceService,
		public insFonctionService: InsFonctionService,
		public insFonctionAutoriseeService: InsFonctionAutoriseeService,
		public insInstanceService: InsInstanceService,
		public insTypeFonctionService: InsTypeFonctionService,
		public insTypeInstanceService: InsTypeInstanceService,
		public adrCodePostalVilleService: AdrCodePostalVilleService,
		public adrAdressePostaleService: AdrAdressePostaleService,
		//public pdtProduitcommandeService: PdtProduitcommandeService,
		public cfgSaisonService: CfgSaisonService,
		public pdtTarifproduitService: PdtTarifproduitService,
		public pdtProduitService: PdtProduitService,
		public licCategorieAgeService: LicCategorieAgeService,
		public perPersonneService: PerPersonneService,
		public strTypeStructureService: StrTypeStructureService,
		public finTypeOperationService: FinTypeOperationService,
		public finBanquesService: FinBanquesService,
		//public finInfosbancairesService: FinInfosbancairesService,
		//public licLicencecommandeeService: LicLicencecommandeeService,		
		//public pdtCommandeService: PdtCommandeService,		
		//public finPrelevementsService: FinPrelevementVirementService
		//public trcTraceService: TrcTraceService

	) { 
		
	}

	prepareLoad() {
		this.nbloaded = 0;
		this.insTypeFonctionService.callbackendloaded = this.endLoaded.bind(this,this.insTypeFonctionService)

		this.trcTypeTraceService.dataEventLoadedSubscription = this.trcTypeTraceService.dataEventLoaded.subscribe({
			next:(response)=>{
				console.log("trcTypeTraceService 🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃")
				this.trcTypeTraceService.dataEventLoadedSubscription.unsubscribe()
			}
		})
		
		this.insFonctionService.dataEventLoadedSubscription = this.insFonctionService.dataEventLoaded.subscribe({
			next:(response)=>{
				console.log("insFonctionService 🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃")
				this.insFonctionService.dataEventLoadedSubscription.unsubscribe()
			}
		})

		this.insTypeFonctionService.dataEventLoadedSubscription = this.insTypeFonctionService.dataEventLoaded.subscribe({
			next:(response)=>{
				console.log("insTypeFonctionService 🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃")
				this.insTypeFonctionService.dataEventLoadedSubscription.unsubscribe()
			}
		})
	

		this.pdtProduitService.dataEventLoadedSubscription = this.pdtProduitService.dataEventLoaded.subscribe({
			next:(response)=>{
				console.log("pdtProduitService 🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃")
				this.pdtProduitService.dataEventLoadedSubscription.unsubscribe()
			}
		})

		this.gedRepertoireService.dataEventLoadedSubscription = this.gedRepertoireService.dataEventLoaded.subscribe({
			next:(response)=>{
				console.log("gedRepertoireService 🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃🎃")
				this.gedRepertoireService.dataEventLoadedSubscription.unsubscribe()
			}
		})

		this.gedRepertoireService.reload()
		//this.finOperationService.loadFromListe(), on charge au coup par coup
		//this.finPrelevementsService.loadFromListe(), on charge au coup par coup
		this.cfgPaysService.reload()
		this.trcTypeTraceService.reload()
		this.insFonctionService.reload()
		this.insFonctionAutoriseeService.reload()
		this.insInstanceService.reload()
		this.insTypeFonctionService.reload()
		this.insTypeInstanceService.reload()
		this.adrCodePostalVilleService.reload()
		this.adrAdressePostaleService.reload()
		//this.pdtProduitcommandeService.reload()
		this.cfgSaisonService.reload()
		this.pdtTarifproduitService.reload()
		this.pdtProduitService.reload()
		//this.pdtCommandeService.reload()
		this.licCategorieAgeService.reload()
		//this.licLicencecommandeeService.reload()

		if ( !this.globalService.isEspaceAdmin())
			this.perPersonneService.loadFromId(this.globalService.getIdPersonneLoggedIn())
		else
			this.perPersonneService.loadAll()
		
		this.strTypeStructureService.reload()
		this.finTypeOperationService.reload()
		this.finBanquesService.reload()
		//this.finInfosbancairesService.reload()
	}

	endLoaded(table,baseservice) {
		console.log("endLoaded",table)
		this.nbloaded++;
		this.checkLoaded();
	}


	getDataLoadedObservable() {
		return this.dataLoaded.asObservable();
	}

	tables = [ 
		//"fin_operation", on charge au coup par coup maintenant
		//"fin_prelevement_virement", on charge au coup par coup maintenant
		//"pdt_commande", on charge au coup par coup maintenant
		//"lic_licencecommandee", on charge au coup par coup maintenant
		//"trc_trace",	
		//"pdt_produitcommande",
		"ged_repertoire",
		"cfg_pays",
		//"fin_infosbancaires",
		"fin_banques",
		"fin_typeoperation",
		"trc_typetrace",
		"ins_typeinstance",
		"str_typestructure",		
		"ins_fonction",
		"ins_fonction_autorisee",
		"ins_typefonction",
		"adr_adressepostale",
		//"adr_codepostalville", on n'attend plus la fin de chargement, pas important
		"cfg_saison",		
		"pdt_tarifproduit",
		"pdt_produit",
		"lic_categorieage",		
		"per_personne",
		"ins_instance",			
	]



	loadData() {
		console.log("data-loader loadData")
		this.prepareLoad()

		//on charge une simulation
		/*setTimeout(() => {
			for (let i = 0; i < 10000; i++) {
				this.data.push({ id: i, name: `Item ${i}` });
			}
			// Notifier que les données sont chargées
			this.nbloaded++;
			this.checkLoaded();
			console.log("a nbloaded",this.nbloaded)
		}, 3000);*/ // Temps de chargement simulé de 3 secondes

		//on charge insfonction
		this.loadedTables = []
		this.dataLoaded.next(false);
		this.globalService.dataLoaded.next(false)

		this.globalService.loaded.subscribe(t=>{
			if ( this.done )
				return;
			if ( this.tables.includes( t ) ) {
				console.log("this.loadData",t)
				this.loadedTables.push(t)
				this.nbloaded++;
				this.checkLoaded();
				//console.log("b nbloaded",this.nbloaded,this.tables.length,t,this.loadedTables,this.getDifference(this.tables,this.loadedTables),Object.keys(this.globalService.allEntries))
			}
		})
	}

	getDifference(arr1: string[], arr2: string[]): string[] {
		return arr1.filter(item => !arr2.includes(item));
	  }

	  async waitForTrue(): Promise<void> {
		// Create an observable from the BehaviorSubject
		const observable$ = this.dataLoaded.asObservable();

		// Use the first operator to wait until the value is true
		const trueValue = await observable$.pipe(
			// Use the first operator to emit only when the value becomes true
			first(value => value === true)
		).toPromise();

		// At this point, trueValue is true
		console.log('The BehaviorSubject is now true');
	}

	checkLoaded(): void {
		//if (this.nbloaded == this.tables.length) {
		if ( this.globalService.areAllElementsInArray(this.tables, this.loadedTables)) {
			console.log("LOADED COMPLETE2",this.tables,this.loadedTables)//,this.perPersonneService.entries)
			this.dataLoaded.next(true);
			//this.dataLoaded.complete()
			this.globalService.dataLoaded.next(true)
			//this.globalService.dataLoaded.complete()
			this.done = true;
		}
	}
}
