export interface Itrc_trace {
	id: Number;
	dateTrace: String;
	libelle: String;
	idActeur: Number;
	idPersonne: Number;
	idStructure: Number;
	idTypeTrace: Number;
	idEtiquette: Number;
}

export class trc_trace implements Itrc_trace {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	dateTrace: String;
	libelle: String;
	idActeur: Number;
	idPersonne: Number;
	idStructure: Number;
	idTypeTrace: Number;
	idEtiquette: Number;
	idGroupeTrace?: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.dateTrace = "";
		this.libelle = "";
		this.idActeur = 0;
		this.idPersonne = 0;
		this.idStructure = 0;
		this.idTypeTrace = 0;
		this.idEtiquette = 0;
	}

	public fromObj(entry: Itrc_trace) {
		this.id = entry.id;
		this.dateTrace = entry.dateTrace;
		this.libelle = entry.libelle;
		this.idActeur = entry.idActeur;
		this.idPersonne = entry.idPersonne;
		this.idStructure = entry.idStructure;
		this.idTypeTrace = entry.idTypeTrace;
		this.idEtiquette = entry.idEtiquette;
		return this;
	}

}
