<!--
<ion-item>
  <ion-label position="floating">Date</ion-label>
  <ion-input
    [(ngModel)]="formattedDate"
    (ionFocus)="openDatePicker()"
    placeholder="dd/mm/yyyy"
    [value]="formattedDate"
    (ngModelChange)="onDateInput($event)"
    [readonly]="true"
  ></ion-input>
</ion-item>

<ion-modal  [isOpen]="showDatePicker" (ionModalDidDismiss)="closeDatePicker()">
  <ion-datetime
    [(ngModel)]="selectedDate"
    (ionChange)="onDateSelected($event)"
    displayFormat="DD/MM/YYYY"
  ></ion-datetime>
</ion-modal>
-->
<ion-item style="--border-width: 0;">
  <ion-input
    [(ngModel)]="formattedDate"
    placeholder="jj/mm/aaaa"
    (input)="onDateInput($event)"
  ></ion-input>
  <ion-icon slot="end" name="calendar" (click)="openDatePicker()"></ion-icon>
</ion-item>

