import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { trc_trace } from '../types/type_trc_trace';
import { TrcTypeTraceService } from './trc-type-trace.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class TrcTraceService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public trcTypeTraceService: TrcTypeTraceService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService ,injector);
		//super.init("trc_trace", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	loadFromPersonne(idPersonne) {
		console.log("start loadFromPersonne trc_trace")
		this.loadid = idPersonne
		super.init("trc_trace", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
		console.log("end loadFromPersonne trc_trace")
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new trc_trace()).fromObj(dbentry)
		res.idGroupeTrace = this.trcTypeTraceService.entries.find(typeTrace=>typeTrace.id==res.idTypeTrace)?.idGroupeTrace
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished trc_trace", this.entries)
	}

	override getEntriesToDisplay(idvalue = null, search = null, sortedField = null) {
		
		//getEntriesToDisplay(idvalue = null, search = null, sortedField = null, idfieldname = "idcustomer")

		let tmp = super.getEntriesToDisplay(idvalue, search, sortedField, "idPersonne")
		
		/*tmp = tmp.filter(x=>!(x.company == "" && x.lastname=="" && x.firstname=="") )*/
		if (sortedField) {

			if (sortedField.sort == 1)
				return tmp.sort((a, b) => a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a, b) => b[sortedField.field].localeCompare(a[sortedField.field]))
		}
		
		return tmp
	}

	insert(dateTrace, libelle, idActeur, idPersonne, idStructure, idTypeTrace, idEtiquette) {
		const data: any = {
			dateTrace: dateTrace,
			libelle: libelle,
			idActeur: idActeur,
			idPersonne: idPersonne,
			idStructure: idStructure,
			idTypeTrace: idTypeTrace,
			idEtiquette: idEtiquette,
		};


		this.getDbFields().forEach(field => {
			if (field.nullable && (!data[field.field] || (data[field.field] && data[field.field] == ""))) {
				delete data[field.field]// = "NULL"
			}
		});

		/*data.dateDebut = "2024-06-21T20:07:00"
		data.dateFin = "2024-06-28T20:08:00"
		data.idInstance = "1"
		data.idStructure = "404"
		data.idTypeFonction = "1"*/

		console.log("✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ insert", data)

		this.apiService.insertTrace(data).subscribe({
			next: (response) => {
				console.log('Record inserted successfully:', response);
				this.apiService.handleTablesToReset2(response,idPersonne)

				this.loadFromPersonne(idPersonne)
				/*const res = new trc_trace()	
				res.db = 1
				res.dateTrace = dateTrace
				res.libelle = libelle
				res.idActeur = idActeur
				res.idPersonne = idPersonne
				res.idStructure = idStructure
				res.idTypeTrace = idTypeTrace
				res.idEtiquette = idEtiquette		
				res.id = response.id
				console.log("new trace",res)
				this.entries.push(res)*/
			},
			error: (error) => { console.error('Error inserting record:', error); }
		});
	}

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "dateTrace", nullable: true },
		{ field: "libelle", nullable: false },
		{ field: "idActeur", nullable: true },
		{ field: "idPersonne", nullable: true },
		{ field: "idStructure", nullable: true },
		{ field: "idTypeTrace", nullable: false },
		{ field: "idEtiquette", nullable: true },
		]
	}

	async Add(
		dateTrace,
		libelle,
		idActeur,
		idPersonne,
		idStructure,
		idTypeTrace,
		idEtiquette
	) {

		const res = new trc_trace()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.dateTrace = dateTrace
		res.libelle = libelle
		res.idActeur = idActeur
		res.idPersonne = idPersonne
		res.idStructure = idStructure
		res.idTypeTrace = idTypeTrace
		res.idEtiquette = idEtiquette

		this.entries.push(res)
		//const id = await this.save(res);
		//res.id = Number(id);
		return res;
	}
}