// src/app/loading-dialog/loading-dialog.component.ts
import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-dialog',
  template: `
    <div mat-dialog-content>
      <p>Chargement des données, veuillez patienter...</p>
    </div>
  `,
  styles: [`
    div {
      text-align: center;
    }
  `]
})
export class LoadingDialogComponent {}
