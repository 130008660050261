// src/app/services/api.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GlobalService } from './global.service';

@Injectable({
	providedIn: 'root'
})

export class ServerDBApiService {
	public apiUrl = 'https://host16.edservices.fr:3000'; // Change to your API URL
	public apiUrl2 = 'http://host16.edservices.fr:3001'; // Change to your API URL
	public token: string = ""//eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InRlc3QiLCJpYXQiOjE3MTc0MDg0ODEsImV4cCI6MTc0ODk0NDQ4MX0.3Xyu68oO2DFZ1Uj4CdPbLM_8VdN-sxXBrHXO83E-cJQ"

	constructor(private http: HttpClient, public globalService: GlobalService) {
		this.token = this.globalService.token
	}

	public getHeaders(): HttpHeaders {
		this.token = this.globalService.token
		console.log("serverdbapi token", this.token)
		const headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': this.token
		});
		return headers
	}

	public getHeaders2(): HttpHeaders {
		this.token = this.globalService.token
		const headers = new HttpHeaders({
			'Authorization': this.token
		});
		return headers
	}

	handleTablesToReset(response) {
		console.log("handleTablesToReset", response)
		if (response.tablestoreset) {
			response.tablestoreset.forEach(element => {
				this.globalService.cacheService.delete(element)
			});
		}
	}

	handleTablesToReset2(response, id?) {
		console.log("handleTablesToReset", response)
		if (response.tablestoreset) {
			response.tablestoreset.forEach(element => {
				this.globalService.cacheService.delete2(element, id)
			});
		}
	}

	loadTable(table, id = null, params = null): Observable<any> {
		let httpParams = new HttpParams();
		const headers = this.getHeaders()
		console.log("loadTable ", table, id, headers)
		let url = this.apiUrl + '/' + table
		if (id)
			url += '/' + id
		if (params !== null) {
			//params = params.set('idParametrePere', idParametrePere.toString());
			httpParams = httpParams.set("params", params);
		}
		return this.http.get<any[]>(url, { params: httpParams, headers })
	}

	getParams(type: string, idParametrePere: number | null): Observable<any> {
		const headers = this.getHeaders()
		let params = new HttpParams().set('type', type);
		if (idParametrePere !== null) {
			params = params.set('idParametrePere', idParametrePere.toString());
		}
		return this.http.get<any>(this.apiUrl + "/params", { params, headers });
	}

	////////////////////////////////////////////////////////////////////
	//common
	delete(basetable, id): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const headers = this.getHeaders()
			this.http.delete(this.apiUrl + `/${basetable}/${id}`, { headers }).subscribe(
				{
					next: (response) => {
						this.handleTablesToReset(response)
						console.log('Item deleted successfully in table ' + basetable, response);
						resolve(true)
					},
					error: (err) => {
						console.error('Error deleting item', err);
						resolve(false)
					}
				})
		})
	}
	//end common
	////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////
	//start questions
	getQuestionsOrders(): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = this.getHeaders()
			this.http.get(`${this.apiUrl}/cfg_parametres_ordre`, { headers }).subscribe({
				next: (response) => {
					console.log('get questions response:', response);
					resolve(response);
				},
				error: (err) => {
					console.error('Error occurred:', err);
					reject(err);
				},
				complete: () => {
					console.log('get questions operation completed');
				}
			});
		})
	}

	getQuestions(): Promise<any> {
		return new Promise((resolve, reject) => {
			const headers = this.getHeaders()
			this.http.get(`${this.apiUrl}/cfg_parametres`, { headers }).subscribe({
				next: (response) => {
					console.log('get questions response:', response);
					resolve(response);
				},
				error: (err) => {
					console.error('Error occurred:', err);
					reject(err);
				},
				complete: () => {
					console.log('get questions operation completed');
				}
			});
		})
	}

	updateQuestion(question): Observable<any> {
		const headers = this.getHeaders()
		return this.http.put(`${this.apiUrl}/cfg_parametres/${question.id}`, question, { headers });
	}

	deleteQuestion(id: number): Promise<any> {
		return new Promise((resolve, reject) => {
			const basetable = "cfg_parametres"
			const headers = this.getHeaders()
			console.log("deleteQuestion", id, headers)
			this.http.delete(`${this.apiUrl}/cfg_parametres/${id}`, { headers }).subscribe({
				next: (response) => {
					console.log('Delete response:', response);
					resolve(response);
				},
				error: (err) => {
					console.error('Error occurred:', err);
					reject(err);
				},
				complete: () => {
					console.log('Delete operation completed');
				}
			});
		})
	}

	addQuestion(question): Observable<any> {
		const headers = this.getHeaders()
		return this.http.post(`${this.apiUrl}/cfg_parametres`, question, { headers });
	}

	loadQuestions(): Observable<any> {
		const headers = this.getHeaders()
		return this.http.get<any[]>(this.apiUrl + '/cfg_parametres', { headers })
	}

	deleteSubQuestion(questionId): Observable<any> {
		const headers = this.getHeaders()
		return this.http.delete(this.apiUrl + `/cfg_parametres/${questionId}`, { headers })
	}

	addQuestions(newQuestion) {
		const headers = this.getHeaders()
		return this.http.post(this.apiUrl + '/cfg_parametres', newQuestion, { headers })
	}

	addSubQuestion(parentQuestionId, text) {
		const headers = this.getHeaders()
		return this.http.post(this.apiUrl + `/cfg_parametres/${parentQuestionId}/subquestions`, { text }, { headers })
	}

	updateQuestionText(questionId, newText) {
		const headers = this.getHeaders()
		return this.http.put<void>(this.apiUrl + `/cfg_parametres/${questionId}`, { text: newText }, { headers })
	}

	addAnswer(newAnswer) {
		const headers = this.getHeaders()
		return this.http.post(this.apiUrl + '/answers', newAnswer, { headers })
	}
	//end questions
	////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////
	//structure fonction
	getNbOfAdherentsPerStructure(idSaison: any): Observable<any> {
		const headers = this.getHeaders()
		const body = { idSaison };
		return this.http.post(this.apiUrl + "/structure/getNbOfAdherentsPerStructure", body, { headers });
	}
	//end structure fonction
	////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////
	//start fonction
	insertFonction(data: any): Observable<any> {
		const headers = this.getHeaders()
		return this.http.post(this.apiUrl + "/fonction", data, { headers });
	}
	//end fonction
	////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////
	//start prelevement
	insertPrelevement(data: any): Observable<any> {
		const headers = this.getHeaders()
		return this.http.post(this.apiUrl + "/prelevement", data, { headers });
	}
	//end prelevement
	////////////////////////////////////////////////////////////////////


	////////////////////////////////////////////////////////////////////
	//start fonction
	loadTraces(idPersonne): Observable<any> {
		const headers = this.getHeaders()
		return this.http.get<any[]>(this.apiUrl + '/trace/' + idPersonne, { headers })
	}

	insertTrace(data: any): Observable<any> {
		const headers = this.getHeaders()
		return this.http.post(this.apiUrl + "/trace", data, { headers });
	}
	//end fonction
	////////////////////////////////////////////////////////////////////

	////////////////////////////////////////////////////////////////////
	//paiement	
	checkCreditCardPaiement(idProduitCommande, idTypeOperation) {
		const headers = this.getHeaders()
		const body = { idProduitCommande, idTypeOperation };
		return this.http.post(this.apiUrl + '/fin_operation', body, { headers })
	}

	importReglements(data) {
		const headers = this.getHeaders()
		const body = { data };
		return this.http.post(this.apiUrl + '/fin_operation/import', body, { headers })
	}
	//paiement
	////////////////////////////////////////////////////////////////////	

	sendResetLink(emailOrCode: string) {
		return this.http.post(this.apiUrl + '/send-reset-link', { emailOrCode }).toPromise();
	}

	verifyResetToken(token: string) {
		return this.http.get<any>(this.apiUrl + `/verify-reset-token?token=${token}`).toPromise();
	}

	resetPassword(token: string, newPassword: string) {
		return this.http.post(this.apiUrl + '/reset-password', { token, newPassword }).toPromise();
	}

	uploadFiles(formData) {
		const headers = this.getHeaders2()
		console.log("formData ", formData)
		return this.http.post(this.apiUrl + '/upload', formData, { headers });//, {headers:h});
	}

	deleteFile(fileId) {
		const headers = this.getHeaders()
		return this.http.delete(this.apiUrl + `/ged_fichier/${fileId}`, { headers });//, {headers:h});		
	}

	downloadFile(filename: string) {
		const headers = this.getHeaders()
		return this.http.get(`${this.apiUrl}/download/${filename}`, { responseType: 'blob', headers });
	}

	async checkToken(token: string): Promise<boolean> {
		const headers = this.getHeaders()
		try {
			const res: any = await this.http.post(`${this.apiUrl}/check-token`, { token }, {headers}).toPromise();
			console.log("checktoken res", res)
			this.globalService.droits = res.droits

			return true;
		} catch {
			return false;
		}
	}
}