export interface Iins_fonction {
	id: Number;
	codeEnvoiCourrierPersonne: String;
	dateDebut: String;
	dateFin: String;
	libelle: String;
	isSalarie: String;
	idFonctionAutorisee: Number;
	idPersonne: Number;
	idStructure: Number;
	email: String;
}

export class ins_fonction implements Iins_fonction {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	codeEnvoiCourrierPersonne: String;
	dateDebut: String;
	dateFin: String;
	libelle: String;
	isSalarie: String;
	idFonctionAutorisee: Number;
	idPersonne: Number;
	idStructure: Number;
	email: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.codeEnvoiCourrierPersonne = "";
		this.dateDebut = "";
		this.dateFin = "";
		this.libelle = "";
		this.isSalarie = "";
		this.idFonctionAutorisee = 0;
		this.idPersonne = 0;
		this.idStructure = 0;
		this.email = "";
	}

	public fromObj(entry: Iins_fonction) {
		this.id = entry.id;
		this.codeEnvoiCourrierPersonne = entry.codeEnvoiCourrierPersonne;
		this.dateDebut = entry.dateDebut;
		this.dateFin = entry.dateFin;
		this.libelle = entry.libelle;
		this.isSalarie = entry.isSalarie;
		this.idFonctionAutorisee = entry.idFonctionAutorisee;
		this.idPersonne = entry.idPersonne;
		this.idStructure = entry.idStructure;
		this.email = entry.email;
		return this;
	}

}
