<ion-header>
	<ion-toolbar>
		<ion-title>Ajouter une entrée</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="dismiss()">Annuler</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content class="ion-padding">
	<form (ngSubmit)="addEntry()">
		<ion-list>
			<div [ngClass]="field.error ? 'error' : ''" *ngFor="let field of fields" >
				<ion-item [ngClass]="field.error ? 'error' : ''" *ngIf="field.default" style="display:none">
					<ion-input label="xxx{{ field.label }}" [(ngModel)]="formValues[field.field]"
						[name]="field.field"></ion-input>
				</ion-item>
				<ion-item [ngClass]="field.error ? 'error' : ''" *ngIf="field.type === 'string' || !field.type && !field.default">
					<ion-input label="{{ field.label }}" [(ngModel)]="formValues[field.field]"
						[name]="field.field"></ion-input>
				</ion-item>
				<ion-item [ngClass]="field.error ? 'error' : ''" *ngIf="field.type === 'date'">
					<ion-label>{{ field.label }}</ion-label>
					<app-date-picker [(dateSelected)]="this.formValues[field.field]"></app-date-picker>
					<!--<ion-datetime [(ngModel)]="formValues[field.field]" [name]="field.field"></ion-datetime>-->
				</ion-item>
				<ion-item [ngClass]="field.error ? 'error' : ''" *ngIf="field.type === 'currency'">
					<ion-input label="{{ field.label }}" type="number" [(ngModel)]="formValues[field.field]"
						[name]="field.field"></ion-input>
				</ion-item>

				<ion-item [ngClass]="field.error ? 'error' : ''" *ngIf="field.type === 'structure'">
					<select-section-locale [textalignright]="true" [noborder]="true" [label]="field.label" [(myProp)]="formValues[field.field]"></select-section-locale>
				</ion-item>

				<ion-item [ngClass]="field.error ? 'error' : ''" *ngIf="field.options">
					<ion-select label="{{ field.label }}" [(ngModel)]="formValues[field.field]" [name]="field.field">
						<ion-select-option *ngFor="let option of field.options" [value]="option.key">{{ option.value
							}}</ion-select-option>
					</ion-select>
				</ion-item>
			</div>
		</ion-list>
		<ion-footer style="margin-top:32px">
			<ion-button expand="full" type="submit">Ajouter</ion-button>
		</ion-footer>

		<ion-list>
			<p style="padding:0;margin:0;line-height: normal;font-size:small;color:red;" *ngFor="let error of errors">{{error}}</p>
		</ion-list>
	</form>
</ion-content>