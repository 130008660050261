export interface Ipdt_produitcommande {
	id: Number;
	dateEnregistrement: String;
	date: string;
	etat: String;
	quantite: Number;
	montantPaye: Number;
	montantPrisPrePaye: Number;
	idCommande: Number;
	idPersonne: Number;
	idProduit: Number;
	idSaison: Number;
	idStructure: Number;
	idTarif: Number;
}

export class pdt_produitcommande implements Ipdt_produitcommande {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	dateEnregistrement: String;
	date: string;
	etat: String;
	quantite: Number;
	montantPaye: Number;
	montantPrisPrePaye: Number;
	idCommande: Number;
	idPersonne: Number;
	idProduit: Number;
	idSaison: Number;
	idStructure: Number;
	idTarif: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.dateEnregistrement = "";
		this.date = "";
		this.etat = "";
		this.quantite = 0;
		this.montantPaye = 0;
		this.montantPrisPrePaye = 0;
		this.idCommande = 0;
		this.idPersonne = 0;
		this.idProduit = 0;
		this.idSaison = 0;
		this.idStructure = 0;
		this.idTarif = 0;
	}

	public fromObj(entry: Ipdt_produitcommande) {
		this.id = entry.id;
		this.dateEnregistrement = entry.dateEnregistrement;
		this.date = entry.date;
		this.etat = entry.etat;
		this.quantite = entry.quantite;
		this.montantPaye = entry.montantPaye;
		this.montantPrisPrePaye = entry.montantPrisPrePaye;
		this.idCommande = entry.idCommande;
		this.idPersonne = entry.idPersonne;
		this.idProduit = entry.idProduit;
		this.idSaison = entry.idSaison;
		this.idStructure = entry.idStructure;
		this.idTarif = entry.idTarif;
		return this;
	}

}
