export interface Iper_personne {
	id: Number;
	civilite: String;
	codeAdherent: String;
	dateNaissance: String;
	etat: String;
	lieuNaissance: String;
	nationalite: String;
	nom: String;
	nomEpure: String;
	nomJeuneFille: String;
	nomJeuneFilleEpure: String;
	prenom: String
	prenomEpure: String;
	profession: String;
	sexe: String;
	situationFamille: String;
	utilisationAdresse: String;
	idAdresse: Number;
	idcategorieSocioPro: Number;
	idPhotoIdentite: Number;
	idDerniereLicence: Number;
	identifiantExterne: String;
	idInfosBancaires: Number;
	paysNaissance: String;
	departementNaissance: String;
	prenom2: String;
	prenom3: String;
	nomPere: String;
	prenomPere: String;
	nomMere: String;
	prenomMere: String;
	infosAControler: String;
	dateMajInfosAControler: String;
}

export class per_personne implements Iper_personne {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	civilite: String;
	codeAdherent: String;
	dateNaissance: String;
	etat: String;
	lieuNaissance: String;
	nationalite: String;
	nom: String;
	nomEpure: String;
	nomJeuneFille: String;
	nomJeuneFilleEpure: String;
	prenom: String;
	prenomEpure: String;
	profession: String;
	sexe: String;
	situationFamille: String;
	utilisationAdresse: String;
	idAdresse: Number;
	idcategorieSocioPro: Number;
	idPhotoIdentite: Number;
	idDerniereLicence: Number;
	identifiantExterne: String;
	idInfosBancaires: Number;
	paysNaissance: String;
	departementNaissance: String;
	prenom2: String;
	prenom3: String;
	nomPere: String;
	prenomPere: String;
	nomMere: String;
	prenomMere: String;
	infosAControler: String;
	dateMajInfosAControler: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.civilite = "";
		this.codeAdherent = "";
		this.dateNaissance = "";
		this.etat = "";
		this.lieuNaissance = "";
		this.nationalite = "";
		this.nom = "";
		this.nomEpure = "";
		this.nomJeuneFille = "";
		this.nomJeuneFilleEpure = "";
		this.prenom = "";
		this.prenomEpure = "";
		this.profession = "";
		this.sexe = "";
		this.situationFamille = "";
		this.utilisationAdresse = "";
		this.idAdresse = 0;
		this.idcategorieSocioPro = 0;
		this.idPhotoIdentite = 0;
		this.idDerniereLicence = 0;
		this.identifiantExterne = "";
		this.idInfosBancaires = 0;
		this.paysNaissance = "";
		this.departementNaissance = "";
		this.prenom2 = "";
		this.prenom3 = "";
		this.nomPere = "";
		this.prenomPere = "";
		this.nomMere = "";
		this.prenomMere = "";
		this.infosAControler = "";
		this.dateMajInfosAControler = "";
	}

	public fromObj(entry: Iper_personne) {
		this.id = entry.id;
		this.civilite = entry.civilite;
		this.codeAdherent = entry.codeAdherent;
		this.dateNaissance = entry.dateNaissance;
		this.etat = entry.etat;
		this.lieuNaissance = entry.lieuNaissance;
		this.nationalite = entry.nationalite;
		this.nom = entry.nom;
		this.nomEpure = entry.nomEpure;
		this.nomJeuneFille = entry.nomJeuneFille;
		this.nomJeuneFilleEpure = entry.nomJeuneFilleEpure;
		this.prenom = entry.prenom;
		this.prenomEpure = entry.prenomEpure;
		this.profession = entry.profession;
		this.sexe = entry.sexe;
		this.situationFamille = entry.situationFamille;
		this.utilisationAdresse = entry.utilisationAdresse;
		this.idAdresse = entry.idAdresse;
		this.idcategorieSocioPro = entry.idcategorieSocioPro;
		this.idPhotoIdentite = entry.idPhotoIdentite;
		this.idDerniereLicence = entry.idDerniereLicence;
		this.identifiantExterne = entry.identifiantExterne;
		this.idInfosBancaires = entry.idInfosBancaires;
		this.paysNaissance = entry.paysNaissance;
		this.departementNaissance = entry.departementNaissance;
		this.prenom2 = entry.prenom2;
		this.prenom3 = entry.prenom3;
		this.nomPere = entry.nomPere;
		this.prenomPere = entry.prenomPere;
		this.nomMere = entry.nomMere;
		this.prenomMere = entry.prenomMere;
		this.infosAControler = entry.infosAControler;
		this.dateMajInfosAControler = entry.dateMajInfosAControler;
		return this;
	}

}
