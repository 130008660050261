export interface Ipdt_tarifproduit {
	id: Number;
	dateDebutTarif: String;
	dateFinTarif: String;
	etatTarif: String;
	libelle: String;
	montant: Number;
	nbMaxLicence: Number;
	nbMinLicence: Number;
	quantiteDebutTarif: Number;
	quantiteFinTarif: Number;
	typeTarif: String;
	idProduit: Number;
	idSaison: Number;
	idStructure: Number;
	idProduitComplementaire: Number;

	tarif: String; //new
}

export class pdt_tarifproduit implements Ipdt_tarifproduit {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	dateDebutTarif: String;
	dateFinTarif: String;
	etatTarif: String;
	libelle: String;
	montant: Number;
	nbMaxLicence: Number;
	nbMinLicence: Number;
	quantiteDebutTarif: Number;
	quantiteFinTarif: Number;
	typeTarif: String;
	idProduit: Number;
	idSaison: Number;
	idStructure: Number;
	idProduitComplementaire: Number;

	tarif: String; //new

	constructor() {
		this.db = 0;
		this.id = 0;
		this.dateDebutTarif = "";
		this.dateFinTarif = "";
		this.etatTarif = "";
		this.libelle = "";
		this.montant = 0;
		this.nbMaxLicence = 0;
		this.nbMinLicence = 0;
		this.quantiteDebutTarif = 0;
		this.quantiteFinTarif = 0;
		this.typeTarif = "";
		this.idProduit = 0;
		this.idSaison = 0;
		this.idStructure = 0;
		this.idProduitComplementaire = 0;
	
		this.tarif = "";
	}

	public fromObj(entry: Ipdt_tarifproduit) {
		this.id = entry.id;
		this.dateDebutTarif = entry.dateDebutTarif;
		this.dateFinTarif = entry.dateFinTarif;
		this.etatTarif = entry.etatTarif;
		this.libelle = entry.libelle;
		this.montant = entry.montant;
		this.nbMaxLicence = entry.nbMaxLicence;
		this.nbMinLicence = entry.nbMinLicence;
		this.quantiteDebutTarif = entry.quantiteDebutTarif;
		this.quantiteFinTarif = entry.quantiteFinTarif;
		this.typeTarif = entry.typeTarif;
		this.idProduit = entry.idProduit;
		this.idSaison = entry.idSaison;
		this.idStructure = entry.idStructure;
		this.idProduitComplementaire = entry.idProduitComplementaire;
		this.tarif = entry.tarif;
		return this;
	}

}
