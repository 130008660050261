import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { lic_categorieage } from '../types/type_lic_categorieage';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LicCategorieAgeService extends BaseService {

	override authneeded = false;
	
	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService,injector);
		super.init("lic_categorieage", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	//funtion passed in constructor
	loadEntry(dbentry) {
		const res = (new lic_categorieage()).fromObj(dbentry)
		return res

	}

}
