<ion-card>
	<ion-card-header>
		<ion-card-title>{{title}}</ion-card-title>
	</ion-card-header>

	<ion-card-content>

		<div class="filterwrapper" *ngIf="filters.length>0">
			<p>Filtres:</p>
			<ion-list>
				<ion-item *ngFor="let filter of filters">
					<ng-container [ngSwitch]="filter.type">
						<ng-container *ngSwitchCase="'daterange'">
							<ion-label>{{ filter.label || filter.field }}</ion-label>

							<app-date-picker (dateSelectedChange)="onDateSelectedChange($event)" [(dateSelected)]="filter.startDate"></app-date-picker>
							<app-date-picker (dateSelectedChange)="onDateSelectedChange($event)" [(dateSelected)]="filter.endDate"></app-date-picker>
							<ion-icon *ngIf="filter.startDate || filter.endDate" name="close-circle"
								(click)="clearFilter(filter)"></ion-icon>
							<!--<ion-datetime display-format="YYYY-MM-DD" [(ngModel)]="filter.startDate"
								placeholder="Start Date"></ion-datetime>
							<ion-datetime display-format="YYYY-MM-DD" [(ngModel)]="filter.endDate"
								placeholder="End Date"></ion-datetime>-->
						</ng-container>
						<ng-container *ngSwitchCase="'select'">
							<ion-label>{{ filter.label || filter.field }}</ion-label>
							<!--<div class="select-container">-->
								<ion-select (ionChange)="onSelectFilterChange($event)" [(ngModel)]="filter.value" style="margin-right:20px" multiple="true">
									<ion-select-option *ngFor="let option of filter.options" [value]="option.key">{{
										option.value }}</ion-select-option>
								</ion-select>
								<ion-icon class="clear-button" *ngIf="filter.value && filter.value.length > 0" name="close-circle"
									(click)="clearFilter(filter)"></ion-icon>
							<!--</div>-->
						</ng-container>
						<ng-container *ngSwitchDefault>
							<ion-label>{{ filter.label || filter.field }}</ion-label>
							<ion-input type="text" [(ngModel)]="filter.value"></ion-input>
							<ion-icon *ngIf="filter.value" name="close-circle" (click)="clearFilter(filter)"></ion-icon>
						</ng-container>
					</ng-container>
				</ion-item>
			</ion-list>
			<!--<ion-button expand="full" (click)="onSearch()">
				<ion-icon name="search" slot="start"></ion-icon>
				Search
			</ion-button>-->
		</div>

		<ion-item *ngIf="searchable" lines="none" class="recherchewrapper">
			<ion-input class="searchinput" fill="outline" (keypress)="search($event.keyCode)"
				[(ngModel)]="tmpSearchValue" placeholder="Entrez votre recherche" type="text" autocorrect="off"
				autocapitalize="none" autocomplete="false">
			</ion-input>
			<ion-icon name="close-circle" (click)="clearSearch()"></ion-icon>
		</ion-item>

		<ion-grid>
			<ion-row>

				<ion-col>
					<div id="abc">TEST</div>
				</ion-col>
			</ion-row>
		</ion-grid>

		<!--/*[style.max-width]="getMaxWidth(field)"*/-->
 <!--[style.width]="field.width + 'px'" -->
		<div>
			<ion-grid [ngClass]="getGridClass()">
				<ion-row class="ion-align-items-center tableheader">
					<ion-col *ngIf="_canopen" style="max-width:38px"></ion-col>
					<ion-col *ngIf="!_reorderDisabled"></ion-col>
					<ion-col [id]="getColId(field)" [style.flex]="getFlexBasis(field)"
						class="resizable-col custom-col" 
						[ngClass]="getClassHeader(field,i)"
						(click)="sort(field)" 
						*ngFor="let field of getDisplayFields(); let i = index">
						<div class="resize-handle" (mousedown)="onMouseDown($event, field)"></div>
						{{field.label}}
						<ion-icon *ngIf="field.sort==1" name="chevron-up"></ion-icon>
						<ion-icon *ngIf="field.sort==2" name="chevron-down"></ion-icon>
					</ion-col>
					<ion-col style="max-width:28px"></ion-col>
					<ion-col style="max-width:28px"></ion-col>
				</ion-row>

				<ion-reorder-group [disabled]="_reorderDisabled"
					(ionItemReorder)="this.global.handleReorder($any($event))">

					<ion-reorder *ngFor="let entry of paginate(); let i = index">

						<ion-row class="ion-align-items-center invoiceline tableline">

							<ion-col *ngIf="_canopen" style="max-width:38px;text-align: center"><ion-icon
									(click)="this._service.open(entry)" name="folder-open-outline"
									size="large"></ion-icon>
							</ion-col>

							<ion-col *ngIf="!_reorderDisabled"><ion-icon name="reorder-three-outline"
									size="large"></ion-icon>
							</ion-col>

							<ion-col [style.flex]="getFlexBasis(field)" [ngClass]="getClass(field,i,j)"
								*ngFor="let field of getDisplayFields(); let j = index">

								<ion-icon class="fieldicon" *ngIf="getType(field) == 'icon'"
									name="{{this.field.iconFunction(entry,field)}}"
									(click)="this.field.callFunction(entry)"></ion-icon>

								<ion-checkbox *ngIf="getType(field) == 'checkbox'" (keyup)="keyup($event,field,i,j)"
									(ionChange)="setFieldValue(entry,field, $event)" [(ngModel)]="entry[ field.field ]">
								</ion-checkbox>

								<div *ngIf="getType(field) == 'function'" (keyup)="keyup($event,field,i,j)">

									<div [innerHTML]="sanitize(this.field.function(entry))"></div>
								</div>

								<ion-input *ngIf="getType(field) == 'autocomplete'" (keyup)="keyup($event,field,i,j)"
									(ngModelChange)="setFieldValue(entry,field, $event)"
									[ngModel]="getFieldValue( entry, field.field )"
									placeholder="{{getPlaceHolder(field)}}" type="text" autocorrect="off"
									autocapitalize="none" autocomplete="false" (ionBlur)="inputAutoCompleteBlur()"
									(ionInput)="inputAutoCompleteChanged($event,entry,i,j,field)">
								</ion-input>

								<ion-select *ngIf="getType(field) == 'select'" (keyup)="keyup($event,field,i,j)"
									(ionChange)="setFieldValue(entry,field, $event)" [(ngModel)]="entry[ field.field ]"
									placeholder="{{getPlaceHolder(field)}}" autocorrect="off" autocapitalize="none"
									autocomplete="false">

									<ion-select-option *ngFor="let option of field.options"
										[value]="option.key">{{option.value}}</ion-select-option>
								</ion-select>

								<ion-input *ngIf="getType(field) == 'date-time'" (keyup)="keyup($event,field,i,j)"
									(ionInput)="setFieldValue(entry,field, $event)"
									(click)="presentDatePopover($event,entry,field,entry[ field.field ])"
									value="{{ this._service.getDateTime( entry[ field.field ] ) }}" id="datetime"
									[ngClass]="getClassInput(field,i)"></ion-input>

								<ion-input *ngIf="getType(field) == 'date'" (keyup)="keyup($event,field,i,j)"
									(ionInput)="setFieldValue(entry,field, $event)"
									(click)="presentDatePopover($event,entry,field,entry[ field.field ])"
									value="{{ this._service.getDate( entry[ field.field ] ) }}" id="date"
									[ngClass]="getClassInput(field,i)"></ion-input>

								<ion-input *ngIf="getType(field) == 'currency'" (keyup)="keyup($event,field,i,j)"
									(ngModelChange)="setFieldValue(entry,field, $event)"
									[ngModel]="getFieldValue( entry, field.field, field )"
									placeholder="{{getPlaceHolder(field)}}" type="text" inputmode="decimal"
									autocorrect="off" autocapitalize="none" autocomplete="false">
								</ion-input>

								<ion-input *ngIf="getType(field) == 'number'" (keyup)="keyup($event,field,i,j)"
									(ngModelChange)="setFieldValue(entry,field, $event)"
									[ngModel]="getFieldValue( entry, field.field )"
									placeholder="{{getPlaceHolder(field)}}" type="number" inputmode="decimal"
									autocorrect="off" autocapitalize="none" autocomplete="false">
								</ion-input>

								<ion-input *ngIf="getType(field) == 'text'" (keyup)="keyup($event,field,i,j)"
									(ngModelChange)="setFieldValue(entry,field, $event)"
									[ngModel]="getFieldValue( entry, field.field, field )"
									placeholder="{{getPlaceHolder(field)}}" type="{{getType(field)}}" autocorrect="off"
									autocapitalize="none" autocomplete="false">
								</ion-input>
							</ion-col>


							<ion-col 
								style="max-width:28px;text-align: center;"><ion-icon *ngIf="this.canUpdateRow(entry)" name="pencil"
								(click)="this._service.updateEntry2(entry)"></ion-icon></ion-col>

							<ion-col 
								style="max-width:28px;text-align: center;"><ion-icon *ngIf="this.canDeleteRow(entry)" name="trash"
								(click)="this._service.deleteEntry2(entry)"></ion-icon></ion-col>

						</ion-row>

					</ion-reorder>
				</ion-reorder-group>

				<!--addFields
												(ionChange)="setAddFieldValue( addfield, $event)" 
							(ngModelChange)="setAddFieldValue( addfield, $event)"
							[ngModel]="getAddFieldValue( entry, field.field, field )"

											
				
											<ion-row class="ion-align-items-center invoiceline tableline">


					<ion-col *ngFor="let addfield of addFields; let j = index">

						<ion-select *ngIf="getAddType(addfield) == 'select'" 
							[(ngModel)]="addfield.value" placeholder="{{getPlaceHolder(addfield)}}" autocorrect="off"
							autocapitalize="none" autocomplete="false">

							<ion-select-option *ngFor="let option of addfield.options"
								[value]="option.key">{{option.value}}</ion-select-option>
						</ion-select>

						<ion-input *ngIf="getAddType(addfield) == 'text'" 
							[(ngModel)]="addfield.value" placeholder="{{getPlaceHolder(addfield)}}"
							type="{{getAddType(addfield)}}" autocorrect="off" autocapitalize="none" autocomplete="false">
						</ion-input>

					</ion-col>
				</ion-row>
-->

				<ion-list #autoCompleteList [style.top]="autoCompleteTop" [style.left]="autoCompleteLeft"
					[hidden]="itemsAutoComplete.length <= 0" class="autocompletelist">
					<ion-item (click)="selectedAutoComplete(itemAutoComplete)"
						*ngFor="let itemAutoComplete of itemsAutoComplete">
						{{itemAutoComplete}}
					</ion-item>
				</ion-list>


				<ion-popover #datepopover [isOpen]="isDatePopoverOpened" (didDismiss)="dismissDatePopover()">
					<ng-template>
						<ion-datetime presentation="{{getPresentationDate()}}" [(ngModel)]="selectedEntryDate"
							locale="fr-FR"></ion-datetime>
					</ng-template>
				</ion-popover>

				<!--<ion-row> old way
					<ion-button [disabled]="false" (click)="addEntry()">Ajouter</ion-button>
					<ion-button *ngIf="reorderEnabled" [disabled]="false"
						(click)="this._reorderDisabled = !_reorderDisabled;">Réorganiser</ion-button>
				</ion-row>-->

				<ion-footer *ngIf="totalPages>1">
					<ion-toolbar>
						<ion-buttons slot="start">
							<ion-button (click)="previousPage()" [disabled]="currentPage === 1">
								Précédent
							</ion-button>
						</ion-buttons>
						<ion-title>
							Page {{ currentPage }} sur {{ totalPages }}
						</ion-title>
						<ion-buttons slot="end">
							<ion-button (click)="nextPage()" [disabled]="currentPage === totalPages">
								Suivant
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
					<ion-toolbar>
						<ion-buttons slot="secondary">
							<!-- Bouton pour la première page -->
							<ion-button (click)="changePage(1)" [disabled]="currentPage === 1">
								1
							</ion-button>

							<!-- Ellipse si nécessaire -->
							<ion-button *ngIf="pages[0] > 2" disabled>...</ion-button>

							<!-- Pages dynamiques -->
							<ion-button *ngFor="let page of pages" (click)="changePage(page)"
								[disabled]="currentPage === page">
								{{ page }}
							</ion-button>

							<!-- Ellipse si nécessaire -->
							<ion-button *ngIf="pages[pages.length - 1] < totalPages - 1" disabled>...</ion-button>

							<!-- Bouton pour la dernière page -->
							<ion-button (click)="changePage(totalPages)" [disabled]="currentPage === totalPages">
								{{ totalPages }}
							</ion-button>
						</ion-buttons>
					</ion-toolbar>
				</ion-footer>


				<ion-row>
					<ion-button *ngIf="this.addable" [disabled]="false" (click)="openAddEntryDialog()">Ajouter</ion-button>
					<ion-button *ngIf="reorderEnabled" [disabled]="false"
						(click)="this._reorderDisabled = !_reorderDisabled;">Réorganiser</ion-button>
				</ion-row>

			</ion-grid>
		</div>

		<ng-content></ng-content>

	</ion-card-content>
</ion-card>