import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';
import { ged_fichier } from '../types/type_ged_fichier';

@Injectable({
	providedIn: 'root'
})
export class GedFichierService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("ged_fichier", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new ged_fichier()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}



	sortItemsByName() {
		this.entries.sort((a, b) => {
			if (a.dateModification < b.dateModification) {
				return 1;
			} else if (a.dateModification > b.dateModification) {
				return -1;
			} else {
				return 0;
			}
		});
	}

	override onLoadingFinished() {
		this.entries.forEach(instance => {
		})

		this.sortItemsByName();
		console.log("finished ged_fichier", this.entries)
	}

	isVisible(file) {

	}

	getVisibleString(file) {
		let res = ""

		if (file.isVisibiliteFederation==1)
			res += "Bureau national - "
		if (file.isVisibiliteLigue==1)
			res += "Région académique - "
		if (file.isVisibiliteZoneInterdept==1)
			res += "??1 - "
		if (file.isVisibiliteDepartement==1)
			res += "Section académique - "
		if (file.isVisibiliteClub==1)
			res += "Section locale - "
		if (file.isVisibiliteEspaceLicencie==1)
			res += "Adhérents - "
		if (file.isVisibiliteHn==1)
			res += "??2 - "

		return res.slice(0,-3)
	}

	/*	override getDbFields() {
			return [{ field: "id", nullable: false },
			{ field: "idSpecialiteStructure", nullable: false },
			{ field: "idTypeInstance", nullable: false },
			{ field: "idTypeStructure", nullable: false }
			]
		}
	
		async Add(
			idSpecialiteStructure,
			idTypeInstance,
			idTypeStructure,
		) {
	
			const res = new ins_instance()
	
			const now = moment().format("YYYY-MM-DD")
	
			res.db = 1
			res.idSpecialiteStructure = idSpecialiteStructure
			res.idTypeInstance = idTypeInstance
			res.idTypeStructure = idTypeStructure
	
			this.entries.push(res)
			const id = await this.save(res);
			res.id = Number(id);
			return res;
		}*/
}
