export interface Istr_structure {
	id: Number;
	code: String;
	etatExtranet: String;
	nom: String;
	nomAbrege: String;
	nomEntreprise: String;
	idAdresseCorrespondance: Number;
	idAdresseSiege: Number;
	idAdresseExpedition: Number;
	idCaracteristiques: Number;
	idAdministratif: Number;
	idInfosBancaires: Number;
	idInfosDiverses: Number;
	idLogo: Number;
	identifiantExterne: String;
}

export class str_structure implements Istr_structure {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	etatExtranet: String;
	nom: String;
	nomAbrege: String;
	nomEntreprise: String;
	idAdresseCorrespondance: Number;
	idAdresseSiege: Number;
	idAdresseExpedition: Number;
	idCaracteristiques: Number;
	idAdministratif: Number;
	idInfosBancaires: Number;
	idInfosDiverses: Number;
	idLogo: Number;
	identifiantExterne: String;
    
	parents: str_structure[]
    children: str_structure[]
	expanded: boolean
	visible: boolean = true

	constructor() {
		this.expanded = false
		this.db = 0;
		
		this.id = 0;
		this.code = "";
		this.etatExtranet = "";
		this.nom = "";
		this.nomAbrege = "";
		this.nomEntreprise = "";
		this.idAdresseCorrespondance = 0;
		this.idAdresseSiege = 0;
		this.idAdresseExpedition = 0;
		this.idCaracteristiques = 0;
		this.idAdministratif = 0;
		this.idInfosBancaires = 0;
		this.idInfosDiverses = 0;
		this.idLogo = 0;
		this.identifiantExterne = "";
	}

	public fromObj(entry: Istr_structure) {
		this.id = entry.id;
		this.code = entry.code;
		this.etatExtranet = entry.etatExtranet;
		this.nom = entry.nom;
		this.nomAbrege = entry.nomAbrege;
		this.nomEntreprise = entry.nomEntreprise;
		this.idAdresseCorrespondance = entry.idAdresseCorrespondance;
		this.idAdresseSiege = entry.idAdresseSiege;
		this.idAdresseExpedition = entry.idAdresseExpedition;
		this.idCaracteristiques = entry.idCaracteristiques;
		this.idAdministratif = entry.idAdministratif;
		this.idInfosBancaires = entry.idInfosBancaires;
		this.idInfosDiverses = entry.idInfosDiverses;
		this.idLogo = entry.idLogo;
		this.identifiantExterne = entry.identifiantExterne;
		return this;
	}

}
