import { Component, OnInit, ViewChild } from '@angular/core';
import { AppPagesService } from './Services/app-pages.service';
import { Storage } from '@ionic/storage-angular';
import { AuthenticationService } from './Services/authentication.service';
import { GlobalService } from './Services/global.service';
import { ParametresService } from './Services/parametres.service';
import { LoadingComponent } from './loading/loading.component';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { IonSplitPane, Platform } from '@ionic/angular';
import { CacheService } from './Services/cache.service';
import { filter } from 'rxjs';

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html',
	styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

	/*@ViewChild('ionSplitPane') ionSplitPane!: IonSplitPane;

	disableIonSplitPane(){
		this.ionSplitPane.disabled = true;
	}

	isLoading = true;

	constructor(
		private router: Router,
		public global: GlobalService,
		public parametresService: ParametresService,
		public appPagesService: AppPagesService,
		private storage: Storage, private auth: AuthenticationService) {

			this.router.events.subscribe((event) => {
				if (event instanceof NavigationStart) {
				  this.isLoading = true;
				} else if (event instanceof NavigationEnd || event instanceof NavigationCancel || event instanceof NavigationError) {
				  this.isLoading = false;
				}
			  });
	}

	openInNewTab(url: string) {
		window.open(url, '_blank');
	  }

	firstIonSplitPane = true;
	async ngOnInit() {
		console.log("xxxxxx this.appPagesService.appPages",this.appPagesService.appPages)
		await this.storage.create();
		console.log("a")
		

		this.global.splitPane.subscribe(x=>{
			if ( !this.firstIonSplitPane )
				this.ionSplitPane.disabled = !this.ionSplitPane.disabled;
			this.firstIonSplitPane = false;
		})
	}

	logout() {
		console.log("logout")
		this.auth.logout()
	}

	openFullScreenPage() {
		this.router.navigate(['/fullscreen']);
	  }*/

	private currentUrl: string = ""

	constructor(private platform: Platform,
		public global: GlobalService,
		public parametresService: ParametresService,
		public appPagesService: AppPagesService, private router: Router,
		public cacheService: CacheService,
		private authService: AuthenticationService) {

		this.router.events.pipe(
			filter(event => event instanceof NavigationEnd)
		).subscribe((event: NavigationEnd) => {
			this.currentUrl = event.url;
		});


		this.initializeApp();
	}

	isSplitPaneEnabled(): boolean {
		//console.log("isSplitPaneEnabled",this.global.appType)
		return !(
			this.currentUrl.includes('/login') ||
			this.currentUrl.includes('/reset-password') ||
			this.currentUrl.includes('/reset-password-confirm') 
		);
	}

	initializeApp() {
		console.log("debug7 initializeAppinitializeAppinitializeApp", window.location)
		if  ( String(window.location).includes('reset-password') )
			return
		this.platform.ready().then(() => {
			console.log("debug7 this.router.url",this.router.url)
			if ( !this.router.url.includes('reset-password') &&
				!this.router.url.includes('reset-password-confirm')) {
				this.authService.autoLogin();
			}
		});
	}

	logout() {
		console.log("logout")
		this.authService.logout()
	}

	ngOnInit() {
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				console.log('debug3 Active URL:', this.router.url, event.urlAfterRedirects);
				this.cacheService.addRoute(event.urlAfterRedirects);
			}
		});
	}


}
