import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, first } from 'rxjs';
import { GlobalService } from './global.service';
import { StrStructureService } from './str-structure.service';
import { DrtAccesService } from './drt-acces.service';
import { DrtAccesRoleService } from './drt-acces-role.service';
import { DrtRoleService } from './drt-role.service';

interface DrtAccesCurrentUser {
	id: number;
	idStructure: number | null;
	idUtilisateur: number;
	idRole: number;
}

@Injectable({
	providedIn: 'root'
})
export class DataLoader3Service {
	worker: Worker;

	private nbloaded = 0;

	private done = false;
	public dataLoaded3: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	ngOnInit() {
		console.log("DataLoader3Service ngoninit")
	}

	getDataLoadedObservable() {
		return this.dataLoaded3.asObservable();
	}

	tables = [
		"str_structures",
	]

	loadData3() {
		const sub4 = this.strStructureService.dataEventLoaded.subscribe((t) => { console.log("dataEventLoaded subscribe", t); this.nbloaded++; this.checkLoaded(); })

		//on charge une simulation


		//
		/*this.sub2 = this.etqEtiquettesService.dataEventLoaded.subscribe((t)=>{console.log("2");this.nbloaded++;this.checkLoaded();this.sub2.unsubscribe()})
		this.sub3 = this.trcGroupeTraceService.dataEventLoaded.subscribe((t)=>{console.log("3");this.nbloaded++;this.checkLoaded();this.sub3.unsubscribe()})
*/

		/*setTimeout(() => {
			for (let i = 0; i < 10000; i++) {
				this.data.push({ id: i, name: `Item ${i}` });
			}
			// Notifier que les données sont chargées
			this.nbloaded++;
			this.checkLoaded();
			console.log("a nbloaded",this.nbloaded)
		}, 3000);*/ // Temps de chargement simulé de 3 secondes

		//on charge insfonction

		/*this.globalService.loaded.subscribe(t => {
			console.log("this.globalService.loaded.this.globalService.loaded.this.globalService.loaded.this.globalService.loaded.")
			if(  this.done )
				return;
			if (this.tables.includes(t)) {
				console.log("this.loadData2", t)				
				this.nbloaded++;
				this.checkLoaded();
				console.log("this.loadData2 b nbloaded", this.nbloaded, t)
			}
		})*/
	}

	constructor(private http: HttpClient,
		public globalService: GlobalService,
		public strStructureService: StrStructureService,
		public drtAccesRoleService: DrtAccesRoleService,
		public drtAccesService: DrtAccesService,
		public drtRoleService: DrtRoleService
	) {
		this.strStructureService.callbackendloaded = this.endLoaded.bind(this, this.strStructureService)

		this.strStructureService.reload()
	}

	endLoaded(baseservice) {
		const startTime = performance.now();
		let endTime
		let elapsedTime

		console.log("this.strStructureService 3", this.strStructureService.entries)

		console.log("endLoaded", baseservice.basetable)
		this.nbloaded++;
		this.checkLoaded();

		endTime = performance.now();    // Get end time in high-resolution time
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time ${baseservice.basetable} endloaded 1: ${elapsedTime.toFixed(2)} ms`);




		endTime = performance.now();    // Get end time in high-resolution time
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time ${baseservice.basetable} endloaded end: ${elapsedTime.toFixed(2)} ms`);
	}

	/*runReorgRights(table) {
		if (typeof Worker !== 'undefined') {
			// Create a new worker using the generated worker file
			this.worker = new Worker(new URL('../worker-reorgrights.worker', import.meta.url));

			// Send data to the worker
			this.worker.postMessage(this.drtAccesService.entries,this.drtAccesRoleService.entries); // for example, to calculate the 40th Fibonacci number

			// Receive message (result) from the worker
			this.worker.onmessage = ({ data }) => {
				console.log(`Result from worker: ${data}`);
			};

			// Handle errors in the worker
			this.worker.onerror = (error) => {
				console.error('Worker error:', error);
			};
		} else {
			console.error('Web Workers are not supported in this environment.');
		}
	}*/


	reorgRights(): Promise<void> {
		return new Promise((resolve) => {
			setTimeout(async () => {
				// Simulate some async task inside the timeout


				//return new Promise((resolve) => {
				const startTime = performance.now();
				let endTime
				let elapsedTime

				console.log("this.strStructureService 3", this.strStructureService.entries)


				endTime = performance.now();    // Get end time in high-resolution time
				elapsedTime = endTime - startTime; // Calculate the time difference		
				console.log(`Elapsed time reorgRights -1: ${elapsedTime.toFixed(2)} ms`,
					this.drtAccesRoleService.entries.filter(x => x.idAcces == 3)
				);

				//on compresse ici tous les droits de l'utilisateur courant
				this.globalService.currentIdUtilisateur = this.globalService.getIdUtilisateurLoggedIn() //pour test
				this.globalService.currentRights = this.drtAccesService.entries
					.filter(x => x.idUtilisateur == this.globalService.currentIdUtilisateur)
					.reduce((acc, acces) => {
						const roles = this.drtAccesRoleService.entries.filter(role => role.idAcces === acces.id);
						roles.forEach(role => {
							acc.push({
								id: acces.id,
								idStructure: acces.idStructure,
								idUtilisateur: acces.idUtilisateur,
								idRole: role.idRole,
							});
						});
						return acc;
					}, [] as DrtAccesCurrentUser[]);

				endTime = performance.now();    // Get end time in high-resolution time
				elapsedTime = endTime - startTime; // Calculate the time difference
				console.log(`Elapsed time reorgRights 00: ${elapsedTime.toFixed(2)} mss`, this.globalService.currentRights);

				let cacheKey = this.globalService.cacheService.createCacheKey2("accesrole")
				let accessRoleCache = await this.globalService.cacheService.get(cacheKey)
				accessRoleCache = undefined
				if (accessRoleCache == undefined) {
					//on réorganize le droit
					this.drtAccesRoleService.entries.forEach(x => {
						x.roledata = this.drtRoleService.entries.find(r => String(r.id) == String(x.idRole))
						x.libelle = x.roledata.libelle
						x.code = x.roledata.code
					})
					this.globalService.cacheService.set(cacheKey, this.drtAccesRoleService.entries);
				}

				endTime = performance.now();    // Get end time in high-resolution time
				elapsedTime = endTime - startTime; // Calculate the time difference
				console.log(`Elapsed time reorgRights 1: ${elapsedTime.toFixed(2)} ms`);

				cacheKey = this.globalService.cacheService.createCacheKey2("acces")
				let accessCache = await this.globalService.cacheService.get(cacheKey)
				accessCache = undefined
				console.log("reorg acces 2", this.drtAccesService.entries)
				if (accessCache == undefined) {
					let error = false
					this.drtAccesService.entries.forEach(x => {
						x.structuredata = this.strStructureService.findEntry(x.idStructure)
						x.structure = "Global"
						if (x.idStructure && !x.structuredata && !error) {
							error = true
							console.error("reorg x.idStructure null", x, this.strStructureService.entries)
						}
						if (x.structuredata)
							x.structure = x.structuredata.nom
					})
					endTime = performance.now();    // Get end time in high-resolution time
					elapsedTime = endTime - startTime; // Calculate the time difference
					console.log(`Elapsed time reorgRights 2: ${elapsedTime.toFixed(2)} ms`);

					this.drtAccesService.entries.map(x => x.roles = this.drtAccesRoleService.entries.filter(r => r.idAcces == x.id))
					//console.log("reorg acces 3",acces,acces.filter(x=>x.idUtilisateur==3),this.drtRoleService.entries)
					this.globalService.cacheService.set(cacheKey, this.drtAccesService.entries);
				}

				endTime = performance.now();    // Get end time in high-resolution time
				elapsedTime = endTime - startTime; // Calculate the time difference
				console.log(`Elapsed time reorgRights end: ${elapsedTime.toFixed(2)} ms`, this.drtAccesService.entries, this.globalService.currentRights, this.strStructureService.entries);
				
				console.log('Function inside timeout is finished');

				resolve(); // Resolves the promise when the task is done
			}, 1000);  // Simulate a 3-second delay
		});
	}

	wait() {
		return
		setTimeout(() => {
			this.nbloaded++; this.checkLoaded();
		}, 100)
	}

	async waitForTrue(): Promise<void> {
		// Create an observable from the BehaviorSubject
		const observable$ = this.dataLoaded3.asObservable();

		// Use the first operator to wait until the value is true
		const trueValue = await observable$.pipe(
			// Use the first operator to emit only when the value becomes true
			first(value => value === true)
		).toPromise();

		// At this point, trueValue is true
		console.log('The BehaviorSubject is now true');
	}

	checkLoaded(): void {
		console.log("checkLoaded", this.nbloaded)
		if (this.nbloaded == this.tables.length) {

			console.log("dataloaded2 next 1")
			this.dataLoaded3.next(true);
			console.log("dataloaded2 next 2")
			this.done = true;
		}
	}

	ngOnDestroy() {
		// Terminate the worker when it's no longer needed
		if (this.worker) {
			this.worker.terminate();
		}
	}
}
