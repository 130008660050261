import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModalComponent } from './date-picker-modal.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [DatePickerModalComponent],
  imports: [
    CommonModule,IonicModule,FormsModule
  ],
  exports:[DatePickerModalComponent]
})
export class DatePickerModalModule { }
