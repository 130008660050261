import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { ServerDBApiService } from 'src/app/Services/server-dbapi.service';
import { GedFichierService } from 'src/app/Services/ged-fichier.service';
import { GedRepertoireService } from 'src/app/Services/ged-repertoire.service';

@Component({
	selector: 'app-upload-dialog',
	templateUrl: './upload-dialog.component.html',
	styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent {
	public files: File[] = [];
	selectedFolder: any;
	isVisibiliteFederation: false
	isVisibiliteLigue: false
	isVisibiliteZoneInterdept: false
	isVisibiliteDepartement: false
	isVisibiliteClub: false
	isVisibiliteEspaceLicencie: false
	isVisibiliteHn: false
	success = ""
	error = ""
	isLoading = false

	public droits = [
		{ key: 'Bureau national', value: 'isVisibiliteFederation', checked: true },
		{ key: 'Région académique', value: 'isVisibiliteLigue', checked: true },
		{ key: 'Section académique', value: 'isVisibiliteDepartement', checked: true },
		{ key: 'Section locale', value: 'isVisibiliteClub', checked: true },
		{ key: 'Adhérents', value: 'isVisibiliteEspaceLicencie', checked: true }
	  ];


	  handleCheckboxChange(event, droit) {
		droit.checked = event.target.checked;
	  }

	constructor(private http: HttpClient,
		public apiService: ServerDBApiService,
		public gedFichierService: GedFichierService,
		public gedRepertoireService: GedRepertoireService,
		private modalController: ModalController) { }

	getFileIcon(f: File): string {
		const extension = f.name.split('.').pop().toLocaleLowerCase();
		switch (extension) {
			case 'pdf': return 'fa-solid fa-file-pdf';
			case 'png':
			case 'jpg':
			case 'jpeg': return 'fa-solid fa-file-image';
			case 'mp3': return 'fa-solid fa-file-audio';
			case 'mp4': return 'fa-solid fa-file-video';
			case 'zip': return 'fa-solid fa-file-zipper';
			case 'odt': 
			case 'docx': 
			case 'doc': return 'fa-solid fa-word';
			case 'xlsx': 
			case 'xls': return 'fa-solid fa-excel';
			default: return 'fa-solid fa-file';
		}
	}

	removeFile(index: number): void {
		this.files.splice(index, 1);
	}

	onFileChange(event: any): void {
		this.files = Array.from(event.target.files);
		/*const selectedFiles = event.target.files;
		for (let i = 0; i < selectedFiles.length; i++) {
			const file = selectedFiles[i]
			if (file) {
				this.files.push(file);
			}
		}*/
	}

	onDragOver(event: DragEvent): void {
		console.log("onDragOver")
		event.preventDefault();
	}

	onDrop(event: DragEvent): void {
		console.log("onDrop")
		event.preventDefault();
		if (event.dataTransfer && event.dataTransfer.files.length > 0) {
			for (let i = 0; i < event.dataTransfer.files.length; i++) {
				this.files.push(event.dataTransfer.files[i]);
				console.log("this.files", this.files)
			}
		}
	}

	uploadFiles(): void {
		this.isLoading = true
		this.error = "";
		this.success = "";
		const formData = new FormData();
		this.files.forEach(file => {
			console.log("file", file)
			formData.append('file', file, file.name);
		});
		formData.append('id',this.selectedFolder.id)
		formData.append('idStructureProprietaire',"1")
		formData.append( 'visibilite', JSON.stringify(this.droits.filter(d=>d.checked==true)) )
		this.files = []

		this.apiService.uploadFiles(formData).subscribe((response:any) => {
			console.log(response);
			this.isLoading = false
			if ( response.success ) {

				this.apiService.handleTablesToReset(response)
				this.gedFichierService.callbackendloaded = this.gedFichierServiceEndLoaded.bind(this, this.gedFichierService)
				this.gedFichierService.reload()

				console.log("a",response.success);
				this.success = response.success
				setTimeout(()=>{
					this.close();
				},3000);
			}
			if ( response.error ) {
				console.log("b",response.error);
				this.error = response.error
			}
		});
	}

	gedFichierServiceEndLoaded(table, baseservice) {
		console.log("gedFichierServiceEndLoaded", table)
		this.gedRepertoireService.refresh()
	}

	close(): void {
		this.modalController.dismiss();
	}
}
