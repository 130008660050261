import { Component } from '@angular/core';
import * as moment from 'moment';
import { GlobalService } from 'src/app/Services/global.service';
import { PdfTemplateService } from 'src/app/Services/pdf-template.service';
import { PerPersonneService } from 'src/app/Services/per-personne.service';

@Component({
	selector: 'app-pdf-generator',
	templateUrl: './pdf-generator.component.html',
})
export class PdfGeneratorComponent {
	constructor(private pdfService: PdfTemplateService,
		public global: GlobalService,
		public perPersonneService: PerPersonneService
	) { }

	//pdfGenerator
	async generateBonAdhesion(personne,total) {		
		const pdfUrl = 'assets/bon_adhesion.pdf'; // Path to your PDF template
		const replacements = {
			"signature1": "Fabrice Orel, trésorier national du SNPTES",
			"codeAdherent": "Code adhérent : "+personne.codeAdherent,
			"snptesadresse": "SNPTES\n18 rue Chevreul\n94600 CHOISY LE ROI",
			"adherentnomadresse": this.perPersonneService.getFullAddress2(personne),
			"somme": this.global.formatFloatWithComma( total )+" €",
			"signature2": "Le Trésorier du SNPTES\nFabrice Orel"
		};

		const pdfDoc = await this.pdfService.loadPdf(pdfUrl);
		console.log("pdfDoc")
		const modifiedPdfBytes = await this.pdfService.modifyPdf(pdfDoc, replacements);

		const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}

	async generateRecu(personne,recuFiscalEntry) {		
		console.log("generateRecu",personne,recuFiscalEntry)
		const pdfUrl = 'assets/attestation_fiscale.pdf'; // Path to your PDF template
		const replacements = {
			"signature1": "Fabrice Orel, trésorier national du SNPTES",
			"descAttestation": "Attestation de versement de cotisation syndicale de l'année fiscale "+moment(recuFiscalEntry.dateOperation).year()+" N°"+recuFiscalEntry.nomFichier,
			"codeAdherent": "Code adhérent : "+personne.codeAdherent,
			"snptesadresse": "SNPTES\n18 rue Chevreul\n94600 CHOISY LE ROI",
			"adherentnomadresse": this.perPersonneService.getFullAddress2(personne),
			"somme": this.global.formatFloatWithComma( recuFiscalEntry.total/100 )+" €",
			"signature2": "Le Trésorier du SNPTES\nFabrice Orel"
		/*	'codeAdherent': adherent.codeAdherent,
			'saison': saison,
			'nom': adherent.nom,
			'prenom': adherent.prenom,
			'fullname': adherent.civilite+" "+adherent.nom+" "+adherent.prenom,
			*/
		};

		const pdfDoc = await this.pdfService.loadPdf(pdfUrl);
		console.log("pdfDoc")
		const modifiedPdfBytes = await this.pdfService.modifyPdf(pdfDoc, replacements);

		/*console.log("pdfDoc1")
		// Create a Blob and generate URL to display or download the PDF
		const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
		console.log("pdfDoc2")
		const url = URL.createObjectURL(blob);
		console.log("pdfDoc3")
		window.open(url); // This will open the modified PDF in a new tab
		console.log("pdfDoc4", url)*/

		const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}

	async generateCarteAdherent(adherent: any,saison, pdtCmd) {		
		console.log("generatePdf",adherent)
		const pdfUrl = 'assets/carte-adherent.pdf'; // Path to your PDF template
		const replacements = {
			'codeAdherent': adherent.codeAdherent,
			'saison': saison,
			'nom': adherent.nom,
			'prenom': adherent.prenom,
			'fullname': adherent.civilite+" "+adherent.nom+" "+adherent.prenom,
//			date: '2024-07-05',
		};

		const pdfDoc = await this.pdfService.loadPdf(pdfUrl);
		console.log("pdfDoc")
		const modifiedPdfBytes = await this.pdfService.modifyPdf(pdfDoc, replacements);

		/*console.log("pdfDoc1")
		// Create a Blob and generate URL to display or download the PDF
		const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
		console.log("pdfDoc2")
		const url = URL.createObjectURL(blob);
		console.log("pdfDoc3")
		window.open(url); // This will open the modified PDF in a new tab
		console.log("pdfDoc4", url)*/

		const blob = new Blob([modifiedPdfBytes], { type: 'application/pdf' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.target = '_blank';
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);
		window.URL.revokeObjectURL(url);
	}
}
