export interface Iged_repertoire {
	id: Number;
	isVisibiliteFederation: any;
	isVisibiliteLigue: any;
	isVisibiliteZoneInterdept: any;
	isVisibiliteDepartement: any;
	isVisibiliteClub: any;
	isVisibiliteEspaceLicencie: any;
	isVisibiliteHn: any;
	nom: String;
	idStructureProprietaire: Number;
	idParent: Number;
	isAffichableGed: String;
}

export class ged_repertoire implements Iged_repertoire {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	isVisibiliteFederation: boolean;
	isVisibiliteLigue: boolean;
	isVisibiliteZoneInterdept: boolean;
	isVisibiliteDepartement: boolean;
	isVisibiliteClub: boolean;
	isVisibiliteEspaceLicencie: boolean;
	isVisibiliteHn: boolean;
	nom: String;
	idStructureProprietaire: Number;
	idParent: Number;
	isAffichableGed: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.isVisibiliteFederation = false;
		this.isVisibiliteLigue = false;
		this.isVisibiliteZoneInterdept = false;
		this.isVisibiliteDepartement = false;
		this.isVisibiliteClub = false;
		this.isVisibiliteEspaceLicencie = false;
		this.isVisibiliteHn = false;
		this.nom = "";
		this.idStructureProprietaire = 0;
		this.idParent = 0;
		this.isAffichableGed = "";
	}

	public fromObj(entry: Iged_repertoire) {
		this.id = entry.id;
		this.isVisibiliteFederation = entry.isVisibiliteFederation.data[0];
		this.isVisibiliteLigue = entry.isVisibiliteLigue.data[0];
		this.isVisibiliteZoneInterdept = entry.isVisibiliteZoneInterdept.data[0];
		this.isVisibiliteDepartement = entry.isVisibiliteDepartement.data[0];
		this.isVisibiliteClub = entry.isVisibiliteClub.data[0];
		this.isVisibiliteEspaceLicencie = entry.isVisibiliteEspaceLicencie.data[0];
		this.isVisibiliteHn = entry.isVisibiliteHn.data[0];
		this.nom = entry.nom;
		this.idStructureProprietaire = entry.idStructureProprietaire;
		this.idParent = entry.idParent;
		this.isAffichableGed = entry.isAffichableGed;
		return this;
	}

}
