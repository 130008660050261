import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { pdt_commande } from '../types/type_pdt_commande';
import * as moment from 'moment';
import { PerPersonneService } from './per-personne.service';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';
import { CfgSaisonService } from './cfg-saison.service';

@Injectable({
  providedIn: 'root'
})
export class PdtCommandeService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public perPersonneService: PerPersonneService,
		public override injector: Injector,
		public cfgSaisonService: CfgSaisonService
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("pdt_commande", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	loadFromPersonne(idPersonne) {
		this.loadid = idPersonne
		super.init("pdt_commande", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
	}

	loadAll() {
		this.loadid = null
		super.init("pdt_commande", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
	}

	getCommandeLibelle(idCommande) {
		return this.entries.find(commande=>commande.id==idCommande)?.libelle
	}

	findCommande(id) {
		return this.entries.find(entry=>entry.id==id)?.libelle
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new pdt_commande()).fromObj(dbentry)
//		res.libelle = "Commande " + res.numCommande
//		res.libelle += " | 
		res.libelle = "Saison "+this.cfgSaisonService.entries.find(saison=>saison.id==res.idSaison)?.libelle
		res.libelle += " | " + res.numCommande

		//this.cfgSaisonService.entries.find(saison=>saison.id==commande.idSaison)?.libelle
		return res
	}

	override getDbFields() {
		return [{field:"id",nullable:false},
		{field:"dateCommande",nullable:true},
		{field:"dateReglement",nullable:true},
		{field:"etat",nullable:false},
		{field:"numCommande",nullable:true},
		{field:"annulable",nullable:false},
		{field:"idSaison",nullable:false},
		{field:"idStructure",nullable:true},
		{field:"idPersonne",nullable:true},
		{field:"montantTotal",nullable:false},
		{field:"nbProduits",nullable:false},
		{field:"numFacture",nullable:true}]
	}

	async Add(idSaison,idStructure,idPersonne,montantTotal) {
		console.log("pdt-commande add")
		const res = new pdt_commande()

		const personne = this.perPersonneService.getFromId(idPersonne)
		if ( !personne) {
			console.error("personne introuvable",idPersonne)
		}
		console.log("pdt-commande Add",personne,idPersonne)
		const now = moment().format("YYYY-MM-DD")
		const numCommande = personne.codeAdherent+"_"+moment().format("YYYYMMDDHHmmss")

		res.db = 1
		res.dateCommande = now;
		res.dateReglement = "NULL";
		res.etat = "EnAttente";
		res.numCommande	= numCommande;
		res.annulable = 0
		res.idSaison = idSaison
		res.idStructure	= idStructure
		res.idPersonne = idPersonne
		res.montantTotal = montantTotal*100
		res.nbProduits = 1
		res.numFacture = "NULL"


		this.entries.push( res )
		console.log("pdt-commande before save");
		await this.save( res );
		//res.id = Number(id);

		console.log("end pdt-commande add, after save")
		return res;
	}
}
