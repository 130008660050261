import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { fin_prelevement_virement } from '../types/type_fin_prelevement_virement';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';
import { PdtCommandeService } from './pdt-commande.service';

@Injectable({
  providedIn: 'root'
})
export class FinPrelevementVirementService extends BaseService {

	override authneeded: boolean = false

	public etatOptions = [{key:"APrelever",value:"A prélever"},
		{key:"ARegler",value:"A régler"},
		{key:"Paye",value:"Payé"},
		{key:"Preleve",value:"Prélevé"},
		{key:"Refuse",value:"Refusé"},
	]

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector,
		public pdtCommandeService: PdtCommandeService
	) {
		super(global, appPagesService, router, http, apiService, injector);
		this.basetable = "fin_prelevement_virement"
		//super.init("fin_prelevement_virement", this.loadEntry.bind(this), [], this.getId.bind(this))
		//super.reload()
	}

	override getEntriesToDisplay(idvalue = null, search = null, sortedField = null) {
		
		//getEntriesToDisplay(idvalue = null, search = null, sortedField = null, idfieldname = "idcustomer")

		let tmp = super.getEntriesToDisplay(idvalue, search, sortedField)//, "idPersonne")
		
		/*tmp = tmp.filter(x=>!(x.company == "" && x.lastname=="" && x.firstname=="") )*/
		if (sortedField) {

			if (sortedField.sort == 1)
				return tmp.sort((a, b) => a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a, b) => b[sortedField.field].localeCompare(a[sortedField.field]))
		}
		
		return tmp
	}

	loadFromListe() {
		console.log("start loadFromListe fin_prelevement_virement")
		this.loadid = ""
		super.init("fin_prelevement_virement", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
		console.log("end loadFromListe fin_prelevement_virement")
	}

	loadFromPersonne(idPersonne) {
		console.log("start loadFromPersonne fin_prelevement_virement")
		this.loadid = idPersonne
		super.init("fin_prelevement_virement", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
		console.log("end loadFromPersonne fin_prelevement_virement")
	}

	getEtats() {
		return [{key:"APrelever",value:"A prélever"},
			{key:"Preleve",value:"Prélevé"},
			{key:"Refuse",value:"Refusé"}
		]
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new fin_prelevement_virement()).fromObj(dbentry)
		return res

	}

	canDeletePrelevement( prelevementEntry ) {
		const now = moment()
		let daysBetween = moment(prelevementEntry.dateEcheance).diff(now, 'days');
		if ( prelevementEntry.etat == "APrelever" || daysBetween>10)
			return true;
		return false;
	}

	canUpdatePrelevement( prelevementEntry ) {
		const now = moment()
		let daysBetween = moment(prelevementEntry.dateEcheance).diff(now, 'days');
		if ( prelevementEntry.etat == "APrelever" || daysBetween>10)
			return true;
		return false;
	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished fin_prelevement_virement",this.entries)
	}

	override getDbFields() {
		return [{field:"id",nullable:false},
			{field:"date",nullable:true},
			{field:"etat",nullable:false},
			{field:"montant",nullable:false},
			{field:"type",nullable:false},
			{field:"idStructure",nullable:true},
			{field:"idCommande",nullable:true},
			{field:"idPersonne",nullable:true},
			{field:"dateEcheance",nullable:true}]
	}
	
	addPrelevement(entry: any): void {
		console.log("addPrelevement", entry);
		this.insert(
			moment().format("YYYY-MM-DD"),
			entry.etat,
			entry.montant*100,
			"Prelevement",
			entry.idCommande,
			entry.idPersonne,
			entry.dateEcheance
		)
	}

	insert(date, etat, montant, type, idCommande, idPersonne, dateEcheance) {
		const idStructure = this.pdtCommandeService.entries.find(commande=>commande.id==idCommande)?.idStructure

		const data: any = {
			date: date,
			etat: etat,
			montant: montant,
			type: type,
			idStructure: idStructure,
			idCommande: idCommande,
			idPersonne: idPersonne,
			dateEcheance: dateEcheance	
		};


		this.getDbFields().forEach(field => {
			if (field.nullable && (!data[field.field] || (data[field.field] && data[field.field] == ""))) {
				delete data[field.field]// = "NULL"
			}
		});

		console.log("✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ insert", data)

		this.apiService.insertPrelevement(data).subscribe({
			next: (response) => {
				console.log('Record inserted successfully:', response);
				this.apiService.handleTablesToReset2(response,idPersonne)

				this.loadFromPersonne(idPersonne)
			},
			error: (error) => { console.error('Error inserting record:', error); }
		});
	}

	async Add(
		date,
		etat,
		montant,
		type,
		idStructure,
		idCommande,
		idPersonne,
		dateEcheance) {

		const res = new fin_prelevement_virement()

		const now = moment().format("YYYY-MM-DD")
		
		res.db = 1
		res.date = date
		res.etat = etat
		res.montant = montant
		res.type = type
		res.idStructure = idStructure
		res.idCommande = idCommande
		res.idPersonne = idPersonne
		res.dateEcheance = dateEcheance
		
		this.entries.push( res )
		const id = await this.save( res );
		res.id = Number(id);
		return res;
	}

}
