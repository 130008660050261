import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IonContent, NavController } from '@ionic/angular';
import { BaseService } from 'src/app/Services/base.service';
import { GlobalService } from 'src/app/Services/global.service';
import { DatePickerComponent } from '../date-picker/date-picker.component';

@Component({
	selector: 'app-add-entry-dialog',
	templateUrl: './add-entry-dialog.component.html',
	styleUrls: ['./add-entry-dialog.component.scss'],
})
export class AddEntryDialogComponent implements OnInit {
	@Input() fields: any[];
	
	@Output() onDismiss = new EventEmitter<any>();

	formValues: any = {};

	errors = [];
	constructor(private modalController: ModalController) { }

	ngOnInit() {

		this.fields.forEach(field => {
			this.formValues[field.field] = field.default || field.value || '';

			if ( field.options && field.options.length == 1 ) {
				this.formValues[field.field] = field.options[0].key
			}

		});0
	}

	dismiss(result?: any) {
		this.modalController.dismiss(result);
	}

	addEntry() {
		this.errors = []
		console.log("addEntry")
		let error = false;
		this.fields.forEach(field => {
			const value = this.formValues[field.field]
			field.error = false;
			if ( field.mandatory && value == "" ) {
				error = true;
				field.error = true;
				this.errors.push( field.label + " obligatoire" )
			}
		})

		console.log("addEntry error",error,this.fields)

		if ( error )
			return;

		this.dismiss(this.formValues);
	}

	private datePickerModal
	async openDatePickerModal() {
		this.datePickerModal = await this.modalController.create({
			component: DatePickerComponent,
			componentProps: {
				modalController: this.modalController, // Passer une référence au ModalController
			},
		});

		this.datePickerModal.onDidDismiss().then((data) => {
			if (data && data.data) {
				// Réafficher la fenêtre modale parente après la sélection de la date
				this.presentModal();
			}
		});

		return await this.datePickerModal.present();
	}

	private thisModal;
	async presentModal() {
		console.log("openAddEntryDialog presentModal this.thisModal.onDidDismiss")
		this.thisModal = await this.modalController.create({
			component: AddEntryDialogComponent, // Changer pour le bon composant
			componentProps: { fields: this.fields }
			// Autres options de modal
		});
		this.thisModal.onDidDismiss().then((dataReturned) => {
			console.log("openAddEntryDialog this.thisModal.onDidDismiss")
			if (dataReturned !== null) {
				
				this.onDismiss.emit(dataReturned.data);
			}
		});

		return await this.thisModal.present();
	}

	/*openDatePickerEvent(event) {
	  this.thisModal.dismiss();
	}*/

	onDateSelected(field, selectedDate: string) {
		this.formValues[field.field] = selectedDate
		console.log('Date sélectionnée :', selectedDate, this.formValues);

		// Réagissez ici à la sélection de la date
	}

}
