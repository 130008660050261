import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { str_typestructure } from '../types/type_str_typestructure';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StrTypeStructureService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("str_typestructure", this.loadEntry.bind(this), [], this.getId.bind(this))
    console.log("constructor str_typestructure")
  }

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
    debugger
		const res = (new str_typestructure()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished str_typestructure", this.entries)
	}

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "code", nullable: false },
		{ field: "libelle", nullable: false },
		{ field: "ordre", nullable: true },
		]
	}

	async Add(
		code,
		libelle,
		ordre
	) {

		const res = new str_typestructure()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.code = code
		res.libelle = libelle
		res.ordre = ordre

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}
}