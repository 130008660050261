import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { SectionLocaleComponent } from './section-locale.component';

@NgModule({
  declarations: [SectionLocaleComponent],
  imports: [
    CommonModule,IonicModule,FormsModule
  ],
  exports:[SectionLocaleComponent]
})
export class SectionLocaleModule { }
