export interface Iadr_codepostalville {
	id: Number;
	codePostal: String;
	ville: String;
	codeInsee: String;
	latitude: String;
	longitude: String;
}

export class adr_codepostalville implements Iadr_codepostalville {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	codePostal: String;
	ville: String;
	codeInsee: String;
	latitude: String;
	longitude: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.codePostal = "";
		this.ville = "";
		this.codeInsee = "";
		this.latitude = "";
		this.longitude = "";
	}

	public fromObj(entry: Iadr_codepostalville) {
		this.id = entry.id;
		this.codePostal = entry.codePostal;
		this.ville = entry.ville;
		this.codeInsee = entry.codeInsee;
		this.latitude = entry.latitude;
		this.longitude = entry.longitude;
		return this;
	}

}
