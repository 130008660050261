import { Injectable, OnInit } from '@angular/core';
import { ServerDBApiService } from './server-dbapi.service';

@Injectable({
	providedIn: 'root'
})
export class InfosComplementairesService /*implements OnInit*/ {

	public allQuestions: any[] = [];
	public questions: any[] = [];
	public questionsJson = "";
	public history: any[] = [];
	public answers: any[] = [];
	public first = true

	constructor(public apiService: ServerDBApiService) {
		this.loadQuestions();
	}

	/*ngOnInit() {
		
	}*/


	sort(a, b) {
		a = a.trim()
		b = b.trim()
		const givenString = "Autre"
		if (a === givenString && b !== givenString) {
			return 1; // Move the given string to the end
		} else if (a !== givenString && b === givenString) {
			return -1; // Move other strings before the given string
		} else {
			// Use localCompare for normal sorting
			return a.localeCompare(b);
		}
	}

	async loadQuestions(parentId = null) {

		console.log("start loadQuestions")
		this.questions = []
		console.log("loadQuestions")
		if (this.first = true) {
			const order = await this.apiService.getQuestionsOrders()
			const orderLookup = order.reduce((acc, item) => {
				acc[item.type] = item.sortorder;
				return acc;
			}, {});

			console.log("this.questions ordre", order)
			console.log("this.questions orderLookup", orderLookup)
			const data = await this.apiService.getQuestions()
			this.allQuestions = data
			this.first = false;

			this.history = []
			this.questions = [...this.filterUniqueQuestions(this.allQuestions.filter(q => q.idParametrePere === null))]
			this.questions.sort((a, b) => {
				const orderA = orderLookup[a.type] !== undefined ? orderLookup[a.type] : Infinity;
				const orderB = orderLookup[b.type] !== undefined ? orderLookup[b.type] : Infinity;
				return orderA - orderB;
			});
			console.log("this.questions 1", this.questions)
			this.answers = [];


		} else {
			this.history = []
			this.questions = this.filterUniqueQuestions(this.allQuestions.filter(q => q.idParametrePere === null));
			console.log("this.questions 2", this.questions)
			this.answers = [];

		}

		for (let i=0;i<this.questions.length;i++) {
			let q = this.questions[i]
			q.level = 0
			q.reponseText=""
			q.reponseText2="a"
			q.reponseText3="a"
		}

		this.questionsJson = JSON.stringify(this.questions)
		console.log("end loadQuestions 3")

		/*if (!this.selectedQuestion) {
			this.answers = this.allQuestions.filter(q => q.idParametrePere === parentId);
		} else {
			this.subQuestions = this.allQuestions.filter(q => q.idParametrePere === parentId);
		}*/
	}

	filterUniqueQuestions(questions) {
		const uniqueQuestions = [];
		const seenTypes = new Set();

		questions.forEach(question => {
			if (!seenTypes.has(question.type)) {
				seenTypes.add(question.type);
				uniqueQuestions.push(question);
			}
		});

		return uniqueQuestions;
	}
}
