import { EventEmitter, Injectable, Injector } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { of } from 'rxjs';
import { ServerDBApiService } from './server-dbapi.service';
import { AlertController } from '@ionic/angular';

/*@Injectable({
	providedIn: 'root'
})*/
export class BaseService {

	basetable: string = "unknownbasetable";
	arraytables: any[] = []
	public dataLoaded: Subject<boolean> = new Subject<boolean>();
	public dataEventLoaded: EventEmitter<any> = new EventEmitter();
	public reloadEvent = new EventEmitter<any>();
	public dataEventLoadedSubscription: Subscription;
	authneeded = true;
	callbacknewentry;
	callbackgetid;
	public callbackendloaded = null;
	private isLoadingComplete: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	//private cacheService: CacheService;

	constructor(public global: GlobalService,
		public appPagesService: AppPagesService,
		public router: Router,
		public http: HttpClient,
		public apiService: ServerDBApiService,
		public injector: Injector
	) {
		console.log("constructor", this.constructor.name)
	}

	init(basetable, callbacknewentry, arraytables: any[] = [], callbackgetid = null) { //must be called in child constructor
		this.isLoadingComplete.next(false);
		this.basetable = basetable;
		this.arraytables = arraytables;
		this.callbacknewentry = callbacknewentry;
		this.callbackgetid = callbackgetid;
		console.log("init", basetable, this.authneeded)
		/*if (this.authneeded) {
			this.global.authState.subscribe(value => {
				//this.log("BaseService this.global.authState", value)
				if (value)
					this.load(basetable, callbacknewentry, callbackgetid)
			})
		} else {*/
		//this.load(basetable, callbacknewentry, callbackgetid)
		//}

		//this.log("!!!! constructor BaseService")
	}

	tokenLoaded = false
	
	reload() {
		console.log("debug5 base service tokenLoaded",this.tokenLoaded)
		if (!this.tokenLoaded) {
			this.global.subtokenLoaded = this.global.tokenLoaded.subscribe(x => {
				if (x) {
					this.load(this.basetable, this.callbacknewentry, this.callbackgetid)
					this.tokenLoaded = true;
					this.global.subtokenLoaded.unsubscribe()
				}
			})
		} else {
			this.load(this.basetable, this.callbacknewentry, this.callbackgetid)
		}
	}

	private map: any;
	entries: any[] = [];			//all customers
	currentEntry = null;	//current customer, product etc...

	addEntry(entry: any) {
		//this.log("addEntry", entry, this.entries)
		entry.id = this.getNewId();
		entry.db = 1;
		this.entries.push(entry);
		this.saveEntriesDelay()
		return entry
	}

	getEntries() {
		return this.entries;
	}

	getEntriesToDisplay(idvalue = null, search = null, sortedField = null, idfieldname = "idcustomer") {
		console.log("debug9 base getEntriesToDisplay",this.basetable,idfieldname,idvalue,search,this.entries)

		let res = []
		if (idvalue)
			res = this.entries.filter(x => x.db != 2 && x[idfieldname] == idvalue)
		else {
			res = this.entries.filter(x => {
				let res = false
				res = (x.db != 2)
				if (!res)
					return false
				if (idvalue)
					if (x[idfieldname] != idvalue)
						return false;
				if (search) {
					const xsearch = search.toLocaleLowerCase()
					let property: keyof typeof x;
					for (property in x) {
						if (typeof x[property] == "string" && x[property].toLocaleLowerCase().includes(xsearch))
							return true;
					}
					return false;
				}
				return true
			})
		}
		return res
	}

	//get the property value of an object. 
	//The property can be customer.firstname for exampel
	static getFieldValue(obj, prop) {
		//	if ( prop.includes("lastname"))
		//		this.log("static getFieldValue",obj,prop)
		var parts = prop.split('.'),
			last = parts.pop(),
			l = parts.length,
			i = 1,
			current = parts[0];

		if (l === 0) return obj[prop];

		while ((obj = obj[current]) && i < l) {
			current = parts[i];
			i++;
		}

		if (obj) {
			return obj[last];
		}
	}

	findByProperty(propertyname, value) {
		if (!propertyname) {
			console.error("Missing findByProperty propertyname")
			return []
		}
		const entry = this.entries.find(x => BaseService.getFieldValue(x, propertyname) == value)
		//this.log("findByProperty",propertyname,value,this.entries,entry)
		return entry;
	}

	getAllByPropery(propertyname, value) {
		value = value.toLocaleLowerCase()
		if (!propertyname) {
			console.error("Missing findByProperty propertyname")
			return []
		}
		this.log("getAllByPropery", propertyname, value, this.entries)
		return this.entries.filter(x => BaseService.getFieldValue(x, propertyname).toLocaleLowerCase().includes(value)).map(x => BaseService.getFieldValue(x, propertyname))
	}

	getNewCustomId(array: any[]): number {
		let res = array
			.map((entry) => entry.id)
			.sort((entryId1, entryId2) => {
				return entryId1 > entryId2 ? -1 : 1;
			})[0] + 1
		return (
			isNaN(res) ? 0 : res
		);
	}

	getNewId(): number {
		return Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
		/*let res = this.getEntries()
			.map((entry) => entry.id)
			.sort((entryId1, entryId2) => {
				return entryId1 > entryId2 ? -1 : 1;
			})[0] + 1
		return (
			isNaN(res) ? 0 : res
		);*/
	}

	getDate(field) {
		if (!field)
			return "";
		return moment(field).format("DD/MM/YYYY")
	}

	getDateTime(field) {
		if (!field)
			return "";
		//return moment(field).format("DD/MM/YYYY")
		return moment(field).format("DD/MM/YYYY HH:mm:ss")
	}

	getTitle(entry: any = null) {
		throw new Error("must be overriden");
	}

	findEntry(id): any {
		this.currentEntry = null;
		this.currentEntry = this.entries.find(entry => entry.id == id)
		return this.currentEntry
	}

	fastFindEntry(id): any {
		return this.map.get(String(id))
	}

	openold(entry: any = null) {
		throw new Error("must be overriden");
	}

	open2(entry) {
		this.currentEntry = entry;
		this.log("open", entry,this.getSingleIcon(),this.getSingleUrl())

		this.appPagesService.addPage({
			title: this.getTitle(entry), url: this.getSingleUrl(), redirectUrl: this.getListUrl(), icon: this.getSingleIcon(), subnode: true
		});
	}

	open(entry) {
		this.currentEntry = entry;
		this.log("open", entry,this.getSingleIcon(),this.getSingleUrl())

		this.appPagesService.openPage({
			title: this.getTitle(entry), url: this.getSingleUrl(), redirectUrl: this.getListUrl(), icon: this.getSingleIcon(), subnode: true
		});
	}

	close(entry, redirect: String = "") {
		throw new Error("must be overriden");
	}

	setEntryUpdate(entry): void {
		this.log("setEntryUpdate", entry)
		entry.db = 1
		this.saveEntriesDelay();
	}

	deleteEntry(entry) {
		entry.db = 2
		this.saveEntriesDelay()
	}

	async presentAlertConfirm(entry: any) {
		const alertController = this.injector.get(AlertController)
		const alert = await alertController.create({
			header: 'Attention',
			message: 'Êtes-vous sûr de vouloir supprimer cette entrée ?',
			buttons: [
				{
					text: 'Non',
					role: 'cancel',
					cssClass: 'secondary',
					handler: () => {
						console.log('Confirm Cancel');
					},
				},
				{
					text: 'Oui',
					handler: () => {
						entry.db = 2
						this.apiService.delete(this.basetable, entry.id)
					},
				},
			],
		});

		await alert.present();
	}

	async deleteEntry2(entry) {
		this.presentAlertConfirm(entry)
		//throw new Error("deleteEntry2 must be overriden");

	}

	async updateEntry2(entry) {
		throw new Error("updateEntry2 must be overriden");

	}

	handleSave = null;
	saveEntriesDelay() {
		if (this.handleSave != null) {
			clearTimeout(this.handleSave)
		}
		this.handleSave = setTimeout(() => { this.save() }, 5000);
	}


	iconClick(page) {
		throw new Error("iconClick must be overriden");
	}

	getSingleUrl() {
		throw new Error("must be overriden");
	}

	getListUrl() {
		throw new Error("must be overriden");
	}

	getSingleIcon() {
		throw new Error("must be overriden");
	}

	getListIcon() {
		throw new Error("must be overriden");
	}

	debug: boolean = true
	log(...txt) {
		if (!this.debug)
			return
		if (this.basetable.includes("per_personne"))
			console.log(txt)
	}

	onLoadingFinished() {

	}

	format(ent) {
		ent.data = { a: "a", b: "b" }
	}

	getDbFields() {
		console.log("getDbFields")
		return Array();
	}

	////////////////////////////////////////////////////////
	private data: any[] = [];
	getDataLoadedObservable() {
		return this.dataLoaded.asObservable();
	}
	// Méthode pour charger les données
	loadData() {
		// Simulation du chargement des données
		setTimeout(() => {
			for (let i = 0; i < 10000; i++) {
				this.data.push({ id: i, name: `Item ${i}` });
			}
			// Notifier que les données sont chargées
			this.dataLoaded.next(true);
		}, 3000); // Temps de chargement simulé de 3 secondes
	}
	// Méthode pour obtenir les données
	getData() {
		return this.data;
	}
	////////////////////////////////////////////////////////

	private createCacheKey(url: string, params?: any): string {
		let key = url;
		if (params) {
			key += '?' + new URLSearchParams(params).toString();
		}
		return key;
	}

	public getParams() {
		return ""
	}

	public loadid
	async load(basetable, callbacknewentry, callbackgetid = null) {

		const startTime = performance.now();
		let endTime
		let elapsedTime

		this.entries = [];
		this.map = new Map<string, any>();
		if (!this.global.allEntries[basetable])
			this.global.allEntries[basetable] = []

		const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
		const params = new HttpParams()
			//.set('table', (this.global.tableprefix + basetable))
			.set('table', (basetable))

		this.log("💾 " + basetable + " load")

		const url = "https://restsnptes.edservices.fr/api/process.php"

		let cacheKey = this.global.cacheService.createCacheKey(url, basetable);
		let data = null

		const newTables = ["drt_acces_role","drt_acces","drt_utilisateur","trc_trace", "fin_infosbancaires", "fin_operation", "fin_comptebancaire", "cfg_parametres_licencecommandee",
			"fin_prelevement_virement", "per_personne", "ged_fichier", "ged_repertoire", "per_infoscomplementaires", "lic_licencecommandee", "str_structure", 
			"fin_recusfiscaux", "cfg_saison", "lic_categorieage", "pdt_produit", "pdt_produitcommande", "pdt_commande", "adr_adressepostale",
			"adr_codepostalville", "pdt_commandes_statadhesions" ]

		const activeTablesInCache = [ "pdt_commandes_statadhesions", "fin_prelevement_virement", "cfg_saison", "lic_categorieage", "drt_acces_role", "drt_acces", "drt_utilisateur", "str_structure", "pdt_produit", "adr_adressepostale" ]

		//console.log("debug5 basetable",basetable,newTables)
		if (newTables.includes(basetable)) {
			//console.log("debug5 basetable ok",basetable,newTables)

			console.log("debug5 🍇🍇🍇🍇🍇🍇🍇🍇🍇 " + basetable)
			cacheKey = this.global.cacheService.createCacheKey2(basetable, this.loadid);
			console.log("debug5 🍇🍇🍇🍇🍇🍇🍇🍇🍇 cacheKey " + basetable, cacheKey)
			if (this.global.cacheService.has(cacheKey) && activeTablesInCache.includes(basetable) ) {
				console.log("debug5 🍇🍇🍇🍇🍇🍇🍇🍇🍇 usecache " + basetable)
				data = await this.global.cacheService.get(cacheKey)
			}
			if ( data == undefined ) {
				data = await this.apiService.loadTable(basetable, this.loadid, this.getParams()).toPromise()
				console.log("debug5 🍇🍇🍇🍇🍇🍇🍇🍇🍇 no cache " + basetable, data.length, this.getParams())
				this.global.cacheService.set(cacheKey, data);
			}
			endTime = performance.now();    // Get end time in high-resolution time
			elapsedTime = endTime - startTime; // Calculate the time difference
			console.log("debug5 🍇🍇🍇🍇🍇🍇🍇🍇🍇 ok " + basetable, data)
			console.log(`debug5 Elapsed time ${basetable} 1: ${elapsedTime.toFixed(2)} ms`);

		} else {
			if (this.global.cacheService.has(cacheKey)) {
				console.log('Returning cached response for:', cacheKey);
				data = await this.global.cacheService.get(cacheKey)
				if (data === undefined) {
					console.log("undefined", cacheKey)
					data = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise()
					this.global.cacheService.set(cacheKey, data);
				}
				//console.log("data "+basetable,data)
			} else {
				data = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise()
				this.global.cacheService.set(cacheKey, data);
			}

		}


		//this.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx " + basetable + " load", data);
		if (data != null) {
			data.forEach(ent => {
				let dbentry = ent//JSON.parse(ent.data)
				//this.log("💾 dbentry", dbentry)

				//this.log("call callbacknewentry")
				let newentry = callbacknewentry(dbentry, basetable)
				if (callbackgetid) {
					const id = callbackgetid(newentry)
					if ( basetable == "per_personne" ) {
						//this.log("call callbacknewentry res", id, newentry)
					}
					this.map.set(id, newentry);
				}
				this.entries.push(newentry)
				this.global.allEntries[basetable].push(newentry)
			})
			this.global.allMaps[basetable] = this.map
			this.log("💾 entries " + basetable, this.entries, this.map)
		}
		//on charge les tableaux si configuré

		endTime = performance.now();    // Get end time in high-resolution time
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time ${basetable} 2: ${elapsedTime.toFixed(2)} ms`);

		for (let i = 0; i < this.arraytables.length; i++) {
			const x = this.arraytables[i]
			const params = new HttpParams()
				//.set('table', (this.global.tableprefix + x.table))
				.set('table', (x.table))
			const url = "https://restsnptes.edservices.fr/api/process.php"
			const data2 = await this.http.get<any[]>(url, { params: params, headers: headers }).toPromise()
			if (data2 != null) {
				this.log("💾 💾 💾 💾 data2 ", data2)
				data2.forEach(entarray => {
					let dbentry = entarray.data//JSON.parse(entarray.data)
					let idparent = entarray["idparent"]
					this.log("💾 dbentry2 " + x.table, dbentry, entarray)

					this.log("call callbacknewentry2")
					let newentry = callbacknewentry(dbentry, x.table)
					newentry.idparent = idparent
					this.log("call callbacknewentry2 res", newentry)
					let parententry = this.entries.find(x => x.id == idparent)
					this.log("parententry", idparent, parententry, x.prop)
					if (!parententry[x.prop])
						parententry[x.prop] = []
					parententry[x.prop].push(newentry)

					this.log("💾 dbentry2 " + x.table, dbentry)
				})
			}
		}


		endTime = performance.now();    // Get end time in high-resolution time
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time ${basetable} 3: ${elapsedTime.toFixed(2)} ms`);

		//fin du chargement des tableaux
		this.log("💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾💾 end load ", basetable, this.entries)
		setTimeout(() => {
			this.dataEventLoaded.emit(this.basetable);
			console.log("dataEventLoaded emit", this.basetable)
		}, 100)
		this.onLoadingFinished()
		console.log("aaa " + basetable)
		this.global.loaded.next(this.basetable)
		console.log("bbb " + basetable)

		endTime = performance.now();    // Get end time in high-resolution time
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time ${basetable} 4: ${elapsedTime.toFixed(2)} ms`);

		if (this.callbackendloaded) {
			this.callbackendloaded(this.basetable)
			this.callbackendloaded = null;
		}
		console.log("ccc " + basetable)

		endTime = performance.now();    // Get end time in high-resolution time
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time ${basetable} 5: ${elapsedTime.toFixed(2)} ms`);

		this.isLoadingComplete.next(true);
		console.log("debug1 isLoadingComplete true "+basetable+" loadid "+this.loadid)

		endTime = performance.now();    // Get end time in high-resolution time
		elapsedTime = endTime - startTime; // Calculate the time difference
		console.log(`Elapsed time ${basetable} end: ${elapsedTime.toFixed(2)} ms count ${this.entries.length}`);
	}

	getLoadingComplete(): Observable<boolean> {
		return this.isLoadingComplete
	  }
	  
	filterSaveData(data) {
		return data
	}

	save(entrytosave = null, forceInsertId = false) {
		let basetable = this.basetable;
		return new Promise(resolve => {
			this.log("save", basetable, this.entries, entrytosave)
			this.entries.forEach((entry) => {

				if (entrytosave != null && entry != entrytosave)
					return;

				let arrays = []

				if (entry.db != 1)
					return;
				const key = { id: entry.id }
				let method = "add"
				if (entry.id > 0 && !forceInsertId) {
					method = "update"
				}

				let data = {}
				this.getDbFields().forEach(dbfield => {
					const field = dbfield.field
					const nullable = dbfield.nullable
					data[field] = entry[field]
					if (nullable && (entry[field] == "" || entry[field] == null))
						data[field] = "NULL"
				});
				//let data = { ...entry }
				data = this.filterSaveData(data)

				this.log("savee " + basetable, entry, data)

				//on sauvegarde les tableaux automiatuqment dans une table differente
				//le contenu de chaque tableau est conservé dans une tableau différent
				//et ensuite supprimé de l'entry de base pour ne pas qu'il soit sauvegardé
				if (this.arraytables.length > 0) {
					Object.keys(data).forEach(k => {
						if (Array.isArray(data[k])) {
							this.log("data array")
							arrays.push({ prop: k, array: data[k] })
							delete (data[k])
						}
					})
					this.log("data", data)
				}

				//sauvegarde de l'entrée principal
				const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")
				const body = new HttpParams()
					.set('method', method)
					//.set('table', this.global.tableprefix + basetable)
					.set('table', basetable)
					.set('key', JSON.stringify(key))
					.set('data', JSON.stringify(data))
					.set('forceInsertId', forceInsertId)
				console.log("saveee",body)
				const url = "https://restsnptes.edservices.fr/api/process.php"
				this.http.post(url, body.toString(), { headers: headers }).subscribe(data => {
					this.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx " + basetable + " update ", body, data);
					if (data != null) {
						entry.db = 0;

						if (data["status"] == 1) {
							console.log("data status 1")
							if (data["last_insert_id"] > 0)
								entry.id = data["last_insert_id"]
							console.log("entry et index", entry, this.entries.findIndex(x => x == entry), this.entries.length)
						}
						resolve(entry.id);
					}

					//on sauvegarde les tableaux si configuré
					this.arraytables.forEach(x => {
						const tosave = { ...entry }[x.prop]
						//this.log("save " + this.global.tableprefix + x.table, tosave)
						this.log("save " + x.table, tosave)

						if (tosave) {
							tosave.forEach((linetosave) => {

								const key = { id: linetosave.id, idparent: entry.id }
								const data = linetosave
								this.log("save key", key, data)
								const body = new HttpParams()
									.set('method', "add")
									//.set('table', this.global.tableprefix + x.table)
									.set('table', x.table)
									.set('key', JSON.stringify(key))
									.set('data', JSON.stringify(data))

								const url = "https://restsnptes.edservices.fr/api/process.php"
								this.http.post(url, body.toString(), { headers: headers }).subscribe(data => {
									if (data != null) {
										linetosave.db = 0;
									}
								}, error => {
									this.log("http error", error)
								});
							})
						}
					})
				}, error => {
					this.log("http error", error)
				});

			})

			this.entries.forEach((entry) => {
				if (entry.db != 2)
					return;
				const key = { id: entry.id }

				//this.log("save " + basetable)

				const headers = new HttpHeaders().set("Content-type", "application/x-www-form-urlencoded; charset=UTF-8")

				const body = new HttpParams()
					.set('method', "delete")
					.set('table', this.global.tableprefix + basetable)
					.set('key', JSON.stringify(key))

				//this.log(basetable + " delete", body.toString())

				const url = "https://restsntpes.edservices.fr/api/process.php"
				this.http.post(url, body.toString(), { headers: headers }).subscribe(data => {
					this.log("xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx " + basetable + " delete ", data);
					if (data != null) {
						//this.log("delete ok", data);
						entry.db = 2;
					}
				}, error => {
					this.log("http error", error)
				});

			})

			this.log("save before promise")
		});
	}
}
