import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, Renderer2, ViewChild, ViewChildren } from '@angular/core';
import { IonContent, NavController } from '@ionic/angular';
import { BaseService } from 'src/app/Services/base.service';
import { GlobalService } from 'src/app/Services/global.service';
import { NgZone } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { AddEntryDialogComponent } from '../add-entry-dialog/add-entry-dialog.component';
import { Subscription } from 'rxjs';

@Component({
	selector: 'eds-grid',
	templateUrl: './grid.component.html',
	styleUrls: ['./grid.component.scss'],
})
export class GridComponent implements OnInit, AfterViewInit, AfterViewChecked {

	internalid: number = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);

	@ViewChild(IonContent) content: IonContent;
	@ViewChild('abc') abc!: ElementRef;
	@ViewChildren('col') colElements: QueryList<ElementRef>;

	_service: any;
	searchValue: string;
	tmpSearchValue: string;
	@Input() addable: boolean = true;
	@Input() allowUseCache: boolean = false;
	@Input() searchable: boolean = false;
	@Input() reorderEnabled: boolean = false;
	@Input() deleteEnabled: boolean = false;
	@Input() updateEnabled: boolean = false;
	@Input() title: string;
	@Input() displayParam1: any;
	@Input() idfieldname: string = "idcustomer";
	@Input() fields: any[] = [];
	@Input() filters: any[] = [];
	@Output() didSearch = new EventEmitter<any>();
	@Input() addFields: any[] = [];
	@Input() canDeleteRowCallback: Function;
	@Input() canUpdateRowCallback: Function;
	@Input() addEntryCallback: Function;
	_canopen: boolean
	_autoRefresh: number;

	cacheWidths
	ngAfterViewInit() {
		if ( this._service ) {
			const cachename = "dimension_"+this._service.basetable
			this.global.cacheService.get(cachename).then(x=>{
				console.log("debug14 get cache width",cachename,x)
				this.cacheWidths = x
				let i = 0;
				for( i=0;i<this.fields.length;i++) {
					this.fields[i].pixelWidth = x[ i ]
				}
				console.log("debug14 this.fields",this.fields)
			})
		}
	}

	ngAfterViewChecked() {
		// Call the method here to see if it works better
		//this.setInitialWidths();
		//this.setInitialWidths();
	}

	getColId(field) {
		if( field.index == null ) {
			field.index = this.fields.findIndex(x=>x==field)
		}
		return "col" + field.index
	}

	afterViewCheckedOk = false
	setInitialWidths() {
		
			let index = 0
			let col = document.querySelector("#col" + index) as HTMLDivElement | null;
			while (col) {
				let column = this.fields[index]
				if (!column.pixelWidth) {
					console.log("debug14 col" + index + " width", col.getBoundingClientRect().width)
					column.pixelWidth = col.getBoundingClientRect().width
					if ( column.pixelWidth > 0 ) { //si on arrive a trouver au moins une largeur, 
												//ca veut dire que la view est bien initialiser et
												//on ne repassera plus par cette fonction
						this.afterViewCheckedOk = true;
						console.log("debug14 this.afterViewCheckedOk",this.afterViewCheckedOk)
					}
				}
				index++
				col = document.querySelector("#col" + index) as HTMLDivElement | null;
			}
			console.log("debug14 setInitialWidths", this.fields)
			//this.afterViewCheckedOk = true;

	}

	//page: number = 1;
	//pages: number = 1;
	totalPages: number = 0;
	rowPerPage: number = 50;

	currentPage: number = 1;
	pages: number[] = [];

	tmp: string;

	canDeleteRow(entry): boolean {
		let res = this.deleteEnabled
		if (this.canDeleteRowCallback)
			res = res || this.canDeleteRowCallback(entry);
		return res
	}

	canUpdateRow(entry): boolean {
		let res = this.updateEnabled
		if (this.canUpdateRowCallback)
			res = res || this.canUpdateRowCallback(entry);
		return res
	}

	log(...txt) {
		if (!this.global.debug_grid)
			return
		console.log(txt)
	}

	update() {
		this.log("update")
		this.zone.run(() => {
			this.log('force update the screen');
		});

		setTimeout(() => {
			this.update()
		}, 5000);
	}

	sanitize(html) {
		return this.sanitizer.bypassSecurityTrustHtml(html);
	}

	_reorderDisabled: boolean = true;

	private addEntryDialogComponentSubscription: Subscription
	constructor(public global: GlobalService,
		private zone: NgZone,
		private sanitizer: DomSanitizer,
		private modalController: ModalController,
		private addEntryDialogComponent: AddEntryDialogComponent,
		private renderer: Renderer2
	) {


	}

	useCache = false
	ngOnInit(): void {
		console.log("debug5 openAddEntryDialog ngOnInit")
		this.addEntryDialogComponentSubscription = this.addEntryDialogComponent.onDismiss.subscribe(data => {
			console.log('openAddEntryDialog res :', data)
			if (this.addEntryCallback)
				this.addEntryCallback(data)

		})

		this._service.reloadEvent.subscribe((t) => {
			console.log('reloadEvent', t)
			this.cache = null
			this.changePage(1)
			this.paginate()
			//this.displayEntries()	*/
		})

		const dataEventLoadedSubscription = this._service.dataEventLoaded.subscribe({
			next: (response) => {
				console.log("debug5 ✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️✔️ " + this._service.basetable)
				dataEventLoadedSubscription.unsubscribe()
				this.useCache = true
				this.paginate()
			}
		})

	}

	ngOnDestroy() {
		console.log("debug5 openAddEntryDialog ngOnDestroy")
		if (this.addEntryDialogComponentSubscription) {
			this.addEntryDialogComponentSubscription.unsubscribe()
		}
	}

	onSearch() {
		this.paginate()
		this.didSearch.emit(this.filters);
	}

	setAddFieldValue(addfield, value) {
		addfield.value = value
	}

	setFieldValue(obj, field, value) {
		const prop = field.field
		const oldobj = obj
		if (prop == "customer.firstname")
			this.log("setFieldValue", obj, prop, value)

		var parts = prop.split('.'),
			last = parts.pop(),
			l = parts.length,
			i = 1,
			current = parts[0];

		if (l === 0) {
			obj[prop] = value;
			this.log("setFieldValue 1", obj, prop, value)
			this._service.setEntryUpdate(oldobj)

			if (field.onChange)
				field.onChange(oldobj)
			return
		}

		while ((obj = obj[current]) && i < l) {
			current = parts[i];
			i++;
		}

		if (obj) {
			obj[last] = value;
			this.log("setFieldValue 2", obj, prop, value)
			this._service.setEntryUpdate(oldobj)

			if (field.onChange)
				field.onChange(oldobj)
		}
	}

	getFieldValue(obj, prop, field = null) {
		let res = BaseService.getFieldValue(obj, prop)
		if (field && field.formatFunction) {
			if (field.field == "idCommande")
				console.log("debug4 getFieldValue field1", field, res)
			//console.log("this.idfieldname",this.idfieldname)
			res = field.formatFunction(res, BaseService.getFieldValue(obj, this.idfieldname))
			//console.log("getFieldValue field2", field, res)
		}
		return res
	}

	sort(field) {
		if ( this.mouseUp ) {
			//pour éviter le click lors du redimensionnement des colonnes
			this.mouseUp = false
			return
		}
		console.log("sort", field)
		if (!field.sort)
			field.sort = 1
		else if (field.sort == 1)
			field.sort = 2
		else
			field.sort = 1

		this.fields.forEach(f => {
			if (f != field) {
				f.sort = null
			}
		})
		this.cache = null
		this.paginate()
	}

	searchHandle = null
	search(keycode) {
		if (keycode == 13) {
			this.cache = null
			this.searchValue = this.tmpSearchValue
		}
	}

	getFlexBasis(column) {		
		let res = "0 0 " + column.width;
		if ( column.pixelWidth )
			res = "0 0 " + column.pixelWidth + "px";
		//console.log(res)
		return res
	}

	getWidth(field) {
		return "500px";
	}

	getMaxWidth(field) {
		return ""
		if (field.type == "icon")
			return "38px"
		if (field.width)
			return field.width
		return ""
	}



	updatePaginatedItems() {
		const startIndex = (this.currentPage - 1) * this.rowPerPage;
		const endIndex = startIndex + this.rowPerPage;
		//this.paginatedItems = this.items.slice(startIndex, endIndex);
	}

	updatePages() {
		const totalPages = this.totalPages;
		let startPage: number, endPage: number;

		if (totalPages <= 10) {
			// moins de 10 pages, afficher toutes les pages
			startPage = 2;
			endPage = totalPages - 1;
		} else {
			// plus de 10 pages, calculer les pages à afficher
			if (this.currentPage <= 6) {
				startPage = 2;
				endPage = 11;
			} else if (this.currentPage + 4 >= totalPages) {
				startPage = totalPages - 10;
				endPage = totalPages - 1;
			} else {
				startPage = this.currentPage - 5;
				endPage = this.currentPage + 4;
			}
		}

		this.pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
	}

	updatePages2() {
		const totalPages = this.totalPages;
		let startPage: number, endPage: number;

		if (totalPages <= 10) {
			// moins de 10 pages, afficher toutes les pages
			startPage = 1;
			endPage = totalPages;
		} else {
			// plus de 10 pages, calculer les pages à afficher
			if (this.currentPage <= 6) {
				startPage = 1;
				endPage = 10;
			} else if (this.currentPage + 4 >= totalPages) {
				startPage = totalPages - 9;
				endPage = totalPages;
			} else {
				startPage = this.currentPage - 5;
				endPage = this.currentPage + 4;
			}
		}

		this.pages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
	}

	changePage(page: number) {
		this.cache = null
		if (page >= 1 && page <= this.totalPages) {
			this.currentPage = page;
			this.updatePaginatedItems();
			this.updatePages();
		}
	}

	nextPage() {
		this.cache = null
		if (this.currentPage < this.totalPages) {
			this.currentPage++;
			this.updatePaginatedItems();
			this.updatePages();
		}
	}

	previousPage() {
		this.cache = null
		if (this.currentPage > 1) {
			this.currentPage--;
			this.updatePaginatedItems();
			this.updatePages();
		}
	}

	getGridClass() {
		return "grid_" + this.title + "_" + this.internalid
	}

	getClassInput(field, index) {
		let res = "";
		if (["date-time", "date"].indexOf(field)) {
			res = "ion-text-end"
		}
		if (field.class) {
			res = field.class;
		}
		//console.log("getClassInput", res);
		return res
	}

	getClassHeader(field, index) {
		return "header col" + index
	}

	getClass(field, i, j) {
		return "item col" + i + "-" + j
	}

	getPlaceHolder(field) {
		return field.placeholder ? field.placeholder : field.label
	}

	keyup(event, field, i, j) {
		const keys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"]
		if (keys.includes(event.code) && event.shiftKey) {

			if (event.code == "ArrowDown") {
				i++
			}
			if (event.code == "ArrowUp") {
				i--
			}
			if (event.code == "ArrowRight") {
				j++
			}
			if (event.code == "ArrowLeft") {
				j--
			}

			console.log("keyup", field, i, j)

			const focusclass = '.item.col' + i + "-" + j
			let el = document.querySelector('.item.col' + i + "-" + j + " input") as HTMLDivElement | null;
			if (!el) {
				el = document.querySelector('.item.col' + i + "-" + j + " div") as HTMLDivElement | null;
			}
			console.log("focusclass", focusclass, el)
			if (el) {
				el.focus()

			}
		}
	}

	getAddType(field) {
		if (field.type == "function" || field.function)
			return "function"
		if (field.type == "icon")
			return "icon"
		if (field.type == "checkbox")
			return "checkbox"
		if (field.type == "currency")
			return "currency"
		if (field.type == "number")
			return "number"
		if (field.type == "autocomplete")
			return "autocomplete"
		if (field.options)
			return "select"
		if (field.type == "date")
			return "date"
		if (field.type == "date-time")
			return "date-time"
		if (field.type == "datetime")
			return "date-time"
		return "text"
	}

	getType(field) {
		if (field.type == "hidden")
			return "hidden"
		if (field.type == "function" || field.function)
			return "function"
		if (field.type == "icon")
			return "icon"
		if (field.type == "checkbox")
			return "checkbox"
		if (field.type == "currency")
			return "currency"
		if (field.type == "number")
			return "number"
		if (field.type == "autocomplete")
			return "autocomplete"
		if (field.options)
			return "select"
		if (field.type == "date")
			return "date"
		if (field.type == "date-time")
			return "date-time"
		if (field.type == "datetime")
			return "date-time"
		return "text"
	}

	onSelectFilterChange(event) {
		console.log("onSelectFilterChange")
		this.currentPage = 1
		this.cache = null
	}

	onDateSelectedChange(event) {
		console.log("debug7 onEndDateSelectedChange", event)
		this.currentPage = 1
		this.cache = null
	}

	clearSearch() {
		this.log("clearSearch")
		this.tmpSearchValue = ""
		this.searchValue = ""
		this.cache = null
	}

	cache = null
	paginate() {
		// human-readable page numbers usually start with 1, so we reduce 1 in the first argument
		let tmp = this.cache
		if (!this.allowUseCache || (this.allowUseCache && /*this.useCache &&*/ !this.cache)) {
			this.cache = this.displayEntries().slice((this.currentPage - 1) * this.rowPerPage, this.currentPage * this.rowPerPage);
			tmp = this.cache
			console.log("debug5 paginate aa", tmp.length)
		}
		//console.log("debug5 paginate", this._service.basetable, tmp?.length, this.useCache)
		return tmp;
	}

	getDisplayFields() {
		return this.fields.filter(f => f.type != "hidden")
	}

	includesExact(array: string[], searchString: string): boolean {
		return array.some(element => element == searchString);
	}

	ensureArray(input: string | string[]): string[] {
		if (typeof input === 'string') {
			return [input];
		}
		return input;
	}

	consolelog = 0
	displayEntries() {
		let sortedField = this.fields.find(x => x.sort)
		let tmp = this._service.getEntriesToDisplay(this.displayParam1, this.searchValue, sortedField, this.idfieldname)
		console.log("debug9 displayEntries", this._service.basetable, this.idfieldname, tmp, this._service.entries)

		//apply filters
		console.log("debug9 displayentries this.filters", this.filters)
		tmp = tmp.filter(row => {
			return this.filters.every(filter => {
				if (filter.type === 'daterange') {
					const startDate = new Date(filter.startDate);
					const endDate = new Date(filter.endDate);
					const dateTrace = new Date(row[filter.field]);
					if (!filter.startDate && !filter.endDate) {
						return true; // No date range filter applied
					}

					const res = (!filter.startDate || dateTrace >= startDate) && (!filter.endDate || dateTrace <= endDate)

					/*if ( res && (filter.startDate == "2024-10-31" || filter.startDate == "2024-11-01") )
						console.log("debug8 ",filter.startDate,row[filter.field],startDate,dateTrace)
*/
					return res;

				}
				if (filter.type === 'select') {
					if (!filter.value || filter.value.length === 0) {
						return true; // No select filter applied
					}
					if (typeof filter.value === 'string') {
						filter.value = [filter.value];
					}

					if (this.consolelog < 10) {
						this.consolelog++
						console.log("debug4 filter.value", row, filter.field, row[filter.field], filter.value)
					}

					return this.includesExact(filter.value, row[filter.field])
				}
				if (!filter.value) {
					return true; // No text filter applied
				}
				return row[filter.field].includes(filter.value);
			});
		});

		this.totalPages = Math.ceil(tmp.length / this.rowPerPage)
		this.updatePaginatedItems();
		this.updatePages();

		return tmp;
	}

	addEntry2() {

	}

	addEntry() {
		let newentry = this._service.addEntry()
		this.log("grid component addentry", newentry, this._service.getEntries())
		if (this.addEntryCallback)
			newentry = this.addEntryCallback(newentry)
		this.log("grid component entries", this._service.getEntries());
	}

	/*@Input()
	set addEntryCallback(addEntryCallback: (entry:any)=>void) {
		this._addEntryCallback = addEntryCallback;
	}*/

	@Input()
	set canopen(canopen: string) {
		this._canopen = false
		if (canopen.toLocaleLowerCase() == "true") {
			this._canopen = true
		}
	}


	@Input()
	set service(service: BaseService) {
		this._service = service;
	}

	@Input()
	set autoRefresh(timeout: number) {
		this._autoRefresh = timeout;
		setTimeout(() => {
			this.update()
		}, 5000);
	}

	/*auto complete functions*/
	autoCompleteProperty = "";
	autoCompleteSource = -1;
	currentFieldAutoComplete: any = null;
	currentEntryAutoComplete: any = null;
	itemsAutoComplete: any[] = [];
	itemsAutoCompleteCurrentIndex: number = -1;
	inputAutoComplete: any;
	autoCompleteTop: String = "-999px";
	autoCompleteLeft: String = "-999px";
	inputAutoCompleteBlur(): void {
		setTimeout(() => {
			this.log("input auto complete blur")
			this.currentEntryAutoComplete = null;
			this.itemsAutoComplete = []
			this.itemsAutoCompleteCurrentIndex = -1;
			this.autoCompleteSource = -1;
		}, 500);
	}

	//retourne le nom du champ qui est utilisé pour l'affichage de l'auto complete
	//par défaut, le champ est identique à field
	//mais on peut passer un nom de champ différent
	getAutoCompleteFieldName(field) {
		if (field.autocompleteproperty)
			return field.autocompleteproperty
		return field
	}

	selectedAutoComplete(selectedItem): void {
		this.log("selectedAutoComplete", selectedItem, this.itemsAutoCompleteCurrentIndex)

		let line = null;
		let item = null;

		const currentEntry = this.currentEntryAutoComplete;

		//field contient les infos du champ courant de la liste autocomplete
		const field = this.currentFieldAutoComplete;

		//on retrouve l'entrée en fonction de la valeur sélectionnée de la liste autocomplete
		const selectedEntry = field.findfunction(this.getAutoCompleteFieldName(field), selectedItem)

		field.autocompleteOnSelected(currentEntry, selectedEntry)

		this.itemsAutoComplete = []
		this.itemsAutoCompleteCurrentIndex = -1;
	}

	inputAutoCompleteChanged($event, currentEntry, indexrow, indexcol, field): void {
		this.autoCompleteSource = 0
		const value = $event.target.value;
		this.inputAutoComplete = $event.target;
		this.currentFieldAutoComplete = field;
		this.currentEntryAutoComplete = currentEntry;

		this.log("value", value)
		if (value.length <= 0) {
			this.itemsAutoComplete = [];
			return;
		}

		const columnheader = document.querySelector('.' + this.getGridClass() + ' .header.col' + indexcol) as HTMLDivElement | null;
		const fieldElement = document.querySelector('.' + this.getGridClass() + ' .item.col' + indexrow + "-" + indexcol) as HTMLDivElement | null;
		const gridElement = document.querySelector('.' + this.getGridClass()) as HTMLDivElement | null;

		let gridRect = gridElement.getBoundingClientRect();
		let headerRect = columnheader.getBoundingClientRect();
		let rect = $event.target.getBoundingClientRect()
		let parentRect = fieldElement.getBoundingClientRect();
		this.log("headerRect parentRect", headerRect, parentRect)
		this.autoCompleteTop = (parentRect.top - headerRect.top + parentRect.height) + "px";
		this.autoCompleteLeft = (parentRect.left - gridRect.left + 50) + "px";

		this.log("bbb", fieldElement, parentRect, headerRect, rect, gridRect)
		this.log("aaa", this.autoCompleteTop, this.autoCompleteLeft, '.item.col' + indexrow + "-" + indexcol)

		this.itemsAutoComplete = field.autocompletefunction(this.getAutoCompleteFieldName(field), value, currentEntry, indexrow)
		this.itemsAutoCompleteCurrentIndex = indexrow;
		this.log("this.itemsAutoComplete", this.itemsAutoComplete, indexrow, indexcol)

		if (this.itemsAutoComplete.length == 0) {
			//rien trouvé dans l'autocompletion, on a donc une nouvelle entrée
			this.log("pas d'entéer trouvé", this.currentFieldAutoComplete)
		}
	}
	/*auto complete functions*/

	/*gestion popup date*/
	selectedPresentationType = "date"
	selectedEntry: any = null;
	selectedEntryDateFieldName = "";
	selectedEntryDate: any
	isDatePopoverOpened = false;
	@ViewChild('datepopover') popover;
	presentDatePopover(e: Event, entry, field, value) {
		const fieldname = field.field
		this.selectedPresentationType = field.type
		this.selectedEntry = entry;
		this.log("presentPopover", this.isDatePopoverOpened)
		this.popover.event = e;
		this.selectedEntryDate = value;
		this.selectedEntryDateFieldName = fieldname
		this.isDatePopoverOpened = true;
	}

	getPresentationDate() {
		return this.selectedPresentationType
	}

	clearFilter(filter) {
		if (filter.type === 'daterange') {
			filter.startDate = null;
			filter.endDate = null;
		} else {
			filter.value = null;
		}
		this.cache = null;
	}

	dismissDatePopover() {
		this.log("this.selectedInvoiceDate", this.selectedEntryDate)
		this.isDatePopoverOpened = false
		if (this.selectedEntry[this.selectedEntryDateFieldName] != this.selectedEntryDate) {
			this.selectedEntry[this.selectedEntryDateFieldName] = this.selectedEntryDate;
			this.selectedEntry.db = 1;
			this._service.saveEntriesDelay();
		}
	}
	/*gestion popup date*/

	async openAddEntryDialog() {
		console.log("🏁🏁🏁🏁🏁🏁🏁🏁🏁🏁🏁🏁🏁 call openAddEntryDialog")
		this.addEntryDialogComponent.fields = this.addFields

		//addEntryDialogComponent.onDismiss dans le constructor
		const res = await this.addEntryDialogComponent.presentModal()
		console.log("openAddEntryDialog", res, this.addEntryDialogComponent.formValues)
	}

	onDateSelected(filter, value, selectedDate: string) {
		value = selectedDate
		console.log('debug7 Date sélectionnée :', filter, selectedDate, value);

		// Réagissez ici à la sélection de la date
	}

	//resize col
	private startX: number;
	private startWidth: number;
	private currentColumn: any;

	onMouseDown(event: MouseEvent, field: any) {
		if( field.index == null ) {
			field.index = this.fields.findIndex(x=>x==field)
		}
		console.log("debug14 colum.width", field.width)
		if (!field.pixelWidth) {
			const col = document.querySelector("#col" + field.index) as HTMLDivElement | null;
			console.log("debug14 col width", col.getBoundingClientRect().width)
			field.pixelWidth = col.getBoundingClientRect().width
		}

		this.startX = event.clientX;
		this.startWidth = field.pixelWidth;
		this.currentColumn = field;

		this.renderer.addClass(document.body, 'noselect');

		document.addEventListener('mousemove', this.onMouseMove);
		document.addEventListener('mouseup', this.onMouseUp);
	}

	onMouseMove = (event: MouseEvent) => {
		if (this.currentColumn) {
			const dx = event.clientX - this.startX;
			this.currentColumn.pixelWidth = Math.max(50, this.startWidth + dx); // Minimum width of 50px
			//console.log("w", this.startWidth, dx, this.currentColumn.pixelWidth)
		}
	};

	mouseUp = false
	onMouseUp = (event: MouseEvent) => {
		
		if ( this._service ) {
			const cachename = "dimension_"+this._service.basetable
			const widths = this.fields.map(x=>x.pixelWidth)
			console.log("debug14 cache width",cachename,widths)
			this.global.cacheService.set(cachename,widths)
		}

		this.mouseUp = true
		event.stopPropagation();
		console.log('this.currentColumn.width', this.currentColumn.pixelWidth)
		this.renderer.removeClass(document.body, 'noselect');
		document.removeEventListener('mousemove', this.onMouseMove);
		document.removeEventListener('mouseup', this.onMouseUp);
		this.currentColumn = null; // Reset the current column
	};
}
