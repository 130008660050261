import { Injectable } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { str_structures_meres } from '../types/type_str_structures_meres';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StrStructuresMeresService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("str_structures_meres", this.loadEntry.bind(this))
	}

	override addEntry(): any {
		return super.addEntry(new str_structures_meres())
	}

	override getSingleUrl() {
		return '/client/' + this.currentEntry.id
	}

	override getListUrl() {
		return '/clients'
	}

	override getSingleIcon() {
		return 'person'
	}

	override getListIcon() {
		return 'people'
	}

	override getEntriesToDisplay(idcustomer=null,search=null,sortedField=null) {
		let tmp = super.getEntriesToDisplay(idcustomer,search,sortedField)
		tmp = tmp.filter(x=>!(x.company == "" && x.lastname=="" && x.firstname=="") )
		if ( sortedField ) {
			
			if ( sortedField.sort == 1 )
				return tmp.sort((a,b)=>a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a,b)=>b[sortedField.field].localeCompare(a[sortedField.field]))
		}
		return tmp
	}

	override getTitle(entry:any=null) {
		if (!entry)
			entry = this.currentEntry
		let tmp = entry.company.trim()
		if ( tmp == "" ) {
			tmp = entry.lastname + " " + entry.firstname
			tmp = tmp.trim()
			if (tmp == "")
				return "(Nouveau client)"
		}
		return tmp
	}

	//funtion passed in constructor
	loadEntry(dbentry) {
		//console.log("load str_structures_meres", dbentry)
		const res = (new str_structures_meres()).fromObj(dbentry)
		//console.log("loadCustomer", res)
		return res

	}

	/*findCustomer(id): Customer {
		this.currentCustomer = null;
		this.currentCustomer = this.customers.find(cust => cust.id == id)
		return this.currentCustomer
	}*/
}