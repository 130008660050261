export interface Iins_instance {
	id: Number;
	idSpecialiteStructure: Number;
	idTypeInstance: Number;
	idTypeStructure: Number;
}

export class ins_instance implements Iins_instance {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	idSpecialiteStructure: Number;
	idTypeInstance: Number;
	idTypeStructure: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.idSpecialiteStructure = 0;
		this.idTypeInstance = 0;
		this.idTypeStructure = 0;
	}

	public fromObj(entry: Iins_instance) {
		this.id = entry.id;
		this.idSpecialiteStructure = entry.idSpecialiteStructure;
		this.idTypeInstance = entry.idTypeInstance;
		this.idTypeStructure = entry.idTypeStructure;
		return this;
	}

}
