import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';


@Injectable({
	providedIn: 'root'
})
export class AppPagesService {
	public appPages = [];
	public labels = [/*'Family', 'Friends', 'Notes', 'Work', 'Travel', 'Reminders'*/];

	constructor(public global: GlobalService, public router: Router) {
		this.global.mainPageTitle.subscribe(pagetitle => {
			const page = this.appPages.find(page => page.url == pagetitle.url)
			if (page)
				page.title = pagetitle.title
		})

		/*this.global.authState.subscribe(value => {
			console.log("AppPagesService this.global.authState", value, this.global.user)
			if (value) {
			}
		})*/
		console.log("!!!! constructor AppPagesService")
	}

	public loadPageStructure() {
		//let components = this.global.user["components"].toLocaleLowerCase().split(",")
		//if ( components.includes("clients"))
		//	if ( components.includes("factures"))
		//		this.appPages.push( { title: 'Factures', url: '/factures', redirectUrl: '', /*Component: FacturePage,*/ icon: 'documents', subnode: false } )
		//	if ( components.includes("produits"))
		//		this.appPages.push( { title: 'Produits', url: '/produits', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'cube', subnode: false } )
		//	if ( components.includes("timetasks"))
		//this.appPages.push( { title: 'TimeLine', url: '/time-tasks', redirectUrl: '', /*Component: ProduitPage,*/ src: 'assets/icon/chrono.svg', subnode: false, iconClick: null } )
		//this.appPages.push( { title: 'Paramètres', url: '/parametres', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'settings', subnode: false } )

		if (this.global.isEspaceAdmin()) {
			this.appPages.push({ title: 'Adhérents', url: '/adherents', redirectUrl: '', /*Component: ClientPage,*/ icon: 'people', subnode: false })
			
			this.appPages.push({ title: 'Structures', url: '/nodes', redirectUrl: '', /*Component: ProduitPage,*/ icon: '', fontawesomeicon: 'fa-solid fa-building', subnode: false })
			this.appPages.push({ title: 'Liste des structures', url: '/nodes', redirectUrl: '', /*Component: ProduitPage,*/ icon: '', fontawesomeicon: 'fa-solid fa-building', subnode: true })
			this.appPages.push({ title: 'Répartition des adhésions', url: '/repartition-adhesions/', redirectUrl: '', /*Component: ProduitPage,*/ fontawesomeicon: 'fa-solid fa-chart-simple',subnode: true, closeEnabled: false })

			this.appPages.push({ title: 'Financier', url: '/wallet', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'wallet', subnode: false })
			this.appPages.push({ title: 'Import de réglements', url: '/import-reglements', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'save', subnode: true })
			this.appPages.push({ title: 'Prélèvements et cartes', url: '/liste-paiements', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'card', subnode: true })
			this.appPages.push({ title: 'Règlements', url: '/reglements', redirectUrl: '', /*Component: ProduitPage,*/ icon: 'cash', subnode: true })
			this.appPages.push({ title: 'Nouvel adhérent', url: '/nouveau-adherent', redirectUrl: '', /*Component: ClientPage,*/ icon: 'people', subnode: false })
			this.appPages.push({ title: 'Paramètres', url: '/settings', redirectUrl: '', /*Component: ClientPage,*/ icon: 'cog', subnode: false })
			this.appPages.push({ title: 'Infos complémentaires', url: '/questions', redirectUrl: '', /*Component: ClientPage,*/ icon: 'git-pull-request', subnode: true })
		} else {
			this.appPages.push({ title: 'Personne', redirectUrl: '', fontawesomeicon: 'fa-solid fa-user', subnode: false })
			this.appPages.push({ title: 'Mes coordonnées', url: '/mes-coordonnees/'+this.global.getIdPersonneLoggedIn(), redirectUrl: '', /*Component: ProduitPage,*/ fontawesomeicon: 'fa-solid fa-map-location-dot',subnode: true, closeEnabled: false })
			this.appPages.push({ title: 'Mes documents', url: '/mes-documents/'+this.global.getIdPersonneLoggedIn(), redirectUrl: '', /*Component: ProduitPage,*/ fontawesomeicon: 'fa-solid fa-file-pdf',subnode: true, closeEnabled: false })
			this.appPages.push({ title: 'Inscription/Désinscription', url: '/bulletin-info/'+this.global.getIdPersonneLoggedIn(), redirectUrl: '', /*Component: ProduitPage,*/ fontawesomeicon: 'fa-solid fa-newspaper',subnode: true, closeEnabled: false })

			this.appPages.push({ title: 'Mes adhésions', redirectUrl: '', /*Component: ProduitPage,*/ icon: '',  fontawesomeicon: 'fa-solid fa-rectangle-list', subnode: false })
			this.appPages.push({ title: 'Liste des adhésions', url: '/adherent/'+this.global.getIdPersonneLoggedIn(), redirectUrl: '', /*Component: ProduitPage,*/ icon: '',  fontawesomeicon: 'fa-solid fa-address-card', subnode: true })
			this.appPages.push({ title: 'Saisir mon adhésion', url: '/nouvelle-adhesion/'+this.global.getIdPersonneLoggedIn(), redirectUrl: '', /*Component: ProduitPage,*/ icon: '',  fontawesomeicon: 'fa-solid fa-circle-plus', subnode: true })

			this.appPages.push({ title: 'Gestion documentaire', redirectUrl: '', /*Component: ProduitPage,*/ fontawesomeicon: 'fa-solid fa-book',subnode: false })
			this.appPages.push({ title: 'Liste des documents', url: '/liste-documents', redirectUrl: '', /*Component: ProduitPage,*/ fontawesomeicon: 'fa-solid fa-file-pdf',subnode: true, closeEnabled: false  })
		}
	}

	closePage(url: String, redirectUrl: String = "") {
		console.log("closePage3", url);
		const index = this.appPages.findIndex(x => x.url == url)
		console.log("index", index)
		if (index > -1) {
			this.appPages.splice(index, 1);
		}
		console.log("closePage2", JSON.stringify(this.appPages))

		if (redirectUrl != "")
			this.router.navigate([redirectUrl])
	}

	openPage(page: any) { //title,url,component,icon
		console.log("openPage",page)
		if (this.appPages.filter(x => x.url == page.url).length == 0) {

			let index = this.appPages.findIndex(x => x.url == page.redirectUrl)
			if (index > -1) {
				this.appPages.splice(index + 1, 0, page)
			}
			else {
				this.appPages.push({ title: page.title, url: page.url, redirectUrl: page.redirectUrl, /*Component: page.component,*/ icon: page.icon, subnode: page.subnode });
			}
		}
		console.log("this.appPages", this.appPages)
		this.router.navigate([page.url])
	}

	displayPage(page: any) {
		console.log("display page", page)
		this.router.navigate([page.url])
	}
}
