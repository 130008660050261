import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { InfoSociete } from '../types/info-societe';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParametresService extends BaseService {

	override debug: false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("settings", this.loadSettings.bind(this))
    /*this.currentEntry = new InfoSociete()
    this.entries.push( this.currentEntry )*/
	}

  //funtion passed in constructor
	loadSettings(dbentry) {
		this.log("loadSettings", dbentry)
		const res = (new InfoSociete()).fromObj(dbentry)
    this.log("loadSettings", this.global, res)

    this.global.infoSociete = res;
    

    this.currentEntry = res
    /*this.entries = []
    this.entries.push( res )
		*/this.log("loadSettings", res)
		return res

	}
}
