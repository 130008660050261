import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ins_fonction_autorisee } from '../types/type_ins_fonction_autorisee';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InsFonctionAutoriseeService extends BaseService {

	override authneeded: boolean = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService,injector);
		super.init("ins_fonction_autorisee", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new ins_fonction_autorisee()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished ins_fonction_autorisee", this.entries)
	}

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "idInstance", nullable: false },
		{ field: "idTypeFonction", nullable: false },
		{ field: "honorabiliteAllowed", nullable: true },
		{ field: "genre", nullable: true }
		]
	}

	async Add(
		idInstance,
		idTypeFonction,
		honorabiliteAllowed,
    	genre
	) {

		const res = new ins_fonction_autorisee()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.idInstance = idInstance
		res.idTypeFonction = idTypeFonction
		res.honorabiliteAllowed = honorabiliteAllowed
		res.genre = genre

		this.entries.push(res)
		const id = await this.save(res);
		res.id = Number(id);
		return res;
	}
}