<ion-header *ngIf="hideTitle == 0">
  <ion-toolbar>
    <ion-title>CHOIX DE LA SECTION LOCALE</ion-title>
    <ion-buttons slot="end">
      <ion-button *ngIf="closeEnabled" (click)="setOpen(false)">Ferme</ion-button>
      </ion-buttons>
  </ion-toolbar>
</ion-header>

<div>
  <ion-searchbar [debounce]="250" (ionInput)="handleSearch($event)" placeholder="Recherche"></ion-searchbar>

  <ion-list>
    <ng-template #recursiveList let-list>
      <div *ngFor="let item of list; let j = index">
        <ion-item *ngIf="item.visible" ng-init="depth = depth + 1">
          <ion-icon class="folder shadow" *ngIf="item.children.length>0" name="folder"
            slot="start"></ion-icon>
          <ion-icon *ngIf="item.children.length==0" name="document-text-outline"
            slot="start" (click)="select(item)"></ion-icon>
          <ion-label *ngIf="item.children.length>0" (click)="item.expanded=!item.expanded">{{item.code}} - {{item.nom}}</ion-label>							
          <ion-label *ngIf="item.children.length==0" (click)="select(item)">{{item.code}} - {{item.nom}}</ion-label>
          <!--<ion-icon *ngIf="item.children.length>0" (click)="addSubentry(item)" name="add"
            slot="end"></ion-icon>-->
        </ion-item>
        <div class="subnode" *ngIf="item.children.length > 0 && item.expanded == true">
          <ng-container
            *ngTemplateOutlet="recursiveList; context:{ $implicit: item.children }"></ng-container>
        </div>
      </div>
    </ng-template>
    <ng-container
      *ngTemplateOutlet="recursiveList; context:{ $implicit: this.entries }"></ng-container>
  </ion-list>
</div>