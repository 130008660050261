const originalConsoleLog = console.log;

// Override console.log
/*console.log = (...args: any[]) => {
  // Check if the first argument is a string that starts with 'debug3'
  if (typeof args[0] === 'string' && args[0].startsWith('debug7')) {
    originalConsoleLog(...args);  // Call the original console.log if condition is met
  }
};*/

// Optionally, redefine other console methods (console.error, console.warn, etc.) as no-op if desired
/*console.error = () => {};  // Disable console.error
console.warn = () => {};   // Disable console.warn
console.info = () => {};   // Disable console.info
*/

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
Chart.register(ChartDataLabels);

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
