import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { etq_etiquettes } from '../types/type_etq_etiquettes';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { Injector } from '@angular/core';
import { drt_acces } from '../types/type_drt_acces';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class DrtAccesService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector,
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("drt_acces", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new drt_acces()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("debug10 finished drt_acces", this.entries,this.global.droits)

		
	}


	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "idStructure", nullable: true },
		{ field: "idUtilisateur", nullable: false }
		]
	}

	update(drtAcces) {
		//const new_drt_access_role = drtAcces.roles.map(({idRole})=>({idRole}))
		const roleIds = drtAcces.roles.map(role => role.idRole);
		console.log("update drtAccess", drtAcces, roleIds)

		console.log("call apiService 1")
		//this.apiService.addQuestion("aabbcc")
		console.log("call apiService 2")
		this.apiService.updateRoles( drtAcces.id, roleIds )
		console.log("call apiService 2")
	}
}
