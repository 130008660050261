export interface Ietq_etiquettes {
	id: Number;
	code: String;
	libelle: String;
	reference: String;
}

export class etq_etiquettes implements Ietq_etiquettes {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	code: String;
	libelle: String;
	reference: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.code = "";
		this.libelle = "";
		this.reference = "";
	}

	public fromObj(entry: Ietq_etiquettes) {
		this.id = entry.id;
		this.code = entry.code;
		this.libelle = entry.libelle;
		this.reference = entry.reference;
		return this;
	}

}
