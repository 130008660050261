import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { cfg_parametres_licencecommandee } from '../types/type_cfg_parametres_licencecommandee';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CfgParametresLicenceCommandeeService extends BaseService {

	override authneeded = false;

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService, injector);
		//super.init("trc_trace", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	loadFromPersonne(idPersonne) {
		console.log("start loadFromPersonne cfg_parametres_licencecommandee")
		this.loadid = idPersonne
		super.init("cfg_parametres_licencecommandee", this.loadEntry.bind(this), [], this.getId.bind(this))
		super.reload()
		console.log("end loadFromPersonne cfg_parametres_licencecommandee")
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new cfg_parametres_licencecommandee()).fromObj(dbentry)
		return res

	}


	override onLoadingFinished() {
		console.log("finished cfg_parametres_licencecommandee", this.entries)
	}

	override getEntriesToDisplay(idvalue = null, search = null, sortedField = null) {

		//getEntriesToDisplay(idvalue = null, search = null, sortedField = null, idfieldname = "idcustomer")

		let tmp = super.getEntriesToDisplay(idvalue, search, sortedField, "idPersonne")

		/*tmp = tmp.filter(x=>!(x.company == "" && x.lastname=="" && x.firstname=="") )*/
		if (sortedField) {

			if (sortedField.sort == 1)
				return tmp.sort((a, b) => a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a, b) => b[sortedField.field].localeCompare(a[sortedField.field]))
		}

		return tmp
	}

	/*
	insert(dateTrace, libelle, idActeur, idPersonne, idStructure, idTypeTrace, idEtiquette) {
		const data: any = {
			dateTrace: dateTrace,
			libelle: libelle,
			idActeur: idActeur,
			idPersonne: idPersonne,
			idStructure: idStructure,
			idTypeTrace: idTypeTrace,
			idEtiquette: idEtiquette,
		};


		this.getDbFields().forEach(field => {
			if (field.nullable && (!data[field.field] || (data[field.field] && data[field.field] == ""))) {
				delete data[field.field]// = "NULL"
			}
		});


		console.log("✅✅✅✅✅✅✅✅✅✅✅✅✅✅✅ insert", data)

		this.apiService.insertTrace(data).subscribe({
			next: (response) => {
				console.log('Record inserted successfully:', response);
				this.apiService.handleTablesToReset2(response, idPersonne)

				this.loadFromPersonne(idPersonne)

			},
			error: (error) => { console.error('Error inserting record:', error); }
		});
	}*/

	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "idPersonne", nullable: false },
		{ field: "type", nullable: false },
		{ field: "libelle", nullable: false },
		{ field: "idCfgParametere", nullable: false },
		{ field: "idParametrePere", nullable: true },
		
		]
	}

	/*
	async Add(
		dateTrace,
		libelle,
		idActeur,
		idPersonne,
		idStructure,
		idTypeTrace,
		idEtiquette
	) {

		const res = new trc_trace()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.dateTrace = dateTrace
		res.libelle = libelle
		res.idActeur = idActeur
		res.idPersonne = idPersonne
		res.idStructure = idStructure
		res.idTypeTrace = idTypeTrace
		res.idEtiquette = idEtiquette

		this.entries.push(res)
		//const id = await this.save(res);
		//res.id = Number(id);
		return res;
	}*/
}