<ion-header>
  <ion-toolbar>
    <ion-title>
      Fullscreen Page
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content fullscreen>
TEST
</ion-content>
