export interface Icfg_parametres_licencecommandee {
	id: Number;
	idPersonne: Number;
	type: String;
	libelle: String;
	idCfgParametere: Number;
	idParametrePere: Number;
}

export class cfg_parametres_licencecommandee implements Icfg_parametres_licencecommandee {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	idPersonne: Number;
	type: String;
	libelle: String;
	idCfgParametere: Number;
	idParametrePere: Number;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.idPersonne = 0;
		this.type = "";
		this.libelle = "";
		this.idCfgParametere = 0;
		this.idParametrePere = 0;
	}

	public fromObj(entry: Icfg_parametres_licencecommandee) {
		this.id = entry.id;
		this.idPersonne = entry.idPersonne;
		this.type = entry.type;
		this.libelle = entry.libelle;
		this.idCfgParametere = entry.idCfgParametere;
		this.idParametrePere = entry.idParametrePere;
		return this;
	}

}
