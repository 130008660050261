import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy, LoadingController } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { AuthenticationService } from './Services/authentication.service';
import { AuthGuard } from './Services/auth.guard';
import { Storage } from '@ionic/storage';
import { GlobalService } from './Services/global.service';
import { GridModule } from './components/grid/grid.module';
import { ParametresService } from './Services/parametres.service';
import { LoadingComponent } from './loading/loading.component';
import { FullscreenPageComponent } from './fullscreen-page/fullscreen-page.component';
import { LoadingDialogComponent } from './components/loading-dialog/loading-dialog.component';
import { of, forkJoin } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DataLoader2Service } from './Services/data-loader2.service';
import { DataLoaderService } from './Services/data-loader.service';
import { AddEntryDialogModule } from './components/add-entry-dialog/add-entry-dialog.module';
import { AutoSlashDirective } from './directives/auto-slash.directive';
import { DatePickerModule } from './components/date-picker/date-picker.module';
import { AutoSlashModule } from './directives/auto-slash.module';
import { AddEntryDialogComponent } from './components/add-entry-dialog/add-entry-dialog.component';
import { DatePickerModalModule } from './components/date-picker-modal/date-picker-modal.module';
import { ServerDBApiModule } from './Services/server-dbapi.module';
import { EtqEtiquettesService } from './Services/etq-etiquettes.service';
import { DatePipe } from '@angular/common';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { PdfGeneratorComponent } from './components/pdf-generator/pdf-generator.component';
import { UploadDialogComponent } from './components/upload-dialog/upload-dialog.component';
import { NgChartsModule } from 'ng2-charts';

export function initializeApp(globalService: GlobalService, dataLoader2: DataLoader2Service, loadingCtrl: LoadingController, injector: Injector) {
	console.log("a")
/*
	return () => new Promise<void>(async (resolve) => {
		const loading = await loadingCtrl.create({
			message: 'Chargement des données, veuillez patienter...',
			spinner: 'circles'
		});
		await loading.present();

		dataLoader2.loadData2();
		console.log("dataLoader2")
		dataLoader2.dataLoaded2.subscribe(loaded => {
			console.log("dataLoader2 xx", loaded)
			if (loaded) {

				const dataLoader = injector.get(DataLoaderService);
				dataLoader.loadData();
				console.log("dataLoader")
				dataLoader.dataLoaded.subscribe(loaded => {
					if (loaded) {
						globalService.authState.next(true);
						loading.dismiss();
						resolve();
					}
				});

			}
		});
	});*/
}


@NgModule({
	declarations: [AppComponent, LoadingComponent, FullscreenPageComponent, 
		LoadingDialogComponent, UploadDialogComponent],
	imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule,
		FormsModule, HttpClientModule, GridModule,AddEntryDialogModule, 
		DatePickerModule,AutoSlashModule,AddEntryDialogModule,
		DatePickerModalModule,NgChartsModule,
		ReactiveFormsModule,ServerDBApiModule],
	providers: [
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		/*{
			provide: APP_INITIALIZER,
			useFactory: initializeApp,
			deps: [GlobalService, DataLoader2Service, LoadingController, Injector],
			multi: true
		},*/
		Storage, PdfGeneratorComponent, //AuthGuard,
		GlobalService, ParametresService,InAppBrowser,
		AuthenticationService,AddEntryDialogComponent,DatePipe
		
	],
	bootstrap: [AppComponent],
})
export class AppModule { }
