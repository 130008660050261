import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './grid.component';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { AddEntryDialogModule } from '../add-entry-dialog/add-entry-dialog.module';
import { DatePickerModule } from '../date-picker/date-picker.module';

@NgModule({
  declarations: [GridComponent],
  imports: [
    CommonModule,IonicModule,FormsModule,AddEntryDialogModule,DatePickerModule
  ],
  exports:[GridComponent]
})
export class GridModule { }
