import { Injectable, Injector, OnDestroy, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, first } from 'rxjs';
import { GlobalService } from './global.service';
import { StrStructureService } from './str-structure.service';

@Injectable({
  providedIn: 'root'
})
export class DataLoader3Service {
  private nbloaded = 0;

	private done = false;
	public dataLoaded3: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	ngOnInit() {
		console.log("DataLoader2Service ngoninit")
	}

	getDataLoadedObservable() {
		return this.dataLoaded3.asObservable();
	}

	tables = [
		"st_structures",
	]

	loadData3() {
		const sub4 = this.strStructureService.dataEventLoaded.subscribe((t) => { console.log("dataEventLoaded subscribe", t); this.nbloaded++; this.checkLoaded(); })

		//on charge une simulation


		//
		/*this.sub2 = this.etqEtiquettesService.dataEventLoaded.subscribe((t)=>{console.log("2");this.nbloaded++;this.checkLoaded();this.sub2.unsubscribe()})
		this.sub3 = this.trcGroupeTraceService.dataEventLoaded.subscribe((t)=>{console.log("3");this.nbloaded++;this.checkLoaded();this.sub3.unsubscribe()})
*/

		/*setTimeout(() => {
			for (let i = 0; i < 10000; i++) {
				this.data.push({ id: i, name: `Item ${i}` });
			}
			// Notifier que les données sont chargées
			this.nbloaded++;
			this.checkLoaded();
			console.log("a nbloaded",this.nbloaded)
		}, 3000);*/ // Temps de chargement simulé de 3 secondes

		//on charge insfonction

		/*this.globalService.loaded.subscribe(t => {
			console.log("this.globalService.loaded.this.globalService.loaded.this.globalService.loaded.this.globalService.loaded.")
			if(  this.done )
				return;
			if (this.tables.includes(t)) {
				console.log("this.loadData2", t)				
				this.nbloaded++;
				this.checkLoaded();
				console.log("this.loadData2 b nbloaded", this.nbloaded, t)
			}
		})*/
	}

	constructor(private http: HttpClient,
		public globalService: GlobalService,
		public strStructureService: StrStructureService,
	) {
		this.strStructureService.callbackendloaded = this.endLoaded.bind(this, this.strStructureService)

		this.strStructureService.reload()
	}

	endLoaded(table, baseservice) {
		console.log("endLoaded", table)
		this.nbloaded++;
		this.checkLoaded();
	}

	wait() {
		return
		setTimeout(() => {
			this.nbloaded++; this.checkLoaded();
		}, 100)
	}

	async waitForTrue(): Promise<void> {
		// Create an observable from the BehaviorSubject
		const observable$ = this.dataLoaded3.asObservable();

		// Use the first operator to wait until the value is true
		const trueValue = await observable$.pipe(
			// Use the first operator to emit only when the value becomes true
			first(value => value === true)
		).toPromise();

		// At this point, trueValue is true
		console.log('The BehaviorSubject is now true');
	}

	checkLoaded(): void {
		console.log("checkLoaded", this.nbloaded)
		if (this.nbloaded == this.tables.length) {
			console.log("dataloaded2 next 1")
			this.dataLoaded3.next(true);
			console.log("dataloaded2 next 2")
			this.done = true;
		}
	}
}
