import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { GlobalService } from './global.service';
import { AppPagesService } from './app-pages.service';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { ServerDBApiService } from './server-dbapi.service';
import { fin_typeoperation } from '../types/type_fin_typeoperation';
import { Injector } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class FinTypeOperationService extends BaseService {

  override authneeded: boolean = false

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService,injector);
		super.init("fin_typeoperation", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

  	
	findOperation(id) {
		return this.entries.find(entry=>entry.id==id)?.libelle
	}
  
	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new fin_typeoperation()).fromObj(dbentry)
		return res

	}

	getCurrentSaisonId() {
		return this.currentEntry.id
	}

	override onLoadingFinished() {
		console.log("finished fin_typeoperation", this.entries)
	}


	override getDbFields() {
		return [{ field: "id", nullable: false },
		{ field: "code", nullable: false },
		{ field: "libelle", nullable: false },
		{ field: "sens", nullable: false },
		{ field: "saisieManuelleAutorisee", nullable: false },
		{ field: "idGroupeOperation", nullable: false },
    { field: "importFichierAutorise", nullable: false }
		]
	}

	async Add(
		code,
		libelle,
		sens,
		saisieManuelleAutorisee,
		idGroupeOperation,
    importFichierAutorise) {

		const res = new fin_typeoperation()

		const now = moment().format("YYYY-MM-DD")

		res.db = 1
		res.code = code
		res.libelle = libelle
		res.sens = sens
		res.saisieManuelleAutorisee = saisieManuelleAutorisee
		res.idGroupeOperation = idGroupeOperation
    res.importFichierAutorise = importFichierAutorise

		this.entries.push(res)
		//const id = await this.save(res);
		//res.id = Number(id);
		return res;
	}
}
