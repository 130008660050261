export interface Idrt_utilisateur {
	id: Number;
	etat: String;
	passwordAModifier: String;
	login: String;
	password: String;
	idPersonne: Number;
	dateApprobationCGU: String;
	dateApprobationCGUMobile: String;
}

export class drt_utilisateur implements Idrt_utilisateur {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	etat: String;
	passwordAModifier: String;
	login: String;
	password: String;
	idPersonne: Number;
	dateApprobationCGU: String;
	dateApprobationCGUMobile: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.etat = "";
		this.passwordAModifier = "";
		this.login = "";
		this.password = "";
		this.idPersonne = 0;
		this.dateApprobationCGU = "";
		this.dateApprobationCGUMobile = "";
	}

	public fromObj(entry: Idrt_utilisateur) {
		this.id = entry.id;
		this.etat = entry.etat;
		this.passwordAModifier = entry.passwordAModifier;
		this.login = entry.login;
		this.password = entry.password;
		this.idPersonne = entry.idPersonne;
		this.dateApprobationCGU = entry.dateApprobationCGU;
		this.dateApprobationCGUMobile = entry.dateApprobationCGUMobile;
		return this;
	}

}
