export interface Itrc_groupetrace {
	id: Number;
	libelle: String;
}

export class trc_groupetrace implements Itrc_groupetrace {
	db: number = 0; //1 add or update, 2 delete, 0 no changes
	id: Number;
	libelle: String;

	constructor() {
		this.db = 0;
		this.id = 0;
		this.libelle = "";
	}

	public fromObj(entry: Itrc_groupetrace) {
		this.id = entry.id;
		this.libelle = entry.libelle;
		return this;
	}

}
