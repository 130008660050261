import { Injectable, Injector } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { pdt_tarifproduit } from '../types/type_pdt_tarifproduit';
import { ServerDBApiService } from './server-dbapi.service';

@Injectable({
  providedIn: 'root'
})
export class PdtTarifproduitService extends BaseService {

	override authneeded = false;
	
	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector
	) {
		super(global, appPagesService, router, http, apiService,injector);
		super.init("pdt_tarifproduit", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	getId(entry) {
		return String(entry.id)
	}

	loadEntry(dbentry) {
		const res = (new pdt_tarifproduit()).fromObj(dbentry)
		res.tarif = "Normal"
		if ( res.typeTarif != "Normal" )
			res.tarif = res.typeTarif.match(/[A-Z]+(?![a-z])|[A-Z]?[a-z]+|\d+/g).join(' ')+"%";
		res.tarif = res.tarif + " ("+ (Number(res.montant) / 100).toLocaleString() + "€)"
		return res

	}

	getTarifsFromProduit(idProduit,idSaison) {
		console.log("getTarifsFromProduit",idProduit,idSaison)
		return this.entries.filter(f=>f.idProduit==idProduit && f.idSaison == idSaison && f.typeTarif!='Retraite')
	}

	override onLoadingFinished() {
		console.log("finished PdtTarifproduitService")
	}
}
