import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
	selector: 'app-date-picker-modal',
	templateUrl: './date-picker-modal.component.html',
	styleUrls: ['./date-picker-modal.component.scss'],
})
export class DatePickerModalComponent implements OnInit {

	@Input() selectedDate: string
	formattedDate: string;

	@Output() dateSelected: EventEmitter<any> = new EventEmitter<any>();
	formatDate() {
		if (this.selectedDate) {
			const date = new Date(this.selectedDate);
			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const year = date.getFullYear();
			this.formattedDate = `${day}/${month}/${year}`;
		}
	}
	onDateSelected(event: any) {
		//this.selectedDate = event.detail.value;
		this.dismiss(this.selectedDate);
	}
	constructor(private modalController: ModalController) { }

	ngOnInit() { }

	dismiss(result?: any) {
		this.modalController.dismiss(result);
	}


}
