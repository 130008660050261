import { Injectable, Injector } from '@angular/core';
import { AppPagesService } from './app-pages.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { BaseService } from './base.service';
import { str_structure } from '../types/type_str_structure';
import { ServerDBApiService } from './server-dbapi.service';

@Injectable({
	providedIn: 'root'
})
export class StrStructureService extends BaseService {

	override authneeded: boolean = false;
	mapNbAdherents = new Map()

	constructor(public override global: GlobalService,
		public override appPagesService: AppPagesService,
		public override router: Router,
		public override http: HttpClient,
		public override apiService: ServerDBApiService,
		public override injector: Injector,
	) {
		super(global, appPagesService, router, http, apiService, injector);
		super.init("str_structure", this.loadEntry.bind(this), [], this.getId.bind(this))
	}

	override addEntry(): any {
		return super.addEntry(new str_structure())
	}

	override getSingleUrl() {
		return '/client/' + this.currentEntry.id
	}

	override getListUrl() {
		return '/clients'
	}

	override getSingleIcon() {
		return 'person'
	}

	override getListIcon() {
		return 'people'
	}

	getId(entry) {
		return String(entry.id)
	}

	getAllChildrenIds(currentEntry,results) {
		if ( currentEntry.children ) {
			currentEntry.children.forEach(x=>{
				this.getAllChildrenIds( x, results )
			})			
		}
		results.push(currentEntry.id)
	} 

	formatEntries(entries, previousnom = "") {
		//console.log("formatEntries",entries)
		entries.forEach(entry => {
			entry.children = []
			if (entry.previousnom == "" || !entry.previousnom) {
				let libelle = entry.nom
				if (entry.code)
					libelle = entry.code + " - " + entry.nom
				if (previousnom !== "")
					entry.previousnom = previousnom + " > " + libelle
				else
					entry.previousnom = libelle
			}
			entry.childrenids.forEach(childrenid => {
				entry.children.push(this.oldEntries.find(x => x.id == childrenid.idStructure))
			})
			this.formatEntries(entry.children, entry.previousnom)
		})
	}

	public displayentries: any[] = [];
	oldEntries: any[] = [];
	override onLoadingFinished() {
		console.log("debug5 onLoadingFinished", this.basetable, this.entries, this.global.allEntries["str_structures_meres"])
		const meres = this.global.allEntries["str_structures_meres"]

		this.displayentries = [...this.entries]

		this.displayentries.forEach(structure => {
			//	console.log(structure.id)
			structure.childrenids = []
			structure.childrenids = meres.filter(mere => mere.idStructureMere == structure.id)
			structure.a = "a"
		})

		this.displayentries.forEach(structure => {
			//	console.log(structure.id)
			structure.parentsids = []
			structure.parentsids = meres.filter(children => children.idStructure == structure.id)
			structure.a = "a"
		})

		this.oldEntries = this.displayentries
		this.displayentries = []
		this.displayentries = this.oldEntries.filter(structure => structure.parentsids.length == 0)
		console.log("debug5 this.displayentries",this.displayentries)
		this.formatEntries(this.displayentries)

		let idSaison = "2024"//this.flatentries.filter(x => x.childrenids.length == 0).map(x => ({ idStructure: x.id, nbAdherents: 0}))
		this.apiService.getNbOfAdherentsPerStructure(idSaison).subscribe(
			{
				next: res => {
					res.rows.forEach(str => {
						if (str.idStructure) {
							this.mapNbAdherents.set(str.idStructure.toString(), str.nbAdherents)
						}
					})
				},
				error: err => console.log(err.message)
			});

		console.log("debug5 after children", this.entries, idSaison)
	}

	getNbAdherents(idStructure: Number) {
		const res = this.mapNbAdherents.get(idStructure.toString())
		console.log("getNbAdherents", idStructure.toString(), res, this.mapNbAdherents.size, this.mapNbAdherents.get(idStructure))
		return res || ""
	}

	override getEntriesToDisplay(idcustomer = null, search = null, sortedField = null) {
		let tmp = super.getEntriesToDisplay(idcustomer, search, sortedField)
		tmp = tmp.filter(x => !(x.company == "" && x.lastname == "" && x.firstname == ""))
		if (sortedField) {

			if (sortedField.sort == 1)
				return tmp.sort((a, b) => a[sortedField.field].localeCompare(b[sortedField.field]))
			return tmp.sort((a, b) => b[sortedField.field].localeCompare(a[sortedField.field]))
		}
		return tmp
	}

	override getTitle(entry: any = null) {
		if (!entry)
			entry = this.currentEntry
		let tmp = entry.company.trim()
		if (tmp == "") {
			tmp = entry.lastname + " " + entry.firstname
			tmp = tmp.trim()
			if (tmp == "")
				return "(Nouveau client)"
		}
		return tmp
	}

	getCurrentEntry() {
		if (!this.currentEntry)
			this.currentEntry = this.entries[0]
		//console.log("abc getCurrentEntry",this.currentEntry,this.entries)
		return this.currentEntry
	}

	//funtion passed in constructor
	loadEntry(dbentry) {
		//console.log("loadCustomer", dbentry)
		const res = (new str_structure()).fromObj(dbentry)
		//console.log("loadCustomer", res)
		return res

	}

	findStructureNom(id) {
		//console.log("findStructureNom",id)
		return this.entries.find(x => x.id == id).nom
	}

	/*findCustomer(id): Customer {
		this.currentCustomer = null;
		this.currentCustomer = this.customers.find(cust => cust.id == id)
		return this.currentCustomer
	}*/
}