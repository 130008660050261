<ion-header>
  <ion-toolbar>
    <ion-title>Sélectionner une date</ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismiss()">Annuler</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<div class="center">
<ion-datetime
[(ngModel)]="selectedDate"
(ionChange)="onDateSelected($event)"
displayFormat="DD/MM/YYYY"
presentation="date" preferWheel="false"
></ion-datetime>
</div>